import { ActionTypes } from "../constants/actionTypes";
export const getInvoicesData = (invoices) => async (dispatch) => {
  /* istanbul ignore next */
  try {
    dispatch({
      type: ActionTypes.GET_INVOICES_DATA,
      payload: invoices,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getInvoiceData = (invoice) => async (dispatch) => {
  /* istanbul ignore next */
  try {
    dispatch({
      type: ActionTypes.GET_INVOICE_DATA,
      payload: invoice,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getVendorWorkOrderData = (workOrder) => async (dispatch) => {
  /* istanbul ignore next */
  try {
    dispatch({
      type: ActionTypes.GET_VENDOR_DATA,
      payload: workOrder,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getDocumentsData = (documents) => async (dispatch) => {
  /* istanbul ignore next */
  try {
    dispatch({
      type: ActionTypes.GET_DOCUMENTS_DATA,
      payload: documents,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getInvoiceWorkOrdersData = (workOrders) => async (dispatch) => {
  /* istanbul ignore next */
  try {
    dispatch({
      type: ActionTypes.GET_INVOICE_WORKORDERS_DATA,
      payload: workOrders,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getMaintenanceRecordsData =
  (maintenanceRecords) => async (dispatch) => {
    /* istanbul ignore next */
    try {
      dispatch({
        type: ActionTypes.GET_INVOICE_MAINTENANCERECORDS,
        payload: maintenanceRecords,
      });
    } catch (e) {
      console.log(e);
    }
  };

export const getContractsData = (contracts) => async (dispatch) => {
  /* istanbul ignore next */
  try {
    dispatch({
      type: ActionTypes.GET_CONTRACTS_DATA,
      payload: contracts,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getCustomerWorkOrders =
  (customerWorkOrders) => async (dispatch) => {
    /* istanbul ignore next */
    try {
      dispatch({
        type: ActionTypes.GET_CUSTOMER_WORKORDERS,
        payload: customerWorkOrders,
      });
    } catch (e) {
      console.log(e);
    }
  };

export const getCustomerMaintenanceRecords =
  (customerMaintenanceRecords) => async (dispatch) => {
    /* istanbul ignore next */
    try {
      dispatch({
        type: ActionTypes.GET_CUSTOMER_WORKORDERS,
        payload: customerMaintenanceRecords,
      });
    } catch (e) {
      console.log(e);
    }
  };

export const saveWorkOrders = (invoices) => async (dispatch) => {
  /* istanbul ignore next */
  try {
    dispatch({
      type: ActionTypes.GET_INVOICE_DATA,
      payload: invoices,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getUserVendorDetails = (vendor) => async (dispatch) => {
  /* istanbul ignore next */
  try {
    dispatch({
      type: ActionTypes.GET_USER_VENDOR_DETAIL,
      payload: vendor,
    });
  } catch (e) {
    console.log(e);
  }
};
