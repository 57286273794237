import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import expand from "../../assets/img/menu/expand.svg";
import dashboard from "../../assets/img/menu/dashboard.svg";
import Invoices_Active from "../../assets/img/icons/Invoices_Active.svg";
import Workorders_Active from "../../assets/img/icons/Workorders_Active.svg";
import document from "../../assets/img/menu/document.svg";
import compare from "../../assets/img/menu/compare.svg";
import feedback from "../../assets/img/menu/feedback.svg";
import invoice from "../../assets/img/menu/invoice.svg";
import setting from "../../assets/img/menu/setting.svg";
import customers from "../../assets/img/menu/users.svg";
import users_active from "../../assets/img/menu/User_Active.svg";
import users_inactive from "../../assets/img/menu/User_Inactive.svg";
import close from "../../assets/img/menu/close.svg";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useSelector } from "react-redux";

// Role based Menu structure will be done here

function Sidebar({ location }) {
  const classes = useStyles();
  const theme = useTheme();
  const layoutState = useLayoutState();

  // global
  const { isSidebarOpened } = useLayoutState();
  const layoutDispatch = useLayoutDispatch();

  // local
  const [isPermanent, setPermanent] = useState(true);
  const [currentURL, setCurrentURL] = useState();

  const authRole = useSelector(
    (state) => state?.auth?.authUserData?.user?.role,
  );

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  useEffect(() => {
    setCurrentURL(window.location.href);
  }, [window.location.href]);

  const structure = [
    {
      id: 2,
      label: "Work Orders",
      link: "/app/workorders",
      icon:
        currentURL && currentURL?.includes("workorders") ? (
          <img src={Workorders_Active} />
        ) : (
          <img src={setting} />
        ),
    },

    {
      id: 3,
      label: "Invoices",
      link: "/app/invoices",
      icon:
        currentURL && currentURL?.includes("invoices") ? (
          <img src={Invoices_Active} />
        ) : (
          <img src={invoice} />
        ),
    },
    authRole === "admin" && {
      id: 4,
      label: "Users",
      link: "/app/users",
      icon:
        currentURL && currentURL?.includes("users") ? (
          <img src={users_active} />
        ) : (
          <img src={users_inactive} />
        ),
    },
  ];

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      {/* Logic for expanding Sidebar */}
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
          <img
            src={close}
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          ></img>
        </IconButton>
      </div>
      <IconButton
        color="inherit"
        onClick={() => toggleSidebar(layoutDispatch)}
        className={classNames(
          classes.headerMenuButton,
          classes.headerMenuButtonCollapse,
        )}
      >
        {layoutState.isSidebarOpened ? (
          <img
            src={close}
            classes={{
              root: classNames(classes.closeIcon, classes.headerIconCollapse),
            }}
          ></img>
        ) : (
          <img
            src={expand}
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          ></img>
        )}
      </IconButton>

      {/* Mapping the list in SidebarLink */}
      <List className={classes.sidebarList}>
        {structure.map((link) => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    const windowWidth = window.innerWidth;
    const breakpointWidth = theme.breakpoints.values.md;
    const isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
