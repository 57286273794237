import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "react-select";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { visuallyHidden } from "@mui/utils";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import useStyles from "../AddLineItem/styles";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { UPDATE_WORK_ORDER } from "../../../GraphQL/Mutations";
import { useMutation } from "@apollo/client";
import { Box, Grid, Input } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";

export default function ViewRaiseDisputemodel({
  show,
  onHide,
  data,
  refetch,
  onValidDisputeClick,
  onFixDisputeClick,
  isFixed,
}) {
  const [dense, setDense] = React.useState(false);
  const classes = useStyles();

  const headCells = [
    {
      id: "field",
      numeric: false,
      disablePadding: true,
      label: "Field",
    },
    {
      id: "internalId",
      numeric: false,
      disablePadding: true,
      label: "Internal Id",
    },
    {
      id: "LineItemField",
      numeric: false,
      disablePadding: false,
      label: "Line Item Field",
    },
    {
      id: "correctValue",
      numeric: false,
      disablePadding: false,
      label: "Correct Value",
    },
    {
      id: "reason",
      numeric: true,
      disablePadding: false,
      label: "Reason",
    },
    {
      id: "",
      numeric: true,
      disablePadding: false,
      label: "",
    },
    {
      id: "",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];
  /* istanbul ignore next */
  function EnhancedTableHead(props) {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "left" : "left"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  console.log(data, "vsadsad");
  return (
    <div>
      <Dialog
        open={show}
        onClose={onHide}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dispute_dropdown"
      >
        <div className="textArea-dialog">
          <DialogTitle id="alert-dialog-title">View Dispute</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <>
                <Box sx={{ width: "100%" }}>
                  <Paper sx={{ width: "100%", mb: 2 }}>
                    <TableContainer sx={{ padding: "32px" }}>
                      <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                      >
                        <EnhancedTableHead />
                        <TableBody>
                          {data &&
                            data?.workOrder?.disputed?.map((row) => (
                              <TableRow>
                                <TableCell>{row?.field}</TableCell>
                                <TableCell>{row?.internalId}</TableCell>
                                <TableCell>{row?.lineItemField}</TableCell>
                                <TableCell>{row?.correctValue}</TableCell>
                                <TableCell>{row?.reason}</TableCell>
                                <TableCell>
                                  <Box
                                    bgcolor={
                                      isFixed(
                                        row?.field,
                                        row?.internalId,
                                        row?.lineItemField,
                                        row?.correctValue,
                                      ) === "Fixed"
                                        ? "green"
                                        : "red"
                                    }
                                    color={"white"}
                                    borderRadius={"30px"}
                                    p={1}
                                  >
                                    {isFixed(
                                      row?.field,
                                      row?.internalId,
                                      row?.lineItemField,
                                      row?.correctValue,
                                    )}
                                  </Box>
                                </TableCell>

                                {isFixed(
                                  row?.field,
                                  row?.internalId,
                                  row?.lineItemField,
                                  row?.correctValue,
                                ) === "Not Fixed" && (
                                    <TableCell>
                                      <Button
                                        onClick={() =>
                                          onFixDisputeClick(
                                            row?.field,
                                            row?.internalId,
                                            row?.lineItemField,
                                            row?.correctValue,
                                          )
                                        }
                                      >
                                        Fix
                                      </Button>
                                    </TableCell>
                                  )}

                                <TableCell>
                                  <Button
                                    onClick={() =>
                                      onValidDisputeClick(
                                        row?.field,
                                        row?.internalId,
                                      )
                                    }
                                  >
                                    {isFixed(
                                      row?.field,
                                      row?.internalId,
                                      row?.lineItemField,
                                      row?.correctValue,
                                    ) === "Fixed"
                                      ? "Remove"
                                      : " Invalid Dispute"}
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Box>
              </>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className={classNames(classes.cancelButton)}
              onClick={() => onHide()}
            >
              Cancel
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
