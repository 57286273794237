import React from "react";
import classNames from "classnames";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import { Grid, Input } from "@mui/material";
import Select, { components } from "react-select";
import { Controller } from "react-hook-form";

//Styles

import useStyles from "../../styles";

//Icons
import WhitePlus from "../../../../assets/img/icons/whitePlus.svg";

import Plus from "../../../../assets/img/icons/plus.svg";
import { useMutation, useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import {
  CREATE_CUSTOMER,
  CREATE_USER,
  CREATE_USER_GROUP,
} from "../../../../GraphQL/Mutations";
import { toast } from "react-toastify";
import { FETCH_ALL_USER_GROUPS } from "../../../../GraphQL/Queries";

const roleOptions = [
  { label: "Fleet Director", value: "user" },
  { label: "Admin", value: "admin" },
  { label: "Service Manager", value: "service" },
  { label: "Vendor", value: "vendor" },
  { label: "External Customer", value: "customer" },
];

const AddNewUserGroupForm = ({ closeModal }) => {
  // React hook form initialization
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.authUserData?.token);

  // Use selectors initialized
  const customerId = useSelector(
    (state) => state?.auth?.authUserData?.user?.vendorId,
  );

  // ------------------Mutations/Queries------------------//

  // Api call to fetch all user groups
  const {
    error: error,
    loading: userGroupsLoading,
    data: userGroups,
    refetch,
  } = useQuery(FETCH_ALL_USER_GROUPS, {
    variables: { token },
  });

  //Api call to user customer
  const [addUserGroup, { loading }] = useMutation(CREATE_USER_GROUP, {

    onCompleted: (data) => {
      /* istanbul ignore next */
      closeModal(); // Access the response data here
      refetch();
      toast.success("User Group added succesfully!");
    },
    onError: (error) => {
      /* istanbul ignore next */
      toast.error("Unable to add user group!");
    },
  });

  //call Add User Api - submit
  const onSubmit = (data) => {
    addUserGroup({
      variables: {
        token,
        name: data?.name,
        customerId,
      },
    });
  };

  // Dropdown icon
  const DropdownIndicator = (props) => {
    /* istanbul ignore next */
    return (
      <div>
        <components.DropdownIndicator {...props}>
          <img className={classNames(classes.plusIcon)} src={WhitePlus}></img>
        </components.DropdownIndicator>
      </div>
    );
  };

  return (
    <>
      <div className={classNames(classes.addModalContainer)}>
        <div className={classNames(classes.headerContainer)}>
          <div className={classNames(classes.modalHeader)}>New User Group</div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classNames(classes.headerContainer)}>
            <Box sx={{ margin: "24px 0 12px 0" }}>
              <Grid
                className={classNames(classes.formRow)}
                container
                spacing={2}
              >
                <Grid item xs={12} md={12}>
                  <div>
                    <label htmlFor="name">
                      Name
                      <span className={classNames(classes.mandatory)}>*</span>
                    </label>
                  </div>

                  <Input
                    id="name"
                    type={"text"}
                    fullWidth
                    className={classes.textField}
                    keyboardIcon={<img src={Plus}>a</img>}
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <span className={classNames(classes.mandatory)}>
                      {" "}
                      This field is required
                    </span>
                  )}
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                margin: "24px 0 24px 0",
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <button className="upload_button" type="submit">
                Save
              </button>
              <button className="cancel_button">Reset</button>
            </Box>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddNewUserGroupForm;
