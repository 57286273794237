import React from "react";
import { Button } from "@material-ui/core";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";
import { Link } from "react-router-dom";

export default function PageTitle(props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {props.breadcrumb && (
        <div>
          <span>
            <Link to={`${props.location}`}> {props?.title}</Link>
            {` > ${props.subTitle}`}
          </span>
        </div>
      )}
      <div className={classes.pageTitleContainer}>
        <div>
          <span className={classes.typo}>{props?.title}</span>
        </div>

        {props.button && (
          <Button
            classes={{ root: classes.button }}
            variant="contained"
            size="large"
            color="secondary"
          >
            {props.button}
          </Button>
        )}
      </div>
    </div>
  );
}
