import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";
import { useHistory, useLocation, withRouter } from "react-router-dom";

import classnames from "classnames";

import { useMutation, gql, useQuery } from "@apollo/client";
import { AUTO_SIGNIN, SIGN_IN_USER } from "../../GraphQL/Mutations";

// styles
import useStyles from "./styles";

// logo
import logo from "./logo.svg";
import google from "../../images/google.svg";

// context
import { useUserDispatch, loginUser } from "../../context/UserContext";
import {
  getCurrentUser,
  userAuthenticate,
} from "../../redux/actions/authActions";
import { useDispatch } from "react-redux";
import { FETCH_CURRENT_USER } from "../../GraphQL/Queries";

function TokenLogin(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  // global
  const userDispatch = useUserDispatch();

  // local
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeTabId, setActiveTabId] = useState(0);
  const [nameValue, setNameValue] = useState("");
  const [loginValue, setLoginValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");

  // const onUserSignIn = useCallback(({ signInUser: result } = {}) => {
  //   dispatch(userAuthenticate(result));
  //   const { user, token, errors = [] } = result || {};
  //   if (token) {
  //     history.push("/app");
  //   }
  // }, []);

  function Query() {
    const { search } = useLocation();
    const history = useHistory();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  // http://localhost:3000/#/token?token=eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoyNn0.8ge4LVsGTv__q_urNjS1w5trQWIUG6DDt-x65t48AQY#/login
  let query = Query();
  const {
    error: currentUserError,
    loading: currentUserLoading,
    data: user,
  } = useQuery(FETCH_CURRENT_USER, {
    variables: {
      token: query.get("token"),
    },
  });

  // console.log(query, "currentUser");
  /* istanbul ignore next */
  useEffect(() => {
    if (user) {
      dispatch(
        getCurrentUser(
          user,
          query.get("token"),
          query.get("location"),
          query.get("id"),
          query.get("modal"),

          history,
        ),
      );
    } else if (currentUserError) {
      history.push("/login");
    }
  }, [user, currentUserError]);

  console.log(query.get("token"), "dwqdwqd");
  /* istanbul ignore next */
  const login = () => { };

  return (
    <Grid container className={classes.container}>
      <p style={{ color: "black" }}>Verifying your token to authenticate...</p>
    </Grid>
  );
}

export default withRouter(TokenLogin);
