import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import classNames from "classnames";

//Styles

import useStyles from "./styles";
import { UPDATE_WORK_ORDER } from "../../GraphQL/Queries";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { DESTROY_LINE_ITEM } from "../../GraphQL/Mutations";

export default function DeleteModal({
  open,
  setOpenModal,
  token,
  refetchWorkOrder,
  id,
}) {
  const classes = useStyles();
  /* istanbul ignore next */
  const handleClose = () => setOpenModal(false);
  /* istanbul ignore next */
  const [deleteLineItem, { loading }] = useMutation(DESTROY_LINE_ITEM, {
    onCompleted: (data) => {
      handleClose(); // Access the response data here
      toast.success("Line Item deleted successfully!");
      refetchWorkOrder();
    },
    onError: (error) => {
      toast.error("Unable to delete Line Item!");
    },
  });
/* istanbul ignore next */
  const onFormSubmit = () => {
    deleteLineItem({
      variables: {
        token: token,
        id: id,
      },
    });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classNames(classes.style)}>
          <CloseIcon
            onClick={handleClose}
            className={classNames(classes.closeicon)}
          />
          <span className={classNames(classes.span1)}>
            Are you sure you want
          </span>
          <br />
          <span
            className={classNames(classes.span2)}
            id="modal-modal-title"
            variant="h5"
          >
            to delete?
          </span>
          <br />
          <br />
          <br />
          <br />
          <br />
          <Box className={classNames(classes.style2)}>
            <Button
              className={classNames(classes.cancelbutton)}
              data-testId="cancel-btn"
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              className={classNames(classes.savebutton)}
              data-testId="del-btn"
              color="primary"
              variant="contained"
              onClick={onFormSubmit}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
