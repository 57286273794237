import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Button, Input } from "@mui/material";
import { useState } from "react";
import classNames from "classnames";
import useStyles from "../../components/SaveChanges/styles";
import { UPDATE_USER_DOCUMENT_AGREEMENT } from "../../GraphQL/Mutations";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function DocumentModal(props) {
  const classes = useStyles();
  const [checkbox, setCheckbox] = useState(false);

  /* istanbul ignore next */
  const [signDocument, { loading }] = useMutation(
    UPDATE_USER_DOCUMENT_AGREEMENT,
    {
      onCompleted: (data) => {
        props.handleClose(); // Access the response data here
        props.reFetchCurrentUser();
        toast.success("User agreement submitted!");
      },
      onError: (error) => {
        toast.error("Something went wrong!");
      },
    },
  );

  /* istanbul ignore next */
  const onDocumentSubmit = () => {
    signDocument({
      variables: {
        token: props?.token,
        id: props?.info?.udaId,
        userId: props?.info?.id,
        documentId: props?.info?.documentId,
        signedAt: new Date(),
      },
    });
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="keep-mounted-modal-title"
            sx={{ mt: 2, fontWeight: "800", fontSize: "15px" }}
          >
            {props?.info?.documentName && props?.info?.documentName}
          </Typography>
          <Box className="agreement_desc">
            <Typography
              id="keep-mounted-modal-description"
              sx={{ mt: 2, fontSize: "14px" }}
            >
              {props?.info?.documentContent && props?.info?.documentContent}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", mt: 3 }}>
            <Input
              type="checkbox"
              onChange={(e) => setCheckbox(e.target.checked)}
              checked={checkbox}
            />
            <label> &nbsp;&nbsp; I agree to the terms and condition!</label>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            <Button
              className={classNames(classes.savebutton)}
              disabled={!checkbox}
              color="primary"
              variant="contained"
              onClick={onDocumentSubmit}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
