import React from "react";
import classNames from "classnames";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import { Grid, Input } from "@mui/material";
import Select, { components } from "react-select";
import { Controller } from "react-hook-form";

//Styles

import useStyles from "../../styles";

//Icons
import WhitePlus from "../../../../assets/img/icons/whitePlus.svg";

import Plus from "../../../../assets/img/icons/plus.svg";
import { useMutation, useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { CREATE_CUSTOMER, CREATE_USER } from "../../../../GraphQL/Mutations";
import { toast } from "react-toastify";
import { FETCH_ALL_USER_GROUPS } from "../../../../GraphQL/Queries";

const roleOptions = [
  { label: "Fleet Director", value: "user" },
  { label: "Admin", value: "admin" },
  { label: "Service Manager", value: "service" },
  { label: "Vendor", value: "vendor" },
  { label: "External Customer", value: "customer" },
];

const AddNewUserForm = ({ closeModal }) => {
  // React hook form initialization
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.authUserData?.token);

  // Use selectors initialized
  const vendorId = useSelector(
    (state) => state?.auth?.authUserData?.user?.vendorId,
  );

  // ------------------Mutations/Queries------------------//

  // Api call to fetch all user groups
  const {
    error: error,
    loading: userGroupsLoading,
    data: userGroups,
  } = useQuery(FETCH_ALL_USER_GROUPS, {
    variables: { token },
  });

  //Api call to user customer
  const [addUser, { loading }] = useMutation(CREATE_USER, {
    /* istanbul ignore next */
    onCompleted: (data) => {
      closeModal(); // Access the response data here
      toast.success("User added succesfully!");
    },
    onError: (error) => {
      toast.error("Unable to add user!");
    },
  });

  //call Add User Api - submit
  const onSubmit = (data) => {
    /* istanbul ignore next */
    addUser({
      variables: {
        token,
        email: data?.email,
        firstName: data?.firstName,
        lastName: data?.lastName,
        userGroupId: data?.userGroupId?.value,
        role: data?.role.value,
      },
    });
  };

  // Dropdown icon
  const DropdownIndicator = (props) => {
    return (
      <div>
        <components.DropdownIndicator {...props}>
          <img className={classNames(classes.plusIcon)} src={WhitePlus}></img>
        </components.DropdownIndicator>
      </div>
    );
  };

  return (
    <>
      <div className={classNames(classes.addModalContainer)}>
        <div className={classNames(classes.headerContainer)}>
          <div className={classNames(classes.modalHeader)}>New User</div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classNames(classes.headerContainer)}>
            <Box sx={{ margin: "24px 0 12px 0" }}>
              <Grid
                className={classNames(classes.formRow)}
                container
                spacing={2}
              >
                <Grid item xs={6} md={6}>
                  <div>
                    <label htmlFor="firstName">
                      First Name
                      <span className={classNames(classes.mandatory)}>*</span>
                    </label>
                  </div>

                  <Input
                    id="firstName"
                    type={"text"}
                    fullWidth
                    className={classes.textField}
                    keyboardIcon={<img src={Plus}>a</img>}
                    {...register("firstName", { required: true })}
                  />
                  {errors.firstName && (
                    <span className={classNames(classes.mandatory)}>
                      {" "}
                      This field is required
                    </span>
                  )}
                </Grid>
                <Grid item xs={6} md={6}>
                  <div>
                    <label htmlFor="lastName">
                      Last Name
                      <span className={classNames(classes.mandatory)}>*</span>
                    </label>
                  </div>

                  <Input
                    id="lastName"
                    type={"text"}
                    fullWidth
                    className={classes.textField}
                    keyboardIcon={<img src={Plus}>a</img>}
                    {...register("lastName", { required: true })}
                  />
                  {errors.lastName && (
                    <span className={classNames(classes.mandatory)}>
                      {" "}
                      This field is required
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} md={12}>
                  <div>
                    <label htmlFor="email">
                      Email
                      <span className={classNames(classes.mandatory)}>*</span>
                    </label>
                  </div>

                  <Input
                    id="email"
                    type={"email"}
                    fullWidth
                    className={classes.textField}
                    keyboardIcon={<img src={Plus}>a</img>}
                    {...register("email", { required: true })}
                  />
                  {errors.email && (
                    <span className={classNames(classes.mandatory)}>
                      {" "}
                      This field is required
                    </span>
                  )}
                </Grid>
                <Grid item xs={6} md={6}>
                  <div>
                    <label htmlFor="userGroupId">
                      User Group
                      <span className={classNames(classes.mandatory)}>*</span>
                    </label>
                  </div>
                  <Controller
                    name="userGroupId"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        inputId="userGroupId"
                        options={
                          userGroups &&
                          userGroups?.allUserGroups.map((groups) => {
                            return {
                              label: groups?.name,
                              value: groups?.id,
                            };
                          })
                        }
                        // options={colourOptions}
                        className="react_select"
                        closeMenuOnSelect={true}
                        // hideSelectedOptions={false}
                        // components={{ Option, MultiValue, DropdownIndicator }}
                        components={{ DropdownIndicator }}
                      // allowSelectAll={true}
                      />
                    )}
                  />{" "}
                  {errors.userGroupId && (
                    <span className={classNames(classes.mandatory)}>
                      {" "}
                      This field is required
                    </span>
                  )}
                </Grid>
                <Grid item xs={6} md={6}>
                  <div>
                    <label htmlFor="role">
                      Role
                      <span className={classNames(classes.mandatory)}>*</span>
                    </label>
                  </div>
                  <Controller
                    id="role"
                    name="role"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        inputId="role"
                        options={roleOptions}
                        className="react_select"
                        closeMenuOnSelect={true}
                        // hideSelectedOptions={false}
                        // components={{ Option, MultiValue, DropdownIndicator }}
                        components={{ DropdownIndicator }}
                      // allowSelectAll={true}
                      />
                    )}
                  />{" "}
                  {errors.role && (
                    <span className={classNames(classes.mandatory)}>
                      {" "}
                      This field is required
                    </span>
                  )}
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                margin: "24px 0 24px 0",
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <button className="upload_button" type="submit">
                Save
              </button>
              <button className="cancel_button">Reset</button>
            </Box>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddNewUserForm;
