import React, { useState } from "react";
import Select, { components } from "react-select";
import classNames from "classnames";
import Box from "@mui/material/Box";
import { useForm, Controller } from "react-hook-form";
import { Grid, Input } from "@mui/material";


//Styles

import useStyles from "../../styles";

//Icons

import Plus from "../../../../assets/img/icons/plus.svg";
import WhitePlus from "../../../../assets/img/icons/whitePlus.svg";
import {
  CUSTOMER_RELATIONSHIP_SEARCH
} from "../../../../GraphQL/Queries";
import { useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import {
  CREATE_EQUIPMENT,
} from "../../../../GraphQL/Mutations";
import { toast } from "react-toastify";


// Options for dropdown

const maintenanceLevelOptions = [
  {
    label: "Time and Material",
    value: "TM",
  },
  {
    label: "Planned Maintenance",
    value: "PM",
  },
  {
    label: "Extra Care",
    value: "EC",
  },
  {
    label: "Total Care",
    value: "TC",
  },
];

const groupOptions = [
  {
    label: "Forklift",
    value: "Forklift",
  },
  {
    label: "Spotter",
    value: "Spotter",
  },
  {
    label: "Aerial Equipment",
    value: "Aerial Equipment",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const AddEquipmentForm = ({ closeModal }) => {

  // React hook form initialization
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const classes = useStyles();


  // Use selectors initialized here
  const token = useSelector((state) => state?.auth?.authUserData?.token);
  const vendorId = useSelector(
    (state) => state?.auth?.authUserData?.user?.vendorId,
  );


  // States maintained here

  const [customerId, setCustomerId] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [customerMiscInfo, setCustomerMiscInfo] = useState(null);
  const [isCustomer, setIsCustomer] = useState(false);
  const [focus, setFocus] = useState(false);



  // -----------------------UseQueries/Mutations----------------------


  // Query to search for customers
  const {
    error: customerRelationshipErrors,
    loading: customerRelationshipLoading,
    data: customerRelationshipSearch,
  } = useQuery(CUSTOMER_RELATIONSHIP_SEARCH, {
    variables: {
      token,
      vendorId,
      search: customerName,
    },
    skip: customerName?.length <= 3,
  });


  // Mutation to add an equipment
  /* istanbul ignore next */
  const [addEquipment, { loading }] = useMutation(CREATE_EQUIPMENT, {
    onCompleted: (data) => {
      closeModal(); // Access the response data here
      toast.success("Equipment added succesfully!");
    },
    onError: (error) => {
      toast.error("Unable to add equipment!");
    },
  });

  // Submit function to call the mutation to submit adding an equipment
  /* istanbul ignore next */
  const onSubmit = (data) => {
    /* istanbul ignore next */
    addEquipment({
      variables: {
        token: token,
        vendorEquipmentId: data?.vendorEquipmentId,
        ownerId: customerId,
        customerId: customerId,
        maintenanceLevel: data?.maintenanceLevel?.value,
        fleetNumber: data?.fleetNumber,
        year: parseInt(data?.year),
        model: data?.model,
        make: data?.make,
        serialNumber: data?.serialNumber,
        acquisitionCost: parseFloat(data?.acquisitionCost),
        plannedHours: parseInt(data?.plannedHours),
        description: data?.description,
        group: data?.group?.value,
        vendorId: vendorId,
      },
    });
  };


  // ----------------------Functions-------------------//

  // Handles customer information on click of the dropdown
  /* istanbul ignore next */
  const handleSingleCustomer = (customer) => {
    /* istanbul ignore next */
    setCustomerMiscInfo(
      " (" +
      customer?.customerInternalVal +
      ") " +
      customer?.childCustomer?.internalName,
    );
    setCustomerId(customer?.id);
    setFocus(false);

    setCustomerName(customer?.childCustomer.name);
  };


  // sets focus on click of the customer input field
  const handleCustomerFocus = (e) => {
    setFocus(true);
  };


  // function to set customer name on search
  const handleCustomerSearchChange = (e) => {
    setCustomerName(e.target.value);
    setIsCustomer(true);
  };



  // Dropdown icon
  const DropdownIndicator = (props) => {
    return (
      <div>
        <components.DropdownIndicator {...props}>
          <img className={classNames(classes.plusIcon)} src={WhitePlus}></img>
        </components.DropdownIndicator>
      </div>
    );
  };


  /* istanbul ignore next */
  return (
    <>
      <div className={classNames(classes.addModalContainer)}>
        <div className={classNames(classes.headerContainer)}>
          <div className={classNames(classes.modalHeader)}>New Equipment</div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classNames(classes.headerContainer)}>
            <Box sx={{ margin: "24px 0 12px 0" }}>
              {" "}
              <Grid
                className={classNames(classes.formRow)}
                container
                spacing={2}
              >
                <Grid item xs={6} md={6}>
                  <div>
                    <label>
                      Customer <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>

                  <div className="search">
                    <div className="input-group">
                      <input
                        id="search"
                        name="search"
                        type="text"
                        className="form-control search_dropdown"
                        onFocus={(e) => handleCustomerFocus(e)}
                        value={customerName}
                        onChange={handleCustomerSearchChange}
                      />
                    </div>
                  </div>
                  {focus &&
                    customerRelationshipSearch &&
                    customerRelationshipSearch?.searchCustomerRelationships && (
                      <div className="search_drop">
                        {customerRelationshipSearch?.searchCustomerRelationships
                          ?.length > 0 &&
                          customerRelationshipSearch?.searchCustomerRelationships?.map(
                            (customer, i) => (
                              <>
                                <div
                                  className="search_items"
                                  onClick={() => handleSingleCustomer(customer)}
                                >
                                  {customer?.childCustomer?.name +
                                    " (" +
                                    customer?.customerInternalVal +
                                    ") " +
                                    customer?.childCustomer?.internalName}
                                </div>
                              </>
                            ),
                          )}
                      </div>
                    )}
                  {!customerId && (
                    <span style={{ color: "red" }}>
                      {" "}
                      This field is required
                    </span>
                  )}
                </Grid>
                <Grid item xs={6} md={6}>
                  <div>
                    <label>Customer Info</label>
                  </div>

                  <Input
                    fullWidth
                    className={classes.textField}
                    disabled
                    value={customerMiscInfo}
                  />
                </Grid>
              </Grid>
              <Grid
                className={classNames(classes.formRow)}
                container
                spacing={2}
              >
                <Grid item xs={6} md={6}>
                  <div>
                    <label>
                      Vendor Equipment ID
                      <span className={classNames(classes.mandatory)}>*</span>
                    </label>
                  </div>

                  <Input
                    type={"text"}
                    fullWidth
                    className={classes.textField}
                    keyboardIcon={<img src={Plus}>a</img>}
                    {...register("vendorEquipmentId", { required: true })}
                  />
                  {errors.vendorEquipmentId && (
                    <span className={classNames(classes.mandatory)}>
                      {" "}
                      This field is required
                    </span>
                  )}
                </Grid>{" "}
                <Grid item xs={6} md={6}>
                  <div>
                    <label>
                      Maintenance Level
                      <span className={classNames(classes.mandatory)}>*</span>
                    </label>
                  </div>
                  <Controller
                    name="maintenanceLevel"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        className="react_select"
                        closeMenuOnSelect={true}
                        components={{ DropdownIndicator }}
                        // onChange={(e) => handleEquipmentValue(e)}
                        // defaultValue={[colourOptions[4], colourOptions[5]]}
                        // isMulti
                        options={maintenanceLevelOptions}
                      />
                    )}
                  />{" "}
                  {errors.maintenanceLevel && (
                    <span className={classNames(classes.mandatory)}>
                      {" "}
                      This field is required
                    </span>
                  )}
                </Grid>{" "}
                <Grid item xs={6} md={6}>
                  <div>
                    <label>Year</label>
                  </div>

                  <Input
                    type={"number"}
                    fullWidth
                    className={classes.textField}
                    keyboardIcon={<img src={Plus}>a</img>}
                    {...register("year", { required: false })}
                  />
                </Grid>{" "}
                <Grid item xs={6} md={6}>
                  <div>
                    <label>Serial Number</label>
                  </div>

                  <Input
                    type={"text"}
                    fullWidth
                    className={classes.textField}
                    keyboardIcon={<img src={Plus}>a</img>}
                    {...register("serialNumber", { required: false })}
                  />
                </Grid>{" "}
                <Grid item xs={6} md={6}>
                  <div>
                    <label>Fleet Number</label>
                  </div>

                  <Input
                    type={"text"}
                    fullWidth
                    className={classes.textField}
                    keyboardIcon={<img src={Plus}>a</img>}
                    {...register("fleetNumber", { required: false })}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <div>
                    <label>Make</label>
                  </div>

                  <Input
                    type={"text"}
                    fullWidth
                    className={classes.textField}
                    keyboardIcon={<img src={Plus}>a</img>}
                    {...register("make", { required: false })}
                  />
                </Grid>{" "}
                <Grid item xs={6} md={6}>
                  <div>
                    <label>Model</label>
                  </div>

                  <Input
                    type={"text"}
                    fullWidth
                    className={classes.textField}
                    keyboardIcon={<img src={Plus}>a</img>}
                    {...register("model", { required: false })}
                  />
                </Grid>{" "}
                <Grid item xs={6} md={6}>
                  <div>
                    <label>Acquisition Cost ($)</label>
                  </div>

                  <Input
                    type={"number"}
                    fullWidth
                    className={classes.textField}
                    keyboardIcon={<img src={Plus}>a</img>}
                    {...register("acquisitionCost", { required: false })}
                  />
                </Grid>{" "}
                <Grid item xs={6} md={6}>
                  <div>
                    <label>Planned Hours</label>
                  </div>

                  <Input
                    type={"number"}
                    fullWidth
                    className={classes.textField}
                    keyboardIcon={<img src={Plus}>a</img>}
                    {...register("plannedHours", { required: false })}
                  />
                </Grid>{" "}
                <Grid item xs={6} md={6}>
                  <div>
                    <label>Group</label>
                  </div>
                  <Controller
                    name="group"
                    rules={{ required: false }}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        className="react_select"
                        closeMenuOnSelect={true}
                        components={{ DropdownIndicator }}
                        // onChange={(e) => handleEquipmentValue(e)}
                        // defaultValue={[colourOptions[4], colourOptions[5]]}
                        // isMulti
                        options={groupOptions}
                      />
                    )}
                  />
                  {errors.userType && (
                    <span className={classNames(classes.mandatory)}>
                      {" "}
                      This field is required
                    </span>
                  )}
                </Grid>{" "}
              </Grid>
              <Grid
                className={classNames(classes.formRow)}
                container
                spacing={2}
              >
                <Grid item xs={12} md={12}>
                  <div>
                    <label>Content</label>
                  </div>

                  <textarea
                    type={"text"}
                    fullWidth
                    className={classNames(
                      classes.textField,
                      classes.description_textArea,
                    )}
                    keyboardIcon={<img src={Plus}>a</img>}
                    {...register("description", { required: false })}
                  />
                </Grid>{" "}
              </Grid>
            </Box>

            <Box
              sx={{
                margin: "24px 0 24px 0",
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <button className="upload_button" type="submit">
                Save
              </button>
              <button className="cancel_button">Reset</button>
            </Box>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddEquipmentForm;
