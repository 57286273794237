import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { Box } from "@material-ui/core";
import classNames from "classnames";

//style
import useStyles from "./styles";

function createData(Date_Updated, Updated_By, Amount, Notes, Download) {
  return { Date_Updated, Updated_By, Amount, Notes, Download };
}

const rows = [
  createData(
    "15-12-2021",
    "Sean Palmer",
    "$999.85",
    "Lorem ipsum dolor sit amet, consecetur adipiscing elit.",
    "Download",
  ),
  createData(
    "15-12-2021",
    "Amanda Thurston",
    "$1,151.24",
    "Mauris pretium porttitor nulla, eget pellentesque sapien ornare et. Phasellus hendrerit ultricies tellus, vitae fermentum nulla consequat in. Ut dapibus pharetra bibendum.",
    "Download",
  ),
  createData(
    "15-12-2021",
    "Ewelina Walczak",
    "$1, 211.00",
    "Quisque rutrum felis urna, sit amet scelerisque nunc posuere vitae.",
    "Download",
  ),
  createData(
    "15-12-2021",
    "Ewelina Walczak",
    "$1, 211.00",
    "Quisque rutrum felis urna, sit amet scelerisque nunc posuere vitae.",
    "Download",
  ),
];

export default function TableHistory({ logs }) {
  const classes = useStyles();

  return (
    <TableContainer className={classNames(classes.tableContainer)}>
      <Table className={classNames(classes.table)} aria-label="simple table">
        <TableHead>
          <TableRow sx={classNames(classes.tableRow)}>
            <TableCell className={classNames(classes.tableCell1)}>
              Updated on
            </TableCell>
            <TableCell className={classNames(classes.tableCell2)}>
              Field{" "}
            </TableCell>
            <TableCell className={classNames(classes.tableCell3)}>
              Old Value
            </TableCell>
            <TableCell className={classNames(classes.tableCell4)}>
              New Value
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logs &&
            logs.map((row) => (
              <TableRow
                key={row.Date_Updated}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell className={classNames(classes.tableCell5)}>
                  {row.endedAt?.substring(0, 10)}
                </TableCell>
                <TableCell className={classNames(classes.tableCell6)}>
                  {row.fieldName}
                </TableCell>
                <TableCell className={classNames(classes.tableCell5)}>
                  {row.oldValue}
                </TableCell>{" "}
                <TableCell className={classNames(classes.tableCell5)}>
                  {row.newValue}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
