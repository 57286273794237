import React, { useState } from "react";
import { AppBar, Toolbar, IconButton, Menu, MenuItem } from "@material-ui/core";
import {
  Menu as MenuIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";
import logo from "../../assets/img/logo.svg";
import square from "../../assets/img/square.svg";
import demoProfile from "../../assets/img/demoProfile.svg";
import NotificationsIcon from "@mui/icons-material/Notifications";

// styles
import useStyles from "./styles";

// components
import { Badge, Typography } from "../Wrappers/Wrappers";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useDispatch, useSelector } from "react-redux";
import { useUserDispatch, signOut } from "../../context/UserContext";
import { ActionTypes } from "../../redux/constants/actionTypes";
import { Box, Button } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { WORK_ORDER_SEARCH } from "../../GraphQL/Queries";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";

export default function Header(props) {
  const classes = useStyles();
  const history = useHistory();
  const authUser = useSelector((state) => state?.auth?.authUserData);
  const token = useSelector((state) => state?.auth?.authUserData?.token);
  console.log(authUser, "authdatata");
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const dispatch = useDispatch();
  const [profileMenu, setProfileMenu] = useState(null);
  const [focus, setFocus] = useState(false);
  const [search, setSearch] = useState(null);

  const userRole = useSelector(
    (state) => state?.auth?.authUserData?.user?.role,
  );

  // let data;

  const {
    error: error,
    loading: loading,
    data: data,
  } = useQuery(WORK_ORDER_SEARCH, {
    variables: {
      token,
      search,
    },
    skip: search?.length < 4,
  });

  const onReturnClick = () => {
    const currentUrl = window.location.href;
  
    if (currentUrl.includes('uat')) {
      // If the current URL contains 'legacy', redirect to sandbox.transparencyfleet.com
      window.location.replace('https://sandbox.transparencyfleet.com');
    } else if (currentUrl.includes('localhost')) {
      // If the current URL contains 'localhost', redirect to http://localhost:3006
      window.location.replace('http://localhost:3006');
    } else {
      // If there is no 'legacy' or 'localhost' in the current URL, redirect to the current URL
      window.location.replace(`https://transparencyfleet.com`);
    }
  };

  // local
  /* istanbul ignore next */
  const handleFocus = (e) => {
    // dispatch(searchBooks(e.target.value, accessToken));
    setFocus(true);
  };
  /* istanbul ignore next */
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  /* istanbul ignore next */
  const handleSearchItemClick = (value) => {
    setFocus(false);
    setSearch("");
    history.push(`/app/workorders/${value?.id}`);
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      {/* ################ Sidebar expand logic/code ################### */}
      <Toolbar className={classes.toolbar}>
        <IconButton
          data-testid="tgl-sidebar-btn"
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(classes.headerMenuButtonCollapse)}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon className={classes.menuIcon}></MenuIcon>
          )}
        </IconButton>
        <img className={classes.logo} src={logo}></img>
        <div className={classes.grow} />

        {/* ################ Profile Box ################### */}

        {userRole !== "vendor" && (
          <IconButton color="inherit" aria-haspopup="true" aria-controls="mail-menu">
            <Button onClick={onReturnClick} className={classes.Returnbutton}>
              <span className={classes.returnFont}>Return to Legacy TDW</span>
            </Button>
          </IconButton>
        )}
        <Box sx={{ pl: 2, pr: 2 }}>
          <div className="search">
            <div className="input-group">
              <input
                id="search"
                name="search"
                type="text"
                placeholder="Search for Work Orders"
                className="form-control search_dropdown"
                onFocus={(e) => handleFocus(e)}
                value={search}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          {focus && data && data?.searchWorkOrders && (
            <div className="search_drop">
              {data?.searchWorkOrders?.length > 0 &&
                data?.searchWorkOrders?.map((d, i) => (
                  <>
                    <div
                      className="search_items"
                      onClick={() => handleSearchItemClick(d)}
                    >
                      {d?.internalId}
                    </div>
                  </>
                ))}
            </div>
          )}
        </Box>
        <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          // onClick={(e) => {
          //   setMailMenu(e.currentTarget);
          //   setIsMailsUnread(false);
          // }}
          className={classes.headerMenuButton}
        >
          <NotificationsIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <IconButton
          data-testid="profile-menu-button"
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={(e) => setProfileMenu(e.currentTarget)}
        >
          {/* <img src={demoProfile}></img> */}
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            {console.log(authUser, "authUser")}
            <Typography variant="h4" weight="medium">
              {authUser?.user?.firstName && authUser?.user?.lastName
                ? authUser?.user?.firstName + " " + authUser?.user?.lastName
                : authUser?.user?.name}
            </Typography>
          </div>
          {/* <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} /> Profile
          </MenuItem>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} /> Tasks
          </MenuItem>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} /> Messages
          </MenuItem> */}
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() =>
                dispatch({
                  type: ActionTypes.LOGOUT,
                })
              }
            >
              Sign Out
            </Typography>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
