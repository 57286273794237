import React from "react";
import classNames from "classnames";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import { Grid, Input } from "@mui/material";

//Styles

import useStyles from "../../styles";

//Icons

import Plus from "../../../../assets/img/icons/plus.svg";
import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { CREATE_CUSTOMER } from "../../../../GraphQL/Mutations";
import { toast } from "react-toastify";

const AddCustomerForm = ({ closeModal }) => {
  // React hook form initialization
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.authUserData?.token);

  // Use selectors initialized
  const vendorId = useSelector(
    (state) => state?.auth?.authUserData?.user?.vendorId,
  );

  // ------------------Mutations/Queries------------------//

  //Api call to create customer
  /* istanbul ignore next */
  const [addCustomer, { loading }] = useMutation(CREATE_CUSTOMER, {
    onCompleted: (data) => {
      closeModal(); // Access the response data here
      toast.success("Customer added succesfully!");
    },
    onError: (error) => {
      toast.error("Unable to add customer!");
    },
  });

  //call Add customer Api - submit
  /* istanbul ignore next */
  const onSubmit = (data) => {
    addCustomer({
      variables: {
        address1: data?.address1,
        address2: data?.address2,
        city: data?.city,
        country: data?.country,
        customerInternalVal: data?.customerInternalVal,
        dpw: parseInt(data?.dpw),
        hpd: parseInt(data?.hpd),
        internalName: data?.internalName,
        name: data?.name,
        postalCode: data?.postalCode,
        province: data?.province,
        token,
        vendorId: vendorId,
      },
    });
  };

  return (
    <>
      <div className={classNames(classes.addModalContainer)}>
        <div className={classNames(classes.headerContainer)}>
          <div className={classNames(classes.modalHeader)}>New Customer</div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classNames(classes.headerContainer)}>
            <Box sx={{ margin: "24px 0 12px 0" }}>
              <Grid
                className={classNames(classes.formRow)}
                container
                spacing={2}
              >
                <Grid item xs={6} md={6}>
                  <div>
                    <label>
                      Name
                      <span className={classNames(classes.mandatory)}>*</span>
                    </label>
                  </div>

                  <Input
                    type={"text"}
                    fullWidth
                    className={classes.textField}
                    keyboardIcon={<img src={Plus}>a</img>}
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <span className={classNames(classes.mandatory)}>
                      {" "}
                      This field is required
                    </span>
                  )}
                </Grid>
                <Grid item xs={6} md={6}>
                  <div>
                    <label>
                      Internal Value (ex. C-0005)
                      <span className={classNames(classes.mandatory)}>*</span>
                    </label>
                  </div>

                  <Input
                    type={"text"}
                    fullWidth
                    className={classes.textField}
                    keyboardIcon={<img src={Plus}>a</img>}
                    {...register("customerInternalVal", { required: true })}
                  />
                  {errors.customerInternalVal && (
                    <span className={classNames(classes.mandatory)}>
                      {" "}
                      This field is required
                    </span>
                  )}
                </Grid>
              </Grid>

              <Grid
                className={classNames(classes.formRow)}
                container
                spacing={2}
              >
                <Grid item xs={12} md={12}>
                  <div>
                    <label>Site</label>
                  </div>

                  <Input
                    type={"text"}
                    fullWidth
                    className={classes.textField}
                    keyboardIcon={<img src={Plus}>a</img>}
                    {...register("internalName", { required: false })}
                  />
                </Grid>{" "}
                <Grid item xs={6} md={6}>
                  <div>
                    <label>
                      Address
                      <span className={classNames(classes.mandatory)}>*</span>
                    </label>
                  </div>

                  <Input
                    type={"text"}
                    fullWidth
                    className={classes.textField}
                    keyboardIcon={<img src={Plus}>a</img>}
                    {...register("address1", { required: true })}
                  />
                  {errors.address1 && (
                    <span className={classNames(classes.mandatory)}>
                      {" "}
                      This field is required
                    </span>
                  )}
                </Grid>
                <Grid item xs={6} md={6}>
                  <div>
                    <label>Address 2</label>
                  </div>
                  <Input
                    type={"text"}
                    fullWidth
                    className={classes.textField}
                    keyboardIcon={<img src={Plus}>a</img>}
                    {...register("address2", { required: false })}
                  />{" "}
                </Grid>
              </Grid>

              <Grid
                className={classNames(classes.formRow)}
                container
                spacing={2}
              >
                {" "}
                <Grid item xs={6} md={6}>
                  <div>
                    <label>
                      City
                      <span className={classNames(classes.mandatory)}>*</span>
                    </label>
                  </div>
                  <Input
                    type={"text"}
                    fullWidth
                    className={classes.textField}
                    keyboardIcon={<img src={Plus}>a</img>}
                    {...register("city", { required: true })}
                  />{" "}
                  {errors.city && (
                    <span className={classNames(classes.mandatory)}>
                      {" "}
                      This field is required
                    </span>
                  )}
                </Grid>{" "}
                <Grid item xs={6} md={6}>
                  <div>
                    <label>
                      State (Abbreviation)
                      <span className={classNames(classes.mandatory)}>*</span>
                    </label>
                  </div>

                  <Input
                    type={"text"}
                    fullWidth
                    className={classes.textField}
                    keyboardIcon={<img src={Plus}>a</img>}
                    {...register("province", { required: true })}
                  />
                  {errors.province && (
                    <span className={classNames(classes.mandatory)}>
                      {" "}
                      This field is required
                    </span>
                  )}
                </Grid>
                <Grid item xs={6} md={6}>
                  <div>
                    <label>
                      Zip Code
                      <span className={classNames(classes.mandatory)}>*</span>
                    </label>
                  </div>

                  <Input
                    type={"text"}
                    fullWidth
                    className={classes.textField}
                    keyboardIcon={<img src={Plus}>a</img>}
                    {...register("postalCode", { required: true })}
                  />
                  {errors.postalCode && (
                    <span className={classNames(classes.mandatory)}>
                      {" "}
                      This field is required
                    </span>
                  )}
                </Grid>
                <Grid item xs={6} md={6}>
                  <div>
                    <label>
                      Country (Abbreviation)
                      <span className={classNames(classes.mandatory)}>*</span>
                    </label>
                  </div>

                  <Input
                    type={"text"}
                    fullWidth
                    className={classes.textField}
                    keyboardIcon={<img src={Plus}>a</img>}
                    {...register("country", { required: true })}
                  />
                  {errors.country && (
                    <span className={classNames(classes.mandatory)}>
                      {" "}
                      This field is required
                    </span>
                  )}
                </Grid>
                <Grid item xs={6} md={6}>
                  <div>
                    <label>Hours Per Day</label>
                  </div>

                  <Input
                    type={"text"}
                    fullWidth
                    className={classes.textField}
                    keyboardIcon={<img src={Plus}>a</img>}
                    {...register("hpd", { required: false })}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <div>
                    <label>Days Per Week</label>
                  </div>

                  <Input
                    type={"text"}
                    fullWidth
                    className={classes.textField}
                    keyboardIcon={<img src={Plus}>a</img>}
                    {...register("dpw", { required: false })}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                margin: "24px 0 24px 0",
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <button className="upload_button" type="submit">
                Save
              </button>
              <button className="cancel_button">Reset</button>
            </Box>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddCustomerForm;
