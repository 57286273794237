import React, { useState } from "react";
import Select, { components } from "react-select";
import classNames from "classnames";
import Box from "@mui/material/Box";
import { useForm, Controller } from "react-hook-form";
import { Grid, Input } from "@mui/material";

//Styles

import useStyles from "../../styles";

//Icons

import { ColorRing } from "react-loader-spinner";
import Plus from "../../../../assets/img/icons/plus.svg";
import WhitePlus from "../../../../assets/img/icons/whitePlus.svg";
import { disableExperimentalFragmentVariables, useMutation, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CUSTOMER_RELATIONSHIP_SEARCH,
  FETCH_ALL_WORK_CATEGORIES,
  FETCH_EQUIPMENT_BY_CUSTOMER,
  FETCH_CONTRACT_DETAILS_BY_EQUIPMENT,
} from "../../../../GraphQL/Queries";
import { CREATE_WORK_ORDER } from "../../../../GraphQL/Mutations";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

// File types to upload
const fileTypes = ["JPG", "PNG", "GIF"];


// Dropdown options for invoicetype
const invoiceType = [
  { label: "Customer", value: "Customer" },
  { label: "Total Care", value: "Total Care" },
  { label: "Extra Care", value: "Extra Care" },
  { label: "Internal", value: "Internal" },
];



const AddMicroInvoiceForm = ({
  closeModal,
  refetchWorkOrders,
  handleClick,
}) => {
  // react hook form init
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  // Maintaining States
  const [file, setFile] = useState(null);
  const [focus, setFocus] = useState(false);
  const [vendorFocus, setVendorFocus] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [errorsValue, setErrorsValue] = useState(false);
  const [customerName, setCustomerName] = useState(null);
  const [customerMiscInfo, setCustomerMiscInfo] = useState(null);
  const [vendorMiscInfo, setVendorMiscInfo] = useState(null);
  const [isCustomer, setIsCustomer] = useState(false);
  const [mfcCheck, setMfcCheck] = useState(false);
  const [pmCheck, setPmCheck] = useState(false);
  const [vendorName, setVendorName] = useState(null);
  const [equipmentData, setequipmentData] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [vendor_id, setVendorId] = useState(null);
  const [po, setPo] = useState(false);

  // Useselector init
  const token = useSelector((state) => state?.auth?.authUserData?.token);
  const role = useSelector((state) => state?.auth?.authUserData?.user?.role);
  const userVendorDetails = useSelector(
    (state) => state?.invoices?.getUserVendorDetail?.customer,
  );
  const vendorId = useSelector(
    (state) => state?.auth?.authUserData?.user.vendorId,
  );

  // Renderers init
  useEffect(() => {
    /* istanbul ignore next */
    if (role === "vendor") {
      setVendorName(userVendorDetails?.name);
      setVendorId(userVendorDetails?.id);
      setVendorMiscInfo(
        "(" +
        userVendorDetails?.parentRelationships[0]?.customerInternalVal +
        ") " +
        userVendorDetails?.city,
      );
    }
  }, [role]);

  // ---------------------Mutation/Queries---------------------//

  //Api call to search for customers
  const {
    error: customerRelationshipErrors,
    loading: customerRelationshipLoading,
    data: customerRelationshipSearch,
  } = useQuery(CUSTOMER_RELATIONSHIP_SEARCH, {
    variables: {
      token,
      vendorId,
      search: isCustomer ? customerName : vendorName,
    },
    skip: customerName?.length <= 3,
  });

  //Api call to get equipment based on cus id
  const {
    error: customerEquipmentError,
    loading: customerEquipmentLoading,
    data: customerEquipmentData,
  } = useQuery(FETCH_EQUIPMENT_BY_CUSTOMER, {
    variables: {
      token,
      id: customerId,
    },
    // skip: !toggle,
  });

  // api call to fetch all work cat
  const {
    error: workCategoriesErrors,
    loading: workCategoriesLoading,
    data: workCategories,
  } = useQuery(FETCH_ALL_WORK_CATEGORIES, {
    variables: {
      token,
    },
  });

  //* API call to fetch contract details based on equipment
  // TODO: Need to include the error and load handling into the invoice_type dropdown component
  const {
    error: contractDetailsByIdError,
    loading: contractDetailsByIdLoading,
    data: contractDetailsById,
  } = useQuery(FETCH_CONTRACT_DETAILS_BY_EQUIPMENT, {
    variables: {
      token,
      equipmentId : equipmentData?.id
    },

    skip: !customerName && !vendorName && !equipmentData,
  });

  const [filteredInvoiceTypes, setFilteredInvoiceTypes] = useState(invoiceType);
  
  useEffect(() => {
    const amountType = contractDetailsById?.equipment?.contracts[0]?.amountType;
    const contractType = contractDetailsById?.equipment?.contracts[0]?.contractType;

    // Conditional logic for setting filteredInvoiceTypes
    if (amountType === "Flat Per Invoice" || contractType === "Cost Plus" || amountType === "Flat Per Equipment") {
      setFilteredInvoiceTypes([{ label: "Customer", value: "Customer" }]);
    } else if (amountType === null) {
      setFilteredInvoiceTypes(invoiceType);
    }
  }, [contractDetailsById]); // Set contractDetailsById as a dependency

  //Api call to add new workorder
  /* istanbul ignore next */
  const [addWorkorder, { loading }] = useMutation(CREATE_WORK_ORDER, {
    onCompleted: (data) => {
      /* istanbul ignore next */
      history.push(`/app/workorders/${data?.createWorkOrder?.workOrder?.id}`);
      refetchWorkOrders();
      closeModal(); // Access the response data here
      toast.success("Work Order created succesfully!");
    },
    onError: (error) => {
      toast.success("Unable to create a Work Order!");
    },
  });

  //api to add a new work order call mutation - submit
  /* istanbul ignore next */
  const onSubmit = async (data) => {
    try {
      const result = await addWorkorder({
        variables: {
          countAsMfc: mfcCheck,
          countAsPm: pmCheck,
          customerId: customerId,
          downtime: parseFloat(data?.downTime),
          driveMeter: parseInt(data?.driveMeter),
          equipmentId: equipmentData?.id,
          internalId: data?.internalId,
          invoiceType: data?.invoiceType.value,
          invoicedAt: data?.invoicedAt,
          keyOnMeter: parseInt(data?.keyOnMeter),
          pumpMeter: parseInt(data?.pumpMeter),
          deadmanMeter: parseInt(data?.deadmanMeter),
          serviceDescription: data?.serviceDescription,
          serviceType: data?.serviceType.value,
          startedAt: data?.startedAt,
          stoppedAt: data?.stoppedAt,
          model: equipmentData?.model,
          serialNumber: equipmentData?.serialNumber,
          token,
          po: data?.po?.toString() || undefined,
          vendorId: vendor_id,
        },
      });
  
      // Check if there are any errors in the result
      if (result?.errors) {
        console.error(result.errors);
        // Handle errors accordingly, for example, display an error message to the user
      } else {
        // Workorder submitted successfully
        // Optionally, you can handle success state or navigate to a different page
        console.log("Workorder submitted successfully");
      }
    } catch (error) {
      console.error(error);
      // Handle any unexpected errors
    }
  };
  

  //all functions init

  //function to handle customer click on search
  /* istanbul ignore next */
  const handleSingleCustomer = (customer) => {
    setToggle(!toggle);
    setCustomerMiscInfo(
      " (" +
      customer?.customerInternalVal +
      ") " +
      customer?.childCustomer?.internalName,
    );
    setCustomerId(customer?.childCustomer?.id);

    if (
      customer?.childCustomer?.contracts?.find((po) => po?.poRequired === true)
    ) {
      setPo(true);
    } else {
      setPo(false);
    }
    setFocus(false);

    setCustomerName(customer?.childCustomer.name);
  };
  /* istanbul ignore next */
  //function to handle vendor click on search
  const handleSingleVendor = (vendor) => {
    setVendorMiscInfo(
      " (" +
      vendor?.customerInternalVal +
      ") " +
      vendor?.childCustomer?.internalName,
    );
    setVendorId(vendor?.childCustomer?.id);
    setVendorFocus(false);
    setVendorName(vendor?.childCustomer?.name);
  };

  //var to handle customer equipment
  /* istanbul ignore next */
  const equipmentDataValue = customerEquipmentData?.customer?.equipment?.map(
    (d) => ({
      label: d?.serialNumber,
      value: d,
      model: d?.model,
      serialNumber: d?.serialNumber,
    }),
  );

  //function to handle customer equipment
  /* istanbul ignore next */
  const handleEquipmentValue = (res) => {
    console.log(res, "res");
    setequipmentData({
      ...res,
      name: res.label,
      id: res.value.id,
      model: res.model,
      serialNumber: res.serialNumber,
    });
  };

  const handleValidations = () => {
    setErrorsValue(true);
  };

  //handles cus search change
  const handleCustomerSearchChange = (e) => {
    setCustomerName(e.target.value);
    setIsCustomer(true);
  };

  //handles vendor search change
  const handleVendorSearchChange = (e) => {
    setVendorName(e.target.value);
    setIsCustomer(false);
  };

  //handles customer focus
  const handleCustomerFocus = (e) => {
    // dispatch(searchBooks(e.target.value, accessToken));
    setFocus(true);
  };

  //handles vendor focus
  const handleVendorFocus = (e) => {
    // dispatch(searchBooks(e.target.value, accessToken));
    setVendorFocus(true);
  };

  // dropdown icon
  /* istanbul ignore next */
  const DropdownIndicator = (props) => {
    return (
      <div>
        <components.DropdownIndicator {...props}>
          <img style={{ color: "white" }} src={WhitePlus}></img>
        </components.DropdownIndicator>
      </div>
    );
  };

  /* istanbul ignore next */
  return (
    <>
      <div className={classNames(classes.addModalContainer)}>
        <div className={classNames(classes.headerContainer)}>
          <div className={classNames(classes.modalHeader)}>Invoices</div>
        </div>{" "}
        {workCategoriesLoading || customerEquipmentLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={[]}
            />

            <span>Loading...</span>
          </Box>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classNames(classes.headerContainer)}>
              <Box sx={{ margin: "24px 0 12px 0" }}>
                <Grid
                  className={classNames(classes.formRow)}
                  container
                  spacing={2}
                >
                  <Grid item xs={6} md={6}>
                    <div>
                      <label>
                        Customer <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>

                    <div className="search">
                      <div className="input-group">
                        <input
                          id="search"
                          name="search"
                          type="text"
                          className="form-control search_dropdown"
                          onFocus={(e) => handleCustomerFocus(e)}
                          value={customerName}
                          onChange={handleCustomerSearchChange}
                        />
                      </div>
                    </div>
                    {focus &&
                      customerRelationshipSearch &&
                      customerRelationshipSearch?.searchCustomerRelationships && (
                        <div className="search_drop">
                          {customerRelationshipSearch
                            ?.searchCustomerRelationships?.length > 0 &&
                            customerRelationshipSearch?.searchCustomerRelationships?.map(
                              (customer, i) => (
                                <>
                                  <div
                                    className="search_items"
                                    onClick={() =>
                                      handleSingleCustomer(customer)
                                    }
                                  >
                                    {customer?.childCustomer?.name +
                                      " (" +
                                      customer?.customerInternalVal +
                                      ") " +
                                      customer?.childCustomer?.internalName}
                                  </div>
                                </>
                              ),
                            )}
                        </div>
                      )}
                    {!customerId && errorsValue && (
                      <span style={{ color: "red" }}>
                        {" "}
                        This field is required
                      </span>
                    )}

                    {role === "admin" && (
                      <div
                        onClick={(e) => handleClick(e, "customer")}
                        style={{ color: "blue" }}
                        className="cursor_pointer"
                      >
                        Don't find your customer? Click here to add a new
                        Customer!
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <div>
                      <label>Customer Info</label>
                    </div>

                    <Input
                      fullWidth
                      className={classes.textField}
                      disabled
                      value={customerMiscInfo}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <div>
                      <label>
                        Vendor <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>

                    <div className="search">
                      <div className="input-group">
                        <input
                          id="search"
                          name="search"
                          disabled={role === "vendor"}
                          type="text"
                          className="form-control search_dropdown"
                          onFocus={(e) => handleVendorFocus(e)}
                          value={vendorName}
                          onChange={handleVendorSearchChange}
                        />
                      </div>
                    </div>
                    {vendorFocus &&
                      customerRelationshipSearch &&
                      customerRelationshipSearch?.searchCustomerRelationships && (
                        <div className="search_drop">
                          {customerRelationshipSearch
                            ?.searchCustomerRelationships?.length > 0 &&
                            customerRelationshipSearch?.searchCustomerRelationships?.map(
                              (vendor, i) => (
                                <>
                                  <div
                                    className="search_items"
                                    onClick={() => handleSingleVendor(vendor)}
                                  >
                                    {vendor?.childCustomer?.name +
                                      " (" +
                                      vendor?.customerInternalVal +
                                      ") " +
                                      vendor?.childCustomer?.internalName}
                                  </div>
                                </>
                              ),
                            )}
                        </div>
                      )}
                    {!vendor_id && errorsValue && (
                      <span style={{ color: "red" }}>
                        {" "}
                        This field is required
                      </span>
                    )}
                  </Grid>{" "}
                  <Grid item xs={6} md={6}>
                    <div>
                      <label>Vendor Info</label>
                    </div>

                    <Input
                      fullWidth
                      className={classes.textField}
                      disabled
                      value={vendorMiscInfo}
                    />
                  </Grid>
                </Grid>
                <Grid
                  className={classNames(classes.formRow)}
                  container
                  spacing={2}
                >
                  <Grid item xs={4} md={4}>
                    <div>
                      <label>
                        Equipment <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <Select
                      className="react_select"
                      closeMenuOnSelect={true}
                      components={{ DropdownIndicator }}
                      isDisabled={customerId === null ? true : false}
                      onChange={(e) => handleEquipmentValue(e)}
                      // defaultValue={[colourOptions[4], colourOptions[5]]}
                      // isMulti
                      options={equipmentDataValue}
                    />
                    {!equipmentData?.id && errorsValue && (
                      <span style={{ color: "red" }}>
                        {" "}
                        This field is required
                      </span>
                    )}{" "}
                    {role === "admin" && (
                      <div
                        onClick={(e) => handleClick(e, "equipment")}
                        style={{ color: "blue" }}
                        className="cursor_pointer"
                      >
                        Click here to add a new Equipment!
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <div>
                      <label>Model</label>
                    </div>

                    <Input
                      fullWidth
                      className={classes.textField}
                      {...register("model")}
                      value={equipmentData && equipmentData?.model}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <div>
                      <label>Serial Number</label>
                    </div>

                    <Input
                      fullWidth
                      className={classes.textField}
                      {...register("serialNumber")}
                      disabled
                      value={equipmentData && equipmentData?.serialNumber}
                    />
                  </Grid>
                </Grid>
                {/* Row: "Start Date", "End Date", "Invoice At" */}
                <Grid
                  className={classNames(classes.formRow)}
                  container
                  spacing={2}
                >
                  <Grid item xs={4} md={4}>
                    <div>
                      <label>Start Date</label>
                    </div>

                    <Input
                      type={"date"}
                      fullWidth
                      className={classes.textField}
                      keyboardIcon={<img src={Plus}>a</img>}
                      {...register("startedAt", { required: false })}
                    />
                  </Grid>
                  <Grid item xs={4} md={4}>
                      <div>
                        <label>End Date</label>
                      </div>

                      <Input
                        type={"date"}
                        fullWidth
                        className={classes.textField}
                        {...register("stoppedAt", { required: false })}
                      />
                    </Grid>
                    <Grid item xs={4} md={4}>
                    <div>
                      <label>
                        Invoiced At<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>

                    <Input
                      type={"date"}
                      fullWidth
                      className={classes.textField}
                      keyboardIcon={<img src={Plus}>a</img>}
                      {...register("invoicedAt", { required: true })}
                    />
                    {errors.invoicedAt && (
                      <span style={{ color: "red" }}>
                        {" "}
                        This field is required
                      </span>
                    )}

                  </Grid>
                </Grid>
                <Grid className={classNames(classes.formRow)} container spacing={2}>
                  <Grid item xs={3} md={3}>
                    <div>
                        <label>
                          Work Order ID<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>

                      <Input
                        fullWidth
                        className={classes.textField}
                        {...register("internalId", { required: true })}
                      />
                      {errors.internalId && (
                        <span style={{ color: "red" }}>
                          {" "}
                          This field is required
                        </span>
                      )}
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <div>
                        <label>
                          Invoice Type<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <Controller
                        name="invoiceType"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            className="react_select"
                            closeMenuOnSelect={true}
                            components={{ DropdownIndicator }}
                            options={filteredInvoiceTypes}
                          />
                        )}
                      />
                      {errors.invoiceType &&
                        errors.invoiceType.type === "required" && (
                          <span style={{ color: "red" }}>
                            {" "}
                            This field is required
                          </span>
                      )}
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <div>
                      <label>
                        Service Type<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <Controller
                      name="serviceType"
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          className="react_select"
                          closeMenuOnSelect={true}
                          components={{ DropdownIndicator }}
                          // defaultValue={[colourOptions[4], colourOptions[5]]}

                          options={
                            workCategories &&
                            workCategories?.allWorkCategories?.map((cat) => {
                              return { label: cat?.value, value: cat?.value };
                            })
                          }
                        />
                      )}
                    />
                    {errors.serviceType &&
                      errors.serviceType.type === "required" && (
                        <span style={{ color: "red" }}>
                          {" "}
                          This field is required
                        </span>
                    )}
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <div>
                      <label>Downtime</label>
                    </div>

                    <Input
                      fullWidth
                      type="number"
                      className={classes.textField}
                      {...register("downTime")}
                    />
                  </Grid>
                </Grid>

              {/* Row: "Key On Meter", "Drive Meter", "Pump Meter", "Deadman Meter" */}
              <Grid className={classNames(classes.formRow)} container spacing={2}>
                <Grid item xs={3} md={3}>
                  <div>
                    <label>Key on Meter</label>
                  </div>

                  <Input
                    fullWidth
                    className={classes.textField}
                    {...register("keyOnMeter")}
                    type="number"
                  />
                </Grid>
                <Grid item xs={3} md={3}>
                  <div>
                    <label>Drive Meter</label>
                  </div>

                  <Input
                    fullWidth
                    className={classes.textField}
                    {...register("driveMeter")}
                    type="number"
                  />
                </Grid>
                <Grid item xs={3} md={3}>
                  <div>
                    <label>Pump Meter</label>
                  </div>

                  <Input
                    fullWidth
                    className={classes.textField}
                    {...register("pumpMeter")}
                    type="number"
                  />
                </Grid>
                <Grid item xs={3} md={3}>
                  <div>
                    <label>Deadman Meter</label>
                  </div>

                  <Input
                    fullWidth
                    className={classes.textField}
                    {...register("deadmanMeter")}
                    type="number"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                {/* First block */}
                <Grid item xs={8} md={8}>
                  <div>
                    <label>Is this Planned Maintenance/Major Fluid Change?</label>
                  </div>
                  <Select
                    className="react_select"
                    closeMenuOnSelect={true}
                    components={{ DropdownIndicator }}
                    value={
                      mfcCheck
                        ? { label: 'Major Fluid Change', value: 'mfc' }
                        : pmCheck
                        ? { label: 'Planned Maintenance', value: 'pm' }
                        : { label: 'None', value: 'none' }
                    }
                    onChange={(e) => {
                      if (e.value === 'mfc') {
                        setMfcCheck(true);
                        setPmCheck(false);
                      } else if (e.value === 'pm') {
                        setMfcCheck(false);
                        setPmCheck(true);
                      } else {
                        setMfcCheck(false);
                        setPmCheck(false);
                      }
                    }}
                    options={[
                      { label: 'Planned Maintenance', value: 'pm' },
                      { label: 'Major Fluid Change', value: 'mfc' },
                      { label: 'None', value: undefined }
                    ]}
                  />
                </Grid>
                <Grid item xs={4} md={4}>
                  <div>
                    <label>Purchase Order No.</label>
                  </div>
                  <Input
                      fullWidth
                      className={classes.textField}
                      {...register("po")}
                    />
                </Grid>
                </Grid>
                  <Grid item xs={12} md={12}>
                    <div>
                        <label>Description</label>
                    </div>
                    <textarea
                        row={100}
                        col={100}
                        className={classNames(classes.textField, classes.description_textArea)}
                        {...register("serviceDescription", {
                            required: "This field is required",
                            minLength: {
                                value: 15,
                                message: "Description should provide a detailed explanation of service and will be reviewed"
                            }
                        })}
                    />
                    {errors.serviceDescription && (
                        <span style={{ color: "red" }}>
                            {errors.serviceDescription.message}
                        </span>
                    )}
                </Grid>

                
                {/* <Grid
                  className={classNames(classes.formRow)}
                  container
                  spacing={2}
                >
                  <Grid item xs={6} md={6}>
                    <div>
                      <label>
                        Cause<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <Input
                      fullWidth
                      className={classes.textField}
                      {...register("cause", { required: true })}
                    />
                    {errors.cause && (
                      <span style={{ color: "red" }}>
                        {" "}
                        This field is required
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <div>
                      <label>
                        Cure<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <Input
                      fullWidth
                      className={classes.textField}
                      {...register("cure", { required: true })}
                    />
                    {errors.cure && (
                      <span style={{ color: "red" }}>
                        {" "}
                        This field is required
                      </span>
                    )}
                  </Grid>
                </Grid> */}
                {/* <Grid
                  className={classNames(classes.formRow)}
                  container
                  spacing={2}
                >
                  <Grid item xs={12} md={12}>
                    <div>
                      <label>
                        Vendor Notes<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <Input
                      fullWidth
                      className={classes.textField}
                      {...register("serviceDescription", { required: true })}
                    />
                    {errors.vendorNotes && (
                      <span style={{ color: "red" }}>
                        {" "}
                        This field is required
                      </span>
                    )}
                  </Grid>
                </Grid> */}



                {/* <Grid
                  className={classNames(classes.formRow)}
                  container
                  spacing={2}
                >
                  <Grid item xs={4} md={4}>
                    <div>
                      <label>
                        Does this Work Order qualify as a Planned Maintenance?
                      </label>
                    </div>

                    <input
                      type="checkbox"
                      fullWidth
                      className={classes.textField}
                      value={mfcCheck}
                      onChange={(e) => setMfcCheck(e.target.checked)}
                    />
                  </Grid>{" "}
                  <Grid item xs={4} md={4}>
                    <div>
                      <label>
                        Does this Work Order qualify as a Major Fluid Check?
                      </label>
                    </div>

                    <input
                      type="checkbox"
                      fullWidth
                      className={classes.textField}
                      value={pmCheck}
                      onChange={(e) => setPmCheck(e.target.checked)}
                    />
                  </Grid>
                  {po && (
                    <Grid item xs={4} md={4}>
                      <div>
                        <label>Po</label>
                      </div>

                      <Controller
                        name="po"
                        rules={{ required: po ? true : false }}
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            className="react_select"
                            closeMenuOnSelect={true}
                            components={{ DropdownIndicator }}
                            options={[
                              { label: "Yes", value: true },
                              { label: "No", value: false },
                            ]}
                          ></Select>
                        )}
                      />
                    </Grid>
                  )}
                </Grid> */}
                {/* <Grid
                className={classNames(classes.formRow)}
                container
                spacing={2}
              >
                <Grid item xs={6} md={6}>
                  <div>
                    <label>Upload Files</label>
                  </div>
                  <Box className={"fileUploader"}>
                    <FileUploader
                      handleChange={handleChange}
                      name="file"
                      types={fileTypes}
                    />
                    <div>
                      <label className={classes.fileTypeText}>
                        Accepted File Types: .PDF and CSV only
                      </label>
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={6} md={6}>
                  <div>
                    <label>Upload a Photo to Complete the Process</label>
                  </div>
                  <Box className={"fileUploader"}>
                    <FileUploader
                      handleChange={handleChange}
                      name="file"
                      types={fileTypes}
                    />
                    <div>
                      <label className={classes.fileTypeText}>
                        Accepted File Types: .PDF and CSV only
                      </label>
                    </div>
                  </Box>
                </Grid>
              </Grid> */}
              </Box>

              <Box
                sx={{
                  margin: "24px 0 24px 0",
                  display: "flex",
                  flexDirection: "row-reverse",
                }}
              >
                <button
                  className="upload_button"
                  type="submit"
                  onClick={handleValidations}
                >
                  Save
                </button>
                {/* <button className="cancel_button">Reset</button> */}
              </Box>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default AddMicroInvoiceForm;
