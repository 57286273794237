import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  paper: {
    display: "flex",
    alignItems: "center",
    width: 612,
    background: "#FFFFFF",
    border: "1px solid #DEDEDE",
    borderRadius: "3px",
    maxHeight: "32px",
    marginRight: "8px",
  },
}));
