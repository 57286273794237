// Users

import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation createUser(
    $token: String!
    $email: String!
    $firstName: String!
    $lastName: String!
    $userGroupId: Int!
    $role: String!
  ) {
    createUser(
      input: {
        token: $token
        email: $email
        firstName: $firstName
        lastName: $lastName
        userGroupId: $userGroupId
        role: $role
      }
    ) {
      user {
        id
      }
    }
  }
`;

export const SIGN_IN_USER = gql`
  mutation signInUser($email: String!, $password: String!) {
    signInUser(input: { credentials: { email: $email, password: $password } }) {
      token

      user {
        id

        email

        firstName

        lastName

        role

        userGroup {
          id

          customer {
            id
            name
            address1
            address2
            city
            country
          }
        }
      }

      errors {
        fieldName

        message
      }
    }
  }
`;

export const SIGN_OUT_USER = gql`
  mutation SignOutUser($token: String!) {
    signOutUser(input: { token: $token }) {
      response
    }
  }
`;

export const AUTO_SIGNIN = gql`
  mutation SignInUserUsingToken($token: String!) {
    signInUserUsingToken(input: { token: $token }) {
      token

      user {
        id

        email

        firstName

        lastName

        role

        userGroup {
          id

          customer {
            id
          }
        }
      }

      errors {
        fieldName

        message
      }
    }
  }
`;

// export const ADD_INVOICE = gql`
//   mutation createInvoice(
//     $token: String!
//     $internalId: String!
//     $customerId: String
//     $workOrderId: String
//     $maintenaceRecordId: String
//     $startRange: String
//     $endRange: String
//     $comment: String
//   ) {
//     invoice {
//       createInvoice(
//         input: {
//           token: $token
//           internalId: $internalId
//           customerId: $customerId
//           workOrderId: $workOrderId
//           maintenaceRecordId: $maintenaceRecordId
//           startRange: $startRange
//           endRange: $endRange
//           comment: $comment
//         }
//       )
//     }
//   }
// `;

export const ADD_INVOICE = gql`
  mutation createInvoice(
    $internalId: String
    $customerId: Int!
    $workOrderIds: [Int!]
    $maintenanceRecordIds: [Int!]
    $startRange: String!
    $endRange: String!
    $comment: String
    $token: String!
  ) {
    createInvoice(
      internalId: $internalId
      customerId: $customerId
      workOrderIds: $workOrderIds
      maintenanceRecordIds: $maintenanceRecordId
      startRange: $startRange
      endRange: $endRange
      comment: $comment
      token: $token
    ) {
      invoice {
        internalId
        customerId
        workOrders {
          id
        }
        maintenanceRecords {
          id
        }
        amount
        tax
        startRange
        endRange
        comment
        pin
        invoicedAt
        fleetServiceCharge
      }
      errors
    }
  }
`;

export const CREATE_WORK_ORDER = gql`
  mutation CreateWorkOrder(
    $token: String!
    $customerId: Int!
    $equipmentId: Int!
    $vendorId: Int!
    $internalId: String!
    $invoicedAt: String!
    $serviceType: String!
    $invoiceType: String!
    $serviceDescription: String
    $keyOnMeter: Int
    $driveMeter: Int
    $pumpMeter: Int
    $deadmanMeter: Int
    $downtime: Float
    $startedAt: String
    $stoppedAt: String
    $po: String
    $countAsPm: Boolean
    $countAsMfc: Boolean
  ) {
    createWorkOrder(
      input: {
        token: $token
        customerId: $customerId
        equipmentId: $equipmentId
        vendorId: $vendorId
        internalId: $internalId
        invoicedAt: $invoicedAt
        serviceType: $serviceType
        invoiceType: $invoiceType
        serviceDescription: $serviceDescription
        keyOnMeter: $keyOnMeter
        driveMeter: $driveMeter
        pumpMeter: $pumpMeter
        deadmanMeter: $deadmanMeter
        downtime: $downtime
        startedAt: $startedAt
        stoppedAt: $stoppedAt
        po: $po
        countAsPm: $countAsPm
        countAsMfc: $countAsMfc
      }
    ) {
      workOrder {
        id
      }
    }
  }
`;

export const UPDATE_LINE_ITEM = gql`
  mutation UpdateLineItem(
    $token: String!
    $id: Int!
    $productGroup: String
    $description: String
    $quantity: Float
    $unitPrice: Float
    $tax: Float
    $amount: Float
    $workSystemId: Int
    $workCategoryId: Int
    $internalId: String
  ) {
    updateLineItem(
      input: {
        token: $token
        id: $id
        productGroup: $productGroup
        description: $description
        quantity: $quantity
        unitPrice: $unitPrice
        tax: $tax
        amount: $amount
        workSystemId: $workSystemId
        workCategoryId: $workCategoryId
        internalId: $internalId
      }
    ) {
      lineItem {
        id
      }
    }
  }
`;

export const DESTROY_LINE_ITEM = gql`
  mutation DestroyLineItem($token: String!, $id: Int!) {
    destroyLineItem(input: { token: $token, id: $id }) {
      id
    }
  }
`;

export const UPDATE_BULK_WORKCATEGORY = gql`
  mutation ResetWorkCategories(
    $id: Int!
    $token: String!
    $work_category: Int
  ) {
    resetWorkCategories(
      input: { id: $id, token: $token, workCategory: $work_category }
    ) {
      workOrder {
        id
      }
      errors
    }
  }
`;

export const UPDATE_WORK_ORDER = gql`
  mutation UpdateWorkOrder(
    $token: String!
    $id: Int!
    $serviceType: String
    $serviceDescription: String
    $keyOnMeter: Int
    $driveMeter: Int
    $pumpMeter: Int
    $deadmanMeter: Int
    $downtime: Float
    $invoiceType: String
    $startedAt: String
    $stoppedAt: String
    $countAsPm: Boolean
    $countAsMfc: Boolean
    $aasmState: String
    $invoicedAt: String
    $disputed: [DisputeTypeInput!]
  ) {
    updateWorkOrder(
      input: {
        token: $token
        id: $id
        serviceType: $serviceType
        serviceDescription: $serviceDescription
        keyOnMeter: $keyOnMeter
        driveMeter: $driveMeter
        pumpMeter: $pumpMeter
        downtime: $downtime
        deadmanMeter: $deadmanMeter
        invoiceType: $invoiceType
        startedAt: $startedAt
        stoppedAt: $stoppedAt
        countAsPm: $countAsPm
        countAsMfc: $countAsMfc
        aasmState: $aasmState
        invoicedAt: $invoicedAt
        disputed: $disputed
      }
    ) {
      workOrder {
        id
      }
    }
  }
`;

export const CREATE_LINE_ITEM = gql`
  mutation CreateLineItem(
    $token: String!
    $workOrderId: Int!
    $productGroup: String!
    $description: String!
    $quantity: Float!
    $unitPrice: Float!
    $tax: Float
    $amount: Float!
    $workSystemId: Int
    $workCategoryId: Int!
    $internalId: String
  ) {
    createLineItem(
      input: {
        token: $token
        workOrderId: $workOrderId
        productGroup: $productGroup
        description: $description
        quantity: $quantity
        unitPrice: $unitPrice
        tax: $tax
        amount: $amount
        workSystemId: $workSystemId
        workCategoryId: $workCategoryId
        internalId: $internalId
      }
    ) {
      lineItem {
        id
        description
        quantity
        unitPrice
        amount
        tax
        internalId
        productGroup
        workSystem {
          id
          value
        }
        workCategory {
          id
          value
        }
      }
      errors
    }
  }
`;

export const SPLIT_LINE_ITEM = gql`
  mutation SplitLineItem(
    $token: String!
    $id: Int!
    $attachment: Float!
    $damage: Float!
    $pm: Float!
    $repair: Float!
    $request: Float!
    $tires: Float!
  ) {
    splitLineItem(
      input: {
        token: $token
        id: $id
        attachment: $attachment
        damage: $damage
        pm: $pm
        repair: $repair
        request: $request
        tires: $tires
      }
    ) {
      lineItems {
        id
      }
    }
  }
`;

// Notes
export const CREATE_NOTE = gql`
  mutation CreateNote(
    $token: String!
    $notableType: String!
    $notableId: Int!
    $content: String!
  ) {
    createNote(
      input: {
        token: $token
        notableType: $notableType
        notableId: $notableId
        content: $content
      }
    ) {
      note {
        id
        content
        createdAt
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const EDIT_NOTE = gql`
  mutation UpdateNote(
    $id: Int!
    $notableType: String
    $notableId: Int
    $content: String
    $userId: Int
  ) {
    updateNote(
      input: {
        id: $id
        notableType: $notableType
        notableId: $notableId
        content: $content
        userId: $userId
      }
    ) {
      note {
        id
        content
        createdAt
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const DESTROY_NOTE = gql`
  mutation DestroyNote($id: Int!) {
    destroyNote(input: { id: $id }) {
      id
      errors
    }
  }
`;

export const UPDATE_USER_DOCUMENT_AGREEMENT = gql`
  mutation UpdateUserDocumentAgreement(
    $token: String!
    $id: Int!
    $signedAt: String
    $documentId: Int
    $userId: Int
  ) {
    updateUserDocumentAgreement(
      input: {
        token: $token
        id: $id
        signedAt: $signedAt
        userId: $userId
        documentId: $documentId
      }
    ) {
      userDocumentAgreement {
        id
      }
      errors
    }
  }
`;

export const GENERATE_WORK_ORDER_PDF = gql`
  mutation GenerateWorkOrderPdf($token: String!, $id: Int!) {
    generateWorkOrderPdf(input: { token: $token, id: $id }) {
      workOrderFile
      errors
    }
  }
`;

export const CREATE_DOCUMENT = gql`
  mutation CreateDocument(
    $token: String!
    $name: String!
    $content: String!
    $startRange: String!
    $endRange: String
    $userType: String
    $length: Int!
  ) {
    createDocument(
      input: {
        token: $token
        name: $name
        content: $content
        startRange: $startRange
        endRange: $endRange
        userType: $userType
        length: $length
      }
    ) {
      document {
        id
      }
    }
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer(
    $token: String!
    $name: String!
    $vendorId: Int!
    $customerInternalVal: String!
    $internalName: String
    $address1: String
    $address2: String
    $city: String
    $province: String
    $postalCode: String
    $country: String
    $hpd: Int
    $dpw: Int
  ) {
    createCustomer(
      input: {
        token: $token
        name: $name
        vendorId: $vendorId
        customerInternalVal: $customerInternalVal
        internalName: $internalName
        address1: $address1
        address2: $address2
        city: $city
        province: $province
        postalCode: $postalCode
        country: $country
        hpd: $hpd
        dpw: $dpw
      }
    ) {
      customer {
        id
        name
        internalName
        parentRelationships {
          id
          customerInternalVal
        }
      }
    }
  }
`;

export const CREATE_EQUIPMENT = gql`
  mutation CreateEquipment(
    $token: String!
    $vendorEquipmentId: String!
    $ownerId: Int!
    $customerId: Int!
    $maintenanceLevel: String!
    $fleetNumber: String
    $year: Int
    $model: String
    $make: String
    $serialNumber: String
    $acquisitionCost: Float
    $plannedHours: Int
    $description: String
    $group: String
  ) {
    createEquipment(
      input: {
        token: $token
        vendorEquipmentId: $vendorEquipmentId
        ownerId: $ownerId
        customerId: $customerId
        maintenanceLevel: $maintenanceLevel
        fleetNumber: $fleetNumber
        year: $year
        model: $model
        make: $make
        serialNumber: $serialNumber
        acquisitionCost: $acquisitionCost
        plannedHours: $plannedHours
        description: $description
        group: $group
      }
    ) {
      equipment {
        id
        vendorEquipment {
          id
          vendorEquipmentId
        }
      }
    }
  }
`;

export const UPDATE_DOCUMENT = gql`
  mutation UpdateDcoument(
    $token: String!
    $id: Int!
    $name: String
    $content: String
    $startRange: String
    $endRange: String
    $userType: String
    $length: Int
    $archivedAt: String
  ) {
    updateDocument(
      input: {
        token: $token
        id: $id
        name: $name
        content: $content
        startRange: $startRange
        endRange: $endRange
        userType: $userType
        length: $length
        archivedAt: $archivedAt
      }
    ) {
      document {
        id
      }
    }
  }
`;

export const CREATE_USER_GROUP = gql`
  mutation CreateUserGroup(
    $customerId: Int!
    $name: String!
    $archivedAt: String
  ) {
    createUserGroup(
      input: { customerId: $customerId, name: $name, archivedAt: $archivedAt }
    ) {
      userGroup {
        id
        name
        archivedAt
      }
      errors
    }
  }
`;
