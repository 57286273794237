export const ActionTypes = {
  //AUTH CONSTANTS
  USER_AUTHENTICATE: "USER_AUTHENTICATE",
  LOGOUT: "LOGOUT",

  //INOICES CONSTANTS
  INVOICE_ID: "INVOICE_ID",
  GET_INVOICES_DATA: "GET_INVOICES_DATA",
  GET_VENDOR_WORK_ORDER_DATA: "GET_VENDOR_WORK_ORDER_DATA",
  GET_CUSTOMER_WORKORDERS: "GET_CUSTOMER_WORKORDERS",
  GET_CUSTOMER_MAINTENANCERECORDS: "GET_CUSTOMER_MAINTENANCERECORDS",
  GET_INVOICE_DATA: "GET_INVOICE_DATA",
  GET_VENDOR_DATA: "GET_VENDOR_DATA",
  GET_DOCUMENTS_DATA: "GET_DOCUMENTS_DATA",
  GET_CONTRACTS_DATA: "GET_CONTRACTS_DATA",
  GET_INVOICE_WORKORDERS_DATA: "GET_INVOICE_WORKORDERS_DATA",
  GET_INVOICE_MAINTENANCERECORDS: "GET_INVOICE_MAINTENANCERECORDS",
  GET_USER_VENDOR_DETAIL: "GET_USER_VENDOR_DETAIL",

  //CUSTOMERS CONSTANTS

  GET_ALL_CUSTOMERS: "GET_ALL_CUSTOMERS",
  GET_ALL_VENDORS: "GET_ALL_VENDORS",

  //LOADER CONSTANTS

  LOADER_STATUS: "LOADER_STATUS",
};
