import React, { useCallback, useState, useEffect } from "react";
import BasicCard from "../../../components/InvoiceCard/Card";
import Box from "@mui/material/Box";
import { Grid, Input } from "@mui/material";
import classNames from "classnames";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Select, { components, DropdownIndicatorProps } from "react-select";

import { useQuery, gql } from "@apollo/client";
import { FETCH_ALL_INVOICES } from "../../../GraphQL/Queries";

// import { colourOptions } from "./utils";

// File Uploader
import { FileUploader } from "react-drag-drop-files";

//DateRange
import { DateRangePicker } from "react-date-range";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

//Styles

import useStyles from "../styles";
import PageTitle from "../../../components/PageTitle/PageTitle";
import SearchBar from "../../../components/SearchBar/SearchBar";
import { Button, TextField } from "@material-ui/core";

//Icons

import Calendar from "../../../assets/img/icons/calendar.svg";
import { addDays } from "date-fns";
import Filter from "../../../assets/img/icons/filter.svg";
import Upload from "../../../assets/img/icons/upload.svg";
import Plus from "../../../assets/img/icons/plus.svg";
import AddInvoiceForm from "../components/forms/AddInvoiceForm";

import { useDispatch, useSelector } from "react-redux";
import { getInvoicesData } from "../../../redux/actions/invoiceActions";
import { useLocation } from "react-router-dom";
import OverallTable from "../InvoiceTable/OverallTable";
import VendorsInvoiceTable from "../InvoiceTable/VendorsInvoiceTable";

// File Types to be uploaded

const fileTypes = ["JPG", "PNG", "GIF"];

export default function InvoiceStatusTable() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { state } = useLocation();

  // console.log(state, "stateejn");
  // console.log(state, "datetee");

  const [icon, setIcon] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [search, setSearch] = useState();
  const [file, setFile] = useState(null);
  const [dateState, setdateState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);

  // Use Selector to get the data from store
  /* istanbul ignore next */
  const authUserData = useSelector((state) => state?.auth?.authUserData);
  /* istanbul ignore next */
  const invoicesData = useSelector(
    (state) => state?.invoices?.getAllInvoicesData?.allInvoices,
  );

  /* istanbul ignore next */
  const token = useSelector((state) => state?.auth?.authUserData?.token);

  const { error, loading, data } = useQuery(FETCH_ALL_INVOICES, {
    variables: { token },
  });
  // console.log(search, "search");
  const handleChange = (file) => {
    setFile(file);
  };

  useEffect(() => {
    dispatch(getInvoicesData(data));
  }, [data]);

  // Dropdown Popover for icons

  const PopoverDisplay = () => {
    return (
      <div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {/* Calendar Popover */}
          {icon === "calendar" && (
            <DateRangePicker
              ranges={[dateSelectionRange]}
              onChange={handleSelect}
            />
          )}

          {/* Upload Popover */}
          {icon === "upload" && (
            <>
              <div className={classNames(classes.modalContainer)}>
                <div className={classNames(classes.headerContainer)}>
                  <div className={classNames(classes.modalHeader)}>
                    Add Document
                  </div>
                </div>

                <div className={classNames(classes.headerContainer)}>
                  <Box sx={{ margin: "24px 0 12px 0" }}>
                    <div>
                      <label>Title</label>
                    </div>
                    <Input className={classes.textField} />
                  </Box>
                  <Box
                    sx={{
                      margin: "24px 0 12px 0",
                    }}
                  >
                    <div>
                      <label>Attach Document</label>
                    </div>
                    <Box className={"fileUploader"}>
                      <FileUploader
                        handleChange={handleChange}
                        name="file"
                        types={fileTypes}
                      />
                      <div>
                        <label className={classes.fileTypeText}>
                          Accepted File Types: .PDF and CSV only
                        </label>
                      </div>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      margin: "24px 0 24px 0",
                      display: "flex",
                      flexDirection: "row-reverse",
                    }}
                  >
                    <button className="upload_button">Upload</button>
                    <button className="cancel_button">Cancel</button>
                  </Box>
                </div>
              </div>
            </>
          )}

          {/* Create New Invoice popover*/}

          {icon === "newInvoice" && <AddInvoiceForm />}
        </Popover>
      </div>
    );
  };
  /* istanbul ignore next */
  const handleClick = (event, type) => {
    setIcon(type);
    setAnchorEl(event.currentTarget);
  };

  const dateSelectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };
  /* istanbul ignore next */
  function handleSelect(ranges) {
    console.log(ranges);
  }
  /* istanbul ignore next */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  /* istanbul ignore next */
  const id = open ? "simple-popover" : undefined;
  /* istanbul ignore next */
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          borderRadius: 1,
          alignItems: "center",
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <PageTitle
            breadcrumb={true}
            title={"Invoices"}
            subTitle={`${state && state.cardName}`}
            location={"/app/invoices"}
          />
        </Box>
        <Box>
          <Box className={classes.paper}>
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search here by customer’s name, vendor’s invoice or TDW invoice No. "
              text="Search"
            />
          </Box>
        </Box>
        <Box className={classNames(classes.buttonContainer)}>
          <Button
            onClick={(e) => handleClick(e, "calendar")}
            className={classNames(classes.button)}
          >
            <img src={Calendar}></img>
          </Button>
          <Button className={classNames(classes.button)}>
            <img src={Filter}></img>
          </Button>
          <Button
            onClick={(e) => handleClick(e, "upload")}
            className={classNames(classes.button)}
          >
            <img src={Upload}></img> &nbsp;&nbsp;Upload
          </Button>
          <Button
            onClick={(e) => handleClick(e, "newInvoice")}
            className={classNames(classes.button)}
          >
            <img src={Plus}></img> &nbsp;&nbsp;Create New
          </Button>
        </Box>
      </Box>

      {state?.tableName == "All Invoices" && (
        <OverallTable
          invoicesData={state && state?.allInvoiceData}
          search={search}
          perPage={25}
        />
      )}
      {state?.tableName == "Vendor Invoices" && (
        <VendorsInvoiceTable
          invoicesData={state && state?.allInvoiceData}
          search={search}
          perPage={25}
        />
      )}
      {PopoverDisplay()}
    </div>
  );
}
