import { Box, Grid } from "@mui/material";
import React from "react";
import BasicCard from "../../../../components/InvoiceCard/Card";
import classNames from "classnames";

const CardContent = ({
  tabsparentState,
  vendorStatus,
  customerInvoiceStatus,
  classes,
  search,
  vendorInvoiceData,
  invoicesData,
  image,
}) => {
  /* istanbul ignore next */
  return (
    <Box className={classNames(classes.cardBox)}>
      <Grid container spacing={2}>
        {tabsparentState == "Vendor Invoices" &&
          vendorStatus &&
          vendorStatus?.map((status) => (
            <>
              {vendorInvoiceData &&
                vendorInvoiceData?.filter(
                  (filter) => filter?.aasmState === status,
                ).length > 0 && (
                  <Grid item md={2} lg={2} xs={12}>
                    <BasicCard
                      invoiceCount={
                        vendorInvoiceData &&
                        vendorInvoiceData?.filter(
                          (filter) => filter?.aasmState === status,
                        ).length
                      }
                      data={
                        vendorInvoiceData &&
                        vendorInvoiceData?.filter(
                          (filter) => filter?.aasmState === status,
                        )
                      }
                      cardName={`${status} Invoices`}
                      invoice_image={image}
                      tableName={"All Invoices"}
                      search={search}
                      status={"open"}
                    />
                  </Grid>
                )}
            </>
          ))}
        {tabsparentState == "End Customer Invoices" &&
          customerInvoiceStatus &&
          customerInvoiceStatus?.map((status) => (
            <>
              {invoicesData &&
                invoicesData?.filter((filter) => filter?.aasmState === status)
                  .length > 0 && (
                  <Grid item md={2} lg={2} xs={12}>
                    <BasicCard
                      invoiceCount={
                        invoicesData &&
                        invoicesData?.filter(
                          (filter) => filter?.aasmState === status,
                        ).length
                      }
                      data={
                        invoicesData &&
                        invoicesData?.filter(
                          (filter) => filter?.aasmState === status,
                        )
                      }
                      cardName={`${status} Invoices`}
                      invoice_image={image}
                      tableName={"All Invoices"}
                      search={search}
                      status={"open"}
                    />
                  </Grid>
                )}
            </>
          ))}
      </Grid>
    </Box>
  );
};

export default CardContent;
