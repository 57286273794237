import React, { useCallback, useState } from "react";
import {
  Button,
  Grid,
  Input,
  Divider,
  Avatar,
  Paper,
  Box,
} from "@mui/material";
import classNames from "classnames";

//Styles

import useStyles from "../../../invoices/styles";
import { useMutation } from "@apollo/client";
import {
  CREATE_NOTE,
  DESTROY_NOTE,
  EDIT_NOTE,
} from "../../../../GraphQL/Mutations";
import { toast } from "react-toastify";
import edit_pencil from "../../../../assets/img/icons/edit_pencil.svg";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { useSelector } from "react-redux";

export default function VendorNotes({
  workOrderData,
  token,
  refetchWorkOrder,
}) {
  const [note, setNote] = useState({});
  const [noteDesc, setNoteDesc] = useState("");
  const [noteDisabled, setNoteDisabled] = useState(true);
  const classes = useStyles();

  const userId = useSelector((state) => state?.auth?.authUserData?.user?.id);
  /* istanbul ignore next */
  const [createVendor, { loading }] = useMutation(CREATE_NOTE, {
    onCompleted: (data) => {
      //   setisDisabled(true);
      //   handleClose(); // Access the response data here
      toast.success("Work Order updated succesfully!");
      setNoteDesc("");
      refetchWorkOrder();
    },
    onError: (error) => {
      toast.error("Unable to update Work Order!");
    },
  });
  /* istanbul ignore next */
  const [updateNote, { updateLoading }] = useMutation(EDIT_NOTE, {
    onCompleted: (data) => {
      //   setisDisabled(true);
      //   handleClose(); // Access the response data here
      toast.success("Work Order updated succesfully!");
      setNote({});
      setNoteDisabled(!noteDisabled);
      refetchWorkOrder();
    },
    onError: (error) => {
      toast.error("Unable to update Work Order!");
    },
  });
  /* istanbul ignore next */
  const [destroyNote, { destroyLoading }] = useMutation(DESTROY_NOTE, {
    onCompleted: (data) => {
      //   setisDisabled(true);
      //   handleClose(); // Access the response data here
      toast.success("Work Order updated succesfully!");
      refetchWorkOrder();
    },
    onError: (error) => {
      toast.error("Unable to update Work Order!");
    },
  });
  /* istanbul ignore next */
  const handleVendorSubmit = () => {
    createVendor({
      variables: {
        token,
        notableType: "WorkOrder",
        notableId: workOrderData?.id,
        content: noteDesc,
      },
    });
  };
  /* istanbul ignore next */
  const editModeNote = (note) => {
    setNote(note);
    setNoteDisabled(!noteDisabled);
  };
  /* istanbul ignore next */
  const onSaveNote = () => {
    updateNote({
      variables: {
        id: note?.id,
        notableType: "WorkOrder",
        notableId: workOrderData?.id,
        content: note?.content,
        userId: userId,
      },
    });
  };
  /* istanbul ignore next */
  const deleteNote = (note) => {
    destroyNote({
      variables: {
        id: note?.id,
      },
    });
  };

  console.log(note, "dwqdwqed");
/* istanbul ignore next */
  return (
    <div className={classNames(classes.modalContainer)}>
      <div className={classNames(classes.headerContainer)}>
        <div className={classNames(classes.editInvoiceHeader)}>
          Vendor Notes
        </div>
      </div>
      <div>
        {workOrderData &&
          workOrderData?.notes?.map((data) => (
            <div>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item xs zeroMinWidth>
                  <Grid className={classNames(classes.contentRow)} container>
                    <Grid lg={11} item xs zeroMinWidth>
                      <span className={classNames(classes.noteName)}>
                        {data?.user?.firstName + " " + data?.user?.lastName}
                      </span>
                    </Grid>
                    <Grid item xs zeroMinWidth>
                      {(noteDisabled || note?.id === data?.id) &&
                        (noteDisabled ? (
                          <div
                            className="cursor-pointer"
                            onClick={() => editModeNote(data)}
                          >
                            <img src={edit_pencil} />
                          </div>
                        ) : (
                          <div
                            className="cursor-pointer"
                            onClick={() => onSaveNote()}
                          >
                            <SaveIcon sx={{ color: "#00b4d8" }} />
                          </div>
                        ))}
                    </Grid>{" "}
                    <Grid item xs zeroMinWidth>
                      <div
                        className="cursor-pointer"
                        onClick={() => deleteNote(data)}
                      >
                        <DeleteIcon sx={{ color: "red" }} />
                      </div>
                    </Grid>
                  </Grid>
                  {noteDisabled || note?.id !== data?.id ? (
                    <p>{data?.content}</p>
                  ) : (
                    <textarea
                      className={classNames(
                        classes.textField,
                        classes.description_textArea,
                        classes.vendorDiscription,
                      )}
                      value={note?.content}
                      onChange={(e) =>
                        setNote({ ...note, content: e.target.value })
                      }
                    />
                  )}
                  <p>
                    Created At: {moment(data?.createdAt).format("MM-DD-YYYY")}
                  </p>
                </Grid>
              </Grid>
              <Divider variant="fullWidth" />
            </div>
          ))}

        <div>
          <textarea
            row={100}
            col={100}
            className={classNames(
              classes.textField,
              classes.description_textArea,
              classes.vendorDiscription,
            )}
            onChange={(e) => setNoteDesc(e.target.value)}
            value={noteDesc}
          />
          <Box>
            <button className="upload_button" onClick={handleVendorSubmit}>
              Submit
            </button>
          </Box>
        </div>
      </div>
    </div>
  );
}
