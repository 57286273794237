import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import classNames from "classnames";
import Divider from "@mui/material/Divider";
import { format } from "date-fns";

//style
import useStyles from "../styles";
import {
  FETCH_ALL_CONTRACTS,
  FETCH_ALL_MAINTANANCE_RECORDS,
  FETCH_WORK_ORDER,
} from "../../../GraphQL/Queries";
import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import {
  getInvoiceWorkOrdersData,
  getMaintenanceRecordsData,
} from "../../../redux/actions/invoiceActions";
import { ActionTypes } from "../../../redux/constants/actionTypes";
import moment from "moment";
import { useHistory } from "react-router-dom";

export default function Workorders({ invoiceId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const token = useSelector((state) => state?.auth?.authUserData?.token);
  const invoiceWorkOrdersData = useSelector(
    (state) =>
      state?.invoices?.getAllInvoiceWorkOrdersData?.allWorkOrdersByInvoiceId,
  );

  console.log(invoiceWorkOrdersData, "invoiceWorkOrdersData");

  const {
    error: workOrderError,
    loading: workOrderLoading,
    data: invoiceWorkOrders,
  } = useQuery(FETCH_WORK_ORDER, {
    variables: { token, id: invoiceId },
  });

  React.useEffect(() => {
    dispatch(getInvoiceWorkOrdersData(invoiceWorkOrders));
  }, [invoiceWorkOrders]);

  console.log(invoiceWorkOrdersData, "invoiceWorkOrdersData");
/* istanbul ignore next */
  return (
    <TableContainer className={classNames(classes.tableContainer)}>
      {invoiceWorkOrdersData && invoiceWorkOrdersData?.length ? (
        <>
          <Table
            className={classNames(classes.table)}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  className={classNames(classes.tableCell1)}
                >
                  Date
                </TableCell>
                <TableCell
                  align="left"
                  className={classNames(classes.tableCell2)}
                >
                  DocumentNo
                </TableCell>
                <TableCell
                  align="left"
                  className={classNames(classes.tableCell3)}
                >
                  Name
                </TableCell>
                <TableCell
                  align="left"
                  className={classNames(classes.tableCell4)}
                >
                  Site
                </TableCell>
                <TableCell
                  align="left"
                  className={classNames(classes.tableCell1)}
                >
                  Lines
                </TableCell>
                <TableCell
                  align="left"
                  className={classNames(classes.tableCell2)}
                >
                  Subtotal
                </TableCell>
                <TableCell
                  align="left"
                  className={classNames(classes.tableCell3)}
                >
                  Tax
                </TableCell>
                <TableCell
                  align="left"
                  className={classNames(classes.tableCell4)}
                >
                  Total
                </TableCell>
                <TableCell
                  align="left"
                  className={classNames(classes.tableCell3)}
                >
                  Remaining
                </TableCell>
                <TableCell
                  align="left"
                  className={classNames(classes.tableCell4)}
                >
                  DueDate
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceWorkOrdersData &&
                invoiceWorkOrdersData?.length &&
                invoiceWorkOrdersData.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      className={classNames(classes.tableCell5)}
                    >
                      {format(new Date(row?.invoicedAt), "MM-dd-yyyy")}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classNames(classes.tableCell6)}
                    >
                      <div
                        onClick={() =>
                          history.push(`/app/workorders/${row?.id}`)
                        }
                        className="cursor-pointer"
                      >
                        {row?.internalId}
                      </div>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classNames(classes.tableCell6)}
                    >
                      {row?.customer?.name}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classNames(classes.tableCell6)}
                    >
                      {row?.customer?.internalName}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      className={classNames(classes.tableCell5)}
                    >
                      {row.lineItems.reduce(
                        (accumulator, current) =>
                          accumulator + current.quantity,
                        0,
                      )}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classNames(classes.tableCell6)}
                    >
                      {row.lineItems
                        .reduce(
                          (accumulator, current) =>
                            accumulator +
                            (current.amount + (current.tax ? current.tax : 0)),
                          0,
                        )
                        .toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classNames(classes.tableCell6)}
                    >
                      {row?.lineItems
                        .reduce(
                          (accumulator, current) =>
                            accumulator + (current.tax ? current.tax : 0),
                          0,
                        )
                        .toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classNames(classes.tableCell6)}
                    >
                      {row?.lineItems
                        .reduce(
                          (accumulator, current) =>
                            accumulator +
                            (current.amount + (current.tax ? current.tax : 0)),
                          0,
                        )
                        .toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classNames(classes.tableCell6)}
                    >
                      {/* {row.Remaining} */}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classNames(classes.tableCell6)}
                    >
                      {/* {row.DueDate} */}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <div className={classNames(classes.tableCell0)}>
            <div className={classNames(classes.tableCell20)}>
              &nbsp;&nbsp; Total
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {"$ " +
                invoiceWorkOrdersData
                  .reduce(
                    (accumulator, current) =>
                      accumulator + parseFloat(current?.lineItemTotal),
                    0,
                  )
                  .toFixed(2)}
            </div>
          </div>
          <br />
        </>
      ) : (
        "No workorders found for this invoice!"
      )}

      {/* <div className={classNames(classes.tableCell22)}>
        SALES SUBTOTAL &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$232,945.42
        <div>
          CONTRACT SUBTOTAL
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; $1887.98
        </div>
        <div>
          WORKORDER SUBTOTAL &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {"$ " + (lineItemsTotal ? lineItemsTotal : "0")}
        </div>
      </div> */}
      {/* <br />
      <Divider variant="middle" />
      <div className={classNames(classes.tableCell23)}>
        <br />
        BALANCE AMOUNT &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; $237,487.40
        <br />
        <br />
      </div> */}
      <Divider variant="middle" />
    </TableContainer>
  );
}
