import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import classNames from "classnames";

//Styles

import useStyles from "./styles";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { UPDATE_WORK_ORDER } from "../../GraphQL/Mutations";

export default function BasicModal({
  open,
  setOpenModal,
  formData,
  token,
  setisDisabled,
  setErrorsValue,
  refetchWorkOrder,
}) {
  const classes = useStyles();
   /* istanbul ignore next */
  const handleClose = () => setOpenModal(false);

   /* istanbul ignore next */
  const [editWorkOrder, { loading }] = useMutation(UPDATE_WORK_ORDER, {
    onCompleted: (data) => {
      setisDisabled(true);
      handleClose(); // Access the response data here
      toast.success("Work Order updated succesfully!");
      refetchWorkOrder();
    },
    onError: (error) => {
      toast.error("Unable to update Work Order!");
    },
  });

   /* istanbul ignore next */
  const onFormSubmit = (e) => {
    setErrorsValue(true);
    console.log(formData, "formData");
    editWorkOrder({
      variables: {
        token: token,
        id: formData?.id,
        serviceType: formData?.serviceType,
        serviceDescription: formData?.serviceDescription,
        keyOnMeter: parseInt(formData?.keyOnMeter),
        driveMeter: parseInt(formData?.driveMeter),
        pumpMeter: parseInt(formData?.pumpMeter),
        deadmanMeter: parseInt(formData?.deadmanMeter),
        downtime: parseFloat(formData?.downtime),
        invoiceType: formData?.invoiceType,
        startedAt: formData?.startedAt,
        stoppedAt: formData?.stoppedAt,
        countAsPm: formData?.countAsPm,
        countAsMfc: formData?.countAsMfc,
        aasmState: formData?.aasmState,
        invoicedAt: formData?.invoicedAt,
      },
    });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classNames(classes.style)}>
          <CloseIcon
            onClick={handleClose}
            className={classNames(classes.closeicon)}
          />
          <span className={classNames(classes.span1)}>Do you want to save</span>
          <br />
          <span
            className={classNames(classes.span2)}
            id="modal-modal-title"
            variant="h5"
          >
            the changes?
          </span>
          <br />
          <br />
          <span className={classNames(classes.span3)}>
            All the changes will be saved and
          </span>
          <br />
          <span className={classNames(classes.span4)}>
            will not be revert back
          </span>
          <br />
          <br />
          <br />
          <Box className={classNames(classes.style2)}>
            <Button
              className={classNames(classes.cancelbutton)}
              variant="outlined"
              onClick={handleClose}
            >
              Don't Save
            </Button>
            <Button
              className={classNames(classes.savebutton)}
              color="primary"
              variant="contained"
              onClick={onFormSubmit}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
