import { makeStyles } from "@material-ui/styles";

const drawerWidth = 240;

export default makeStyles((theme) => ({
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  headerMenuButton: {},
  headerMenuButtonCollapse: {
    justifyContent: "center",
    marginLeft: "0px",
    marginTop: "20px",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: "250px",
    background: "linear-gradient(to bottom, #023E8A, #0b354e) !important",
    transition: theme.transitions?.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions?.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: "linear-gradient(to bottom, #023E8A, #0b354e) !important",
    overflowX: "hidden",
    width: "70px",
    [theme.breakpoints?.down("sm")]: {
      width: drawerWidth,
    },
  },
  toolbar: {
    ...theme.mixins?.toolbar,
    [theme.breakpoints?.down("sm")]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  /* sidebarList: {
    marginTop: theme.spacing(6),
  }, */
  mobileBackButton: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(3),
    [theme.breakpoints.only("sm")]: {
      marginTop: theme.spacing(0.625),
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));
