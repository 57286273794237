import React, { useCallback, useState } from "react";
import PageTitle from "../../../components/PageTitle/PageTitle";
import Box from "@mui/material/Box";
import { Button, Grid, Input, Divider, Avatar, Paper } from "@mui/material";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

//Icons

import Edit from "../../../assets/img/icons/edit.svg";
import Print from "../../../assets/img/icons/print.svg";
import Download from "../../../assets/img/icons/download.svg";
import History from "../../../assets/img/icons/history.svg";
import Save from "../../../assets/img/icons/Save.svg";

//Styles

import useStyles from "./styles";
import EditInvoiceForm from "../../workOrders/components/forms/EditInvoiceForm";
import { useMutation, useQuery } from "@apollo/client";
import { FETCH_SINGLE_WORK_ORDER } from "../../../GraphQL/Queries";
import { useSelector } from "react-redux";
import VendorNotes from "../components/vendorNotes/VendorNotes";
import {
  GENERATE_WORK_ORDER_PDF,
  UPDATE_LINE_ITEM,
  UPDATE_WORK_ORDER,
} from "../../../GraphQL/Mutations";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader/Loader";
import AddRaiseDisputemodel from "../../../components/Modal/RaiseDispute/raiseDisputeModal";
import ViewRaiseDisputemodel from "../../../components/Modal/ViewDispute/viewDispute";
import ModalHistory from "../../../components/History/ModalHistory";

export default function SingleInvoice(props) {
  let { invoiceNumber } = useParams();
  const { handleSubmit } = useForm();
  const classes = useStyles();

  // handling states
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isDisabled, setisDisabled] = useState(true);
  const [icon, setIcon] = React.useState(null);
  const [show, setShow] = useState(false);
  const [showViewDispute, setshowViewDispute] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [updatedWorkOrder, setUpdatedWorkOrder] = React.useState({});

  const [historyopen, setHistoryOpen] = React.useState(false);
  const handleHistoryOpen = () => setHistoryOpen(true);
  const handleHistoryClose = () => setHistoryOpen(false);

  // handling useselectors
  const token = useSelector((state) => state?.auth?.authUserData?.token);
  const authRole = useSelector(
    (state) => state?.auth?.authUserData?.user?.role,
  );
  const workOrderData = useSelector(
    (state) => state?.invoices?.getInvoiceData?.workOrder,
  );

  //----------------- Handling usequeries and mutations----------------------//

  // Api call to fetch single work order
  const { error, loading, data, refetch } = useQuery(FETCH_SINGLE_WORK_ORDER, {
    variables: { token, id: parseInt(invoiceNumber) },
  });

  // Api call to generate work order pdf
  /* istanbul ignore next */
  const [generateWorkOrderPDF, { l }] = useMutation(GENERATE_WORK_ORDER_PDF, {
    onCompleted: (data) => {
      const url = data?.generateWorkOrderPdf?.workOrderFile;
      const a = document.createElement("a");
      a.href = url;
      a.download = "workOrder.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    },
    onError: (error) => {
      toast.error("Unable to update Work Order!");
    },
  });

  // handling functions

  const handleClick = (event, type) => {
    setIcon(type);
    if (type == "edit") {
      setisDisabled(!isDisabled);
    }

    setAnchorEl(event.currentTarget);
  };

  const onDownload = () => {
    generateWorkOrderPDF({
      variables: {
        id: workOrderData?.id,
        token,
      },
    });
  };
  console.log(data, "updatedWorkOrder");
  /* istanbul ignore next */
  const [editWorkOrder, { load }] = useMutation(UPDATE_WORK_ORDER, {
    onCompleted: (data) => {
      toast.success("Work Order updated succesfully!");

      refetch();
    },
    onError: (error) => {
      toast.error("Unable to update Work Order!");
    },
  });

  /* istanbul ignore next */
  const onValidDisputeClick = (field, internalId) => {
    if (field !== "lineItems") {
      const { disputed, ...workOrderWithoutDisputed } = data?.workOrder;
      const updatedDisputed = disputed?.filter((f) => field !== f?.field);
      const updatedWorkOrder = {
        ...workOrderWithoutDisputed,
        disputed: updatedDisputed,
      };
      /* istanbul ignore next */
      editWorkOrder({
        variables: {
          token: token,
          id: updatedWorkOrder?.id,
          serviceType: updatedWorkOrder?.serviceType,
          serviceDescription: updatedWorkOrder?.serviceDescription,
          keyOnMeter: parseInt(updatedWorkOrder?.keyOnMeter),
          driveMeter: parseInt(updatedWorkOrder?.driveMeter),
          pumpMeter: parseInt(updatedWorkOrder?.pumpMeter),
          downtime: parseFloat(updatedWorkOrder?.downtime),
          invoiceType: updatedWorkOrder?.invoiceType,
          startedAt: updatedWorkOrder?.startedAt,
          stoppedAt: updatedWorkOrder?.stoppedAt,
          countAsPm: updatedWorkOrder?.countAsPm,
          countAsMfc: updatedWorkOrder?.countAsMfc,
          aasmState: updatedWorkOrder?.aasmState,
          invoicedAt: updatedWorkOrder?.invoicedAt,
          disputed: updatedWorkOrder?.disputed?.map(
            ({ field, internalId, lineItemField, correctValue, reason }) => ({
              field,
              internalId,
              lineItemField,
              correctValue,
              reason,
            }),
          ),
        },
      });
    } else if (field === "lineItems") {
      const { disputed, ...workOrderWithoutDisputed } = data?.workOrder;
      const updatedDisputed = disputed?.filter(
        (f) => internalId !== f?.internalId,
      );
      const updatedWorkOrder = {
        ...workOrderWithoutDisputed,
        disputed: updatedDisputed,
      };
/* istanbul ignore next */
      editWorkOrder({
        variables: {
          token: token,
          id: updatedWorkOrder?.id,
          serviceType: updatedWorkOrder?.serviceType,
          serviceDescription: updatedWorkOrder?.serviceDescription,
          keyOnMeter: parseInt(updatedWorkOrder?.keyOnMeter),
          driveMeter: parseInt(updatedWorkOrder?.driveMeter),
          pumpMeter: parseInt(updatedWorkOrder?.pumpMeter),
          downtime: parseFloat(updatedWorkOrder?.downtime),
          invoiceType: updatedWorkOrder?.invoiceType,
          startedAt: updatedWorkOrder?.startedAt,
          stoppedAt: updatedWorkOrder?.stoppedAt,
          countAsPm: updatedWorkOrder?.countAsPm,
          countAsMfc: updatedWorkOrder?.countAsMfc,
          aasmState: updatedWorkOrder?.aasmState,
          invoicedAt: updatedWorkOrder?.invoicedAt,
          disputed: updatedWorkOrder?.disputed?.map(
            ({ field, internalId, lineItemField, correctValue, reason }) => ({
              field,
              internalId,
              lineItemField,
              correctValue,
              reason,
            }),
          ),
        },
      });
    }
  };

  const [updateLineItem, { lineItemLoading }] = useMutation(UPDATE_LINE_ITEM, {
    onCompleted: (data) => {
      toast.success("Line Item updated succesfully!");
      refetch();
    },
    onError: (error) => {
      toast.error("Unable to update Line Item!");
    },
  });
/* istanbul ignore next */
  const onFixDisputeClick = (
    field,
    internalId,
    lineItemField,
    correctValue,
  ) => {
    if (
      field !== "lineItems" &&
      field !== "countAsPm" &&
      field !== "countAsMfc"
    ) {
      const disputedField = data?.workOrder?.disputed.find(
        (dispute) => dispute.field === field,
      );
      if (disputedField) {
        const correctValue = disputedField?.correctValue;
        const updatedWorkOrder = {
          ...data,
          workOrder: {
            ...data?.workOrder,
            [field]: correctValue,
          },
        };
/* istanbul ignore next */
        editWorkOrder({
          variables: {
            token: token,
            id: updatedWorkOrder?.workOrder?.id,
            serviceType: updatedWorkOrder?.workOrder?.serviceType,
            serviceDescription: updatedWorkOrder?.workOrder?.serviceDescription,
            keyOnMeter: parseInt(updatedWorkOrder?.workOrder?.keyOnMeter),
            driveMeter: parseInt(updatedWorkOrder?.workOrder?.driveMeter),
            pumpMeter: parseInt(updatedWorkOrder?.workOrder?.pumpMeter),
            downtime: parseFloat(updatedWorkOrder?.workOrder?.downtime),
            invoiceType: updatedWorkOrder?.workOrder?.invoiceType,
            startedAt: updatedWorkOrder?.workOrder?.startedAt,
            stoppedAt: updatedWorkOrder?.workOrder?.stoppedAt,
            countAsPm: updatedWorkOrder?.workOrder?.countAsPm,
            countAsMfc: updatedWorkOrder?.workOrder?.countAsMfc,
            aasmState: updatedWorkOrder?.workOrder?.aasmState,
            invoicedAt: updatedWorkOrder?.workOrder?.invoicedAt,
            disputed: updatedWorkOrder?.workOrder?.disputed?.map(
              ({ field, internalId, lineItemField, correctValue, reason }) => ({
                field,
                internalId,
                lineItemField,
                correctValue,
                reason,
              }),
            ),
          },
        });
      }
    } else if (field === "countAsPm" || field === "countAsMfc") {
      const disputedField = data?.workOrder?.disputed.find(
        (dispute) => dispute.field === field,
      );
      if (disputedField) {
        const correctValue = disputedField?.correctValue;
        const updatedWorkOrder = {
          ...data,
          workOrder: {
            ...data?.workOrder,
            [field]: Boolean(correctValue),
          },
        };
/* istanbul ignore next */
        editWorkOrder({
          variables: {
            token: token,
            id: updatedWorkOrder?.workOrder?.id,
            serviceType: updatedWorkOrder?.workOrder?.serviceType,
            serviceDescription: updatedWorkOrder?.workOrder?.serviceDescription,
            keyOnMeter: parseInt(updatedWorkOrder?.workOrder?.keyOnMeter),
            driveMeter: parseInt(updatedWorkOrder?.workOrder?.driveMeter),
            pumpMeter: parseInt(updatedWorkOrder?.workOrder?.pumpMeter),
            downtime: parseFloat(updatedWorkOrder?.workOrder?.downtime),
            invoiceType: updatedWorkOrder?.workOrder?.invoiceType,
            startedAt: updatedWorkOrder?.workOrder?.startedAt,
            stoppedAt: updatedWorkOrder?.workOrder?.stoppedAt,
            countAsPm: updatedWorkOrder?.workOrder?.countAsPm,
            countAsMfc: updatedWorkOrder?.workOrder?.countAsMfc,
            aasmState: updatedWorkOrder?.workOrder?.aasmState,
            invoicedAt: updatedWorkOrder?.workOrder?.invoicedAt,
            disputed: updatedWorkOrder?.workOrder?.disputed?.map(
              ({ field, internalId, lineItemField, correctValue, reason }) => ({
                field,
                internalId,
                lineItemField,
                correctValue,
                reason,
              }),
            ),
          },
        });
      }
    } else if (field === "lineItems") {
      const workOrder = data.workOrder;
      // Find the matching LineItem object in the lineItems array and update its tax field
      /* istanbul ignore next */
      const updatedLineItems = workOrder.disputed.forEach((dispute) => {
        if (
          dispute.internalId === internalId &&
          dispute.lineItemField === lineItemField
        ) {
          const lineItemToUpdate = workOrder.lineItems.find(
            (lineItem) => lineItem.internalId === internalId,
          );

          console.log(lineItemToUpdate, "lineItemToUpdate");

          if (lineItemToUpdate) {
            const updatedValue = parseFloat(dispute.correctValue);

            const updatedLineItem = {
              ...lineItemToUpdate,
              [lineItemField]: updatedValue,
            };
/* istanbul ignore next */
            updateLineItem({
              variables: {
                token: token,
                id: updatedLineItem?.id,
                productGroup: updatedLineItem?.productGroup,
                description: updatedLineItem?.description,
                quantity: parseFloat(updatedLineItem?.quantity),
                unitPrice: parseFloat(updatedLineItem?.unitPrice),
                tax: parseFloat(updatedLineItem?.tax),
                amount:
                  updatedLineItem?.productGroup === "TAXES"
                    ? 0
                    : parseFloat(updatedLineItem?.amount),
                workSystemId: parseInt(updatedLineItem?.workSystem?.id),
                workCategoryId: parseInt(updatedLineItem?.workCategory?.id),
                internalId: updatedLineItem?.internalId,
              },
            });
          }
        }
      });
    }
  };
/* istanbul ignore next */
  const isFixed = (field, internalId, lineItemField, correctValue) => {
    console.log(field, "field");
    if (
      field !== "lineItems" &&
      field !== "countAsPm" &&
      field !== "countAsMfc"
    ) {
      if (data?.workOrder?.disputed && data?.workOrder?.disputed?.length > 0) {
        for (let i = 0; i < data?.workOrder?.disputed.length; i++) {
          const dispute = data?.workOrder.disputed[i];
          if (
            dispute.field === field &&
            dispute.correctValue === data?.workOrder[field].toString()
          ) {
            return "Fixed";
          }
        }
      }
      if (data?.workOrder[field] === correctValue) {
        return "Fixed";
      }
      return "Not Fixed";
    } else if (field === "countAsPm" || field === "countAsMfc") {
      if (data?.workOrder?.disputed && data?.workOrder?.disputed?.length > 0) {
        for (let i = 0; i < data?.workOrder?.disputed.length; i++) {
          const dispute = data?.workOrder.disputed[i];
          if (
            dispute.field === field &&
            Boolean(dispute.correctValue) === data?.workOrder[field]
          ) {
            return "Fixed";
          }
        }
      }
      if (data?.workOrder[field] === Boolean(correctValue)) {
        return "Fixed";
      }
      return "Not Fixed";
    } else if (field === "lineItems") {
      if (
        data?.workOrder?.lineItems &&
        data?.workOrder?.lineItems?.length > 0
      ) {
        for (let i = 0; i < data?.workOrder.lineItems.length; i++) {
          const lineItem = data?.workOrder.lineItems[i];
          if (
            lineItem.internalId === internalId &&
            lineItem[lineItemField] &&
            lineItem[lineItemField].toString() === correctValue.toString()
          ) {
            return "Fixed";
          }
        }
      }
      return "Not Fixed";
    }
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          borderRadius: 1,
          alignItems: "center",
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <PageTitle
            breadcrumb={true}
            title={"Invoices"}
            subTitle={workOrderData?.internalId}
            location={"/app/invoices"}
          />
        </Box>
        <Box className={classNames(classes.buttonContainer)}>
          {authRole != "customer" && (
            <>
              {isDisabled && (
                <Button
                  onClick={(e) => handleClick(e, "edit")}
                  className={classNames(classes.button)}
                >
                  <img src={Edit}></img> &nbsp;&nbsp;Edit
                </Button>
              )}
            </>
          )}

          {!isDisabled && (
            <Button
              form="hook-form"
              className={classNames(classes.button)}
              onClick={() => setOpenModal(true)}
            >
              <img src={Save}></img> &nbsp;&nbsp;Save
            </Button>
          )}
          {/* This needs to be implemented or deleted if we are going to use the download buttong */}
          {/* <Button
            onClick={(e) => handleClick(e, "print")}
            className={classNames(classes.button)}
          >
            <img src={Print}></img> &nbsp;&nbsp;Print
          </Button> */}
          {/* <Button
            onClick={(e) => onDownload()}
            className={classNames(classes.button)}
          >
            <img src={Download}></img> &nbsp;&nbsp;Download
          </Button> */}

          {data?.workOrder?.logs?.length > 0 && (
            <Button
              onClick={(e) => handleHistoryOpen()}
              className={classNames(classes.button)}
            >
              <img src={History}></img> &nbsp;&nbsp;History
            </Button>
          )}

          <Button
            onClick={() => setShow(true)}
            className={classNames(classes.raiseDisputeButton)}
          >
            <img src={""}></img> &nbsp;&nbsp;Raise Dispute
          </Button>
          {data?.workOrder?.disputed?.length > 0 && (
            <Button
              onClick={() => setshowViewDispute(true)}
              className={classNames(classes.raiseDisputeButton)}
            >
              <img src={""}></img> &nbsp;&nbsp;View Disputes
            </Button>
          )}
        </Box>
      </Box>
      <AddRaiseDisputemodel
        show={show}
        onHide={() => setShow(false)}
        formData={data?.workOrder}
        refetch={refetch}
      />

      <ModalHistory
        open={historyopen}
        setOpenModal={setHistoryOpen}
        handleHistoryClose={handleHistoryClose}
        handleHistoryOpen={handleHistoryOpen}
        logs={data?.workOrder?.logs}
      />
      <ViewRaiseDisputemodel
        show={showViewDispute}
        onHide={() => setshowViewDispute(false)}
        data={data}
        onValidDisputeClick={onValidDisputeClick}
        onFixDisputeClick={onFixDisputeClick}
        isFixed={isFixed}
      />
      <Box className={classNames(classes.editInvoiceModalContainer)}>
        {!data ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Loader />
          </Box>
        ) : (
          <>
            <EditInvoiceForm
              openModal={openModal}
              setOpenModal={setOpenModal}
              isDisabled={isDisabled}
              setisDisabled={setisDisabled}
              data={data}
              refetchWorkOrder={refetch}
              openDeleteModal={openDeleteModal}
              setOpenDeleteModal={setOpenDeleteModal}
              onValidDisputeClick={onValidDisputeClick}
            />

            {((data && data?.workOrder?.invoiceType != "Customer") ||
              authRole === "admin") && (
                <VendorNotes
                  workOrderData={workOrderData}
                  token={token}
                  refetchWorkOrder={refetch}
                />
              )}
          </>
        )}
      </Box>
    </div>
  );
}
