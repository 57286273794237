import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "react-select";
import classNames from "classnames";
import useStyles from "./styles";
import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  FETCH_ALL_WORK_CATEGORIES,
  FETCH_ALL_WORK_SYSTEMS,
} from "../../../GraphQL/Queries";
import { CREATE_LINE_ITEM, SPLIT_LINE_ITEM } from "../../../GraphQL/Mutations";
import { toast } from "react-toastify";

export default function SplitLineItemsModal({
  show,
  onHide,
  workSystems,
  workCategories,
  workOrderId,
  token,
  refetchWorkOrder,
  lineItem,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState({});

  const [total, setTotal] = useState(0);
  const [attachment, setAttachment] = useState(0);
  const [damage, setDamage] = useState(0);
  const [PM, setPM] = useState(0);
  const [repair, setRepair] = useState(0);
  const [request, setRequest] = useState(0);
  const [errorsValue, setErrorsValue] = useState(false);
  const [tires, setTires] = useState(0);
  const [initialQuanitity, setInitialQuantity] = useState(null);

  console.log(lineItem, "worekknasksn");

  /* istanbul ignore next */
  const [splitLineItem, { loading }] = useMutation(SPLIT_LINE_ITEM, {
    onCompleted: (data) => {
      toast.success("Line Item split updated succesfully!");
      refetchWorkOrder();
      onHide();
    },
    onError: (error) => {
      toast.error("Unable to split Line Item!");
    },
  });

  /* istanbul ignore next */
  const handleCancel = () => {
    setFormData({});
    setErrorsValue(false);
    onHide();
  };

  /* istanbul ignore next */

  const onLineItemSubmit = () => {
    if (
      isNaN(attachment) ||
      isNaN(PM) ||
      isNaN(repair) ||
      isNaN(request || isNaN(tires))
    ) {
      setErrorsValue(true);
    } else {
      if (total <= initialQuanitity) {
        splitLineItem({
          variables: {
            token,
            id: lineItem?.id,
            attachment,
            damage,
            pm: PM,
            repair,
            request,
            tires,
          },
        });
      }
      onHide();
      setPM(0);
      setRepair(0);
      setTires(0);
      setRequest(0);
      setAttachment(0);
      setRequest(0);
      setErrorsValue(false);
    }
  };

  console.log(attachment, request, repair, PM, attachment, "froamnnac");

  React.useEffect(() => {
    setInitialQuantity(lineItem?.quantity);
  }, [lineItem?.quantity]);

  /* istanbul ignore next */
  const handleFieldChange = (field, value) => {
    switch (field) {
      case "Attachment":
        setAttachment(parseFloat(value));
        break;
      case "Damage":
        setDamage(parseFloat(value));
        break;
      case "PM":
        setPM(parseFloat(value));
        break;
      case "Repair":
        setRepair(parseFloat(value));
        break;
      case "Request":
        setRequest(parseFloat(value));
        break;
      case "Tires":
        setTires(parseFloat(value));
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    /* istanbul ignore next */
    setTotal(
      (attachment ? attachment : 0) +
      (damage ? damage : 0) +
      (repair ? repair : 0) +
      (request ? request : 0) +
      (tires ? tires : 0) +
      (PM ? PM : 0),
    );
  }, [attachment, damage, repair, request, tires, PM]);

  /* istanbul ignore next */
  return (
    <div>
      <Dialog
        open={show}
        onClose={onHide}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">SPLIT LINE ITEMS</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="row modal_input">
              <div className={classNames(classes.InputLabel)}>Attachment</div>

              <div className="col-8">
                <input
                  onChange={(e) =>
                    handleFieldChange("Attachment", e.target.value)
                  }
                  value={attachment}
                  className={classNames(classes.Input)}
                  type="number"
                // maxLength={7}
                // onBlur={handleMarginVal}
                />
                {isNaN(attachment) && errorsValue && (
                  <span style={{ color: "red" }}>
                    {" "}
                    This field cannot be Empty
                  </span>
                )}
              </div>
            </div>
            <div className="row modal_input">
              <div className={classNames(classes.InputLabel)}>Damage</div>

              <div className="col-8">
                <input
                  onChange={(e) => handleFieldChange("Damage", e.target.value)}
                  value={damage}
                  className={classNames(classes.Input)}
                  type="number"
                // maxLength={7}
                // onBlur={handleMarginVal}
                />
                {isNaN(damage) && errorsValue && (
                  <span style={{ color: "red" }}>
                    {" "}
                    This field cannot be Empty
                  </span>
                )}
              </div>
            </div>
            <div className="row modal_input">
              <div className={classNames(classes.InputLabel)}>PM</div>

              <div className="col-8">
                <input
                  onChange={(e) => handleFieldChange("PM", e.target.value)}
                  value={PM}
                  className={classNames(classes.Input)}
                  type="number"
                // maxLength={7}
                // onBlur={handleMarginVal}
                />
                {isNaN(PM) && errorsValue && (
                  <span style={{ color: "red" }}>
                    {" "}
                    This field cannot be Empty
                  </span>
                )}
              </div>
            </div>
            <div className="row modal_input">
              <div className={classNames(classes.InputLabel)}>Repair</div>

              <div className="col-8">
                <input
                  onChange={(e) => handleFieldChange("Repair", e.target.value)}
                  value={repair}
                  className={classNames(classes.Input)}
                  type="number"
                // maxLength={7}
                // onBlur={handleMarginVal}
                />
                {isNaN(repair) && errorsValue && (
                  <span style={{ color: "red" }}>
                    {" "}
                    This field cannot be Empty
                  </span>
                )}
              </div>
            </div>
            <div className="row modal_input">
              <div className={classNames(classes.InputLabel)}>Request</div>

              <div className="col-8">
                <input
                  onChange={(e) => handleFieldChange("Request", e.target.value)}
                  value={request}
                  className={classNames(classes.Input)}
                  type="number"
                // maxLength={7}
                // onBlur={handleMarginVal}
                />
                {isNaN(request) && errorsValue && (
                  <span style={{ color: "red" }}>
                    This field cannot be Empty
                  </span>
                )}
              </div>
            </div>
            <div className="row modal_input">
              <div className={classNames(classes.InputLabel)}>Tires</div>

              <div className="col-8">
                <input
                  onChange={(e) => handleFieldChange("Tires", e.target.value)}
                  value={tires}
                  className={classNames(classes.Input)}
                  type="number"
                // maxLength={7}
                // onBlur={handleMarginVal}
                />
                {isNaN(tires) && errorsValue && (
                  <span style={{ color: "red" }}>
                    {" "}
                    This field cannot be Empty
                  </span>
                )}
              </div>
            </div>
            <div className="row modal_input">
              <div className={classNames(classes.totalText)}>
                Available Quantity:{" "}
                {lineItem?.quantity -
                  (attachment ? attachment : 0) -
                  (damage ? damage : 0) -
                  (PM ? PM : 0) -
                  (request ? request : 0) -
                  (repair ? repair : 0) -
                  (tires ? tires : 0)}
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classNames(classes.cancelButton)}
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
          <Button
            className={classNames(classes.SaveButton)}
            onClick={() => onLineItemSubmit()}
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
