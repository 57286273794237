import * as React from "react";
import TableHistory from "./TableHistory";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import classNames from "classnames";

//Styles
import useStyles from "./styles";

export default function ModalHistory({
  open,
  setOpenModal,
  handleHistoryOpen,
  handleHistoryClose,
  logs,
}) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        open={open}
        onClose={handleHistoryClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classNames(classes.style)}>
          <span variant="h6" className={classes.heading}>
            {/*style {heading} to be checked  */}
            History
          </span>
          <br />
          <TableHistory logs={logs} />
        </Box>
      </Modal>
    </div>
  );
}
