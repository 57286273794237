import { combineReducers } from "redux";
import authReducer from "./authReducer";
import customerReducer from "./customerReducer";
import invoicesReducer from "./invoicesReducer";
import loaderReducer from "./loaderReducer";

const reducers = combineReducers({
  auth: authReducer,
  invoices: invoicesReducer,
  customers: customerReducer,
  loaderStatus: loaderReducer,
});

export default reducers;
