import { ActionTypes } from "../constants/actionTypes";

export const getAllCustomers = (customers) => async (dispatch) => {
   /* istanbul ignore next */
  try {
    dispatch({
      type: ActionTypes.GET_ALL_CUSTOMERS,
      payload: customers,
    });
  } catch (e) {
    console.log(e);
  }
};


export const getAllVendors = (vendors) => async (dispatch) => {
   /* istanbul ignore next */
  try {
    dispatch({
      type: ActionTypes.GET_ALL_VENDORS,
      payload: vendors,
    });
  } catch (e) {
    console.log(e);
  }
};
