import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

// components
import Layout from "./Layout";

// pages
import Error from "../pages/error";
import Login from "../pages/login";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
  ApolloLink,
} from "@apollo/client";

import { setContext } from "@apollo/client/link/context";

import { onError } from "@apollo/client/link/error";
import SERVER_BASE_URL from "../config/config";

// context
import { useUserState } from "../context/UserContext";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { loaderAction } from "../redux/actions/loaderAction";
import TokenLogin from "../pages/login/TokenLogin";

//################ Routing Logic based on Roles happens inside App() in nested ()s #######################################################################

export default function App() {
  const dispatch = useDispatch();

  console.log = () => {};
  console.error = () => {};
  console.warn = () => {};

  /* istanbul ignore next */

  const authLink = new ApolloLink((operation, forward) => {
    // Set the loading state to true when a request is sent
    dispatch(loaderAction(true));
    return forward(operation).map((response) => {
      // Reset the loading state to false when a response is received
      dispatch(loaderAction(false));
      return response;
    });
  });

  const errorLink = onError(({ graphqlErrors, networkError }) => {
    if (graphqlErrors) {
      graphqlErrors.map(({ message, location, path }) => {
        alert(`Graphql Error ${message}`);
      });
    }
  });

  const link = from([
    errorLink,
    authLink.concat(
      new HttpLink({
        uri: SERVER_BASE_URL,
      }),
    ),
  ]);

  // global
  const { isAuthenticated } = useUserState();
  const token = useSelector((state) => state?.auth?.authUserData?.token);
  return (
    <ApolloProvider
      client={
        new ApolloClient({
          link: link,
          cache: new InMemoryCache(),
        })
      }
    >
      <HashRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={() =>
              token ? <Redirect to="/app/invoices" /> : <Redirect to="/login" />
            }
          />
          <Route
            exact
            path="/app"
            render={() => <Redirect to="/app/invoices" />}
          />
          <PrivateRoute path="/app" component={Layout} />
          <PublicRoute path="/token" component={TokenLogin} />
          <PublicRoute path="/login" component={Login} />

          <Route component={Error} />
        </Switch>
      </HashRouter>
    </ApolloProvider>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          token ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/login",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
