import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import { format } from "date-fns";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Export_Button from "../../../assets/img/icons/Export_Button.svg";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import SearchBar from "material-ui-search-bar";
import { Link, useHistory } from "react-router-dom";
import moment from "moment/moment";
import classNames from "classnames";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import useStyles from "../styles";

function createData(internalName, name, startRange, endRange, billingValue) {
  return {
    internalName,
    name,
    startRange,
    endRange,
    billingValue,
  };
}

const rows = [
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
];
/* istanbul ignore next */
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
/* istanbul ignore next */
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
/* istanbul ignore next */
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  {
    id: "internalName",
    numeric: false,
    disablePadding: true,
    label: "Internal Name",
  },
  {
    id: "startRange",
    numeric: false,
    disablePadding: false,
    label: "Start Range",
  },
  {
    id: "endRange",
    numeric: false,
    disablePadding: false,
    label: "End Range",
  },
  {
    id: "billingValue",
    numeric: true,
    disablePadding: false,
    label: "Billing Value",
  },
];
/* istanbul ignore next */
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all desserts",
                        }}
                    />
                </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function VendorContracts({ contractsData, search, perPage }) {
  const classes = useStyles();
  const history = useHistory();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(perPage);
  const [searchValue, setSearchValue] = React.useState("");

  React.useEffect(() => {
    setSearchValue(search);
  }, [search]);

  console.log(searchValue, "seasas");

  // Selectors from state
  /* istanbul ignore next */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "desc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  /* istanbul ignore next */
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  /* istanbul ignore next */
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };
  /* istanbul ignore next */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  /* istanbul ignore next */
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  /* istanbul ignore next */
  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  console.log(selected, "invoiceNo");
  console.log(
    "rowwwwwwwwwwwwwwwwwwwwwwww",
    rows.map((data) => console.log("data", data)),
  );
  /* istanbul ignore next */
  const intCusDetailNavigate = () => {
    history.push();
    // `/customer-portal/${userID ?? customerData?.id}/product/${
    //   props?.data?.partNumber
    // }?cp=true`,
    // {
    //   state: {
    //     partNumber: props?.data?.partNumber,
    //     partName:
    //       props?.data?.description ?? props?.data?.partDescription,
    //     id: props?.data?.id
    //   }
    // }
  };
  /* istanbul ignore next */
  const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
    const [isExpanded, setIsExpanded] = React.useState(false);

    return (
      <>
        <TableRow {...otherProps}>
          <TableCell padding="checkbox">
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          {children}
        </TableRow>
        {isExpanded && (
          <TableRow>
            <TableCell padding="checkbox" />
            {expandComponent}
          </TableRow>
        )}
      </>
    );
  };
  /* istanbul ignore next */
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer sx={{ padding: "32px" }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={contractsData?.length}
            />
            <TableBody>
              {console.log(contractsData, "search")}
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}

              {contractsData &&
                contractsData &&
                searchValue &&
                contractsData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .filter((searchData) =>
                    searchData?.internalName
                      ?.toLowerCase()
                      ?.includes(searchValue.toLowerCase()),
                  )
                  .map((row) => (
                    <ExpandableTableRow
                      key={row.internalName}
                      expandComponent={row?.customers?.map((d) => (
                        <div className={classNames(classes.expantableContent)}>
                          <span>
                            {d?.parentRelationships[0]?.customerInternalVal
                              ? d?.parentRelationships[0]?.customerInternalVal +
                                "- "
                              : ""}
                          </span>
                          <span>
                            Internal Name :{" "}
                            <span
                              className={classNames(classes.headingSubContent)}
                            >
                              {" "}
                              {d?.internalName ?? "N/A"}{" "}
                            </span>
                          </span>
                          <span>
                            Name :{" "}
                            <span
                              className={classNames(classes.headingSubContent)}
                            >
                              {d?.name ?? "N/A"}
                            </span>
                          </span>
                        </div>
                      ))}
                    >
                      <TableCell>{row?.internalName}</TableCell>
                      <TableCell>
                        {format(new Date(row?.startRange), "MM-dd-yyyy")}
                      </TableCell>
                      <TableCell>
                        {format(new Date(row?.endRange), "MM-dd-yyyy")}
                      </TableCell>
                      <TableCell>{row?.billingValue}</TableCell>
                    </ExpandableTableRow>
                  ))}
              {contractsData &&
                !searchValue &&
                contractsData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

                  .map((row) => (
                    <ExpandableTableRow
                      key={row.internalName}
                      expandComponent={row?.customers?.map((d) => (
                        <div className={classNames(classes.expantableContent)}>
                          <span>
                            {d?.parentRelationships[0]?.customerInternalVal
                              ? d?.parentRelationships[0]?.customerInternalVal +
                                "- "
                              : ""}
                          </span>
                          <span>
                            Internal Name :{" "}
                            <span
                              className={classNames(classes.headingSubContent)}
                            >
                              {" "}
                              {d?.internalName ?? "N/A"}{" "}
                            </span>
                          </span>
                          <span>
                            Name :{" "}
                            <span
                              className={classNames(classes.headingSubContent)}
                            >
                              {d?.name ?? "N/A"}
                            </span>
                          </span>
                        </div>
                      ))}
                    >
                      <TableCell>{row?.internalName}</TableCell>
                      <TableCell>
                        {format(new Date(row?.startRange), "MM-dd-yyyy")}
                      </TableCell>
                      <TableCell>
                        {format(new Date(row?.endRange), "MM-dd-yyyy")}
                      </TableCell>
                      <TableCell>{row?.billingValue}</TableCell>
                    </ExpandableTableRow>
                  ))}
            </TableBody>
          </Table>
          {/* <div className={classNames(classes.tableCell0)}>
            <div className={classNames(classes.tableCell20)}>
              Total
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              232,945.42
            </div>
          </div> */}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 35, 50]}
          component="div"
          count={contractsData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
