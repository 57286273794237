import { ActionTypes } from "../constants/actionTypes";

const initialState = {};

const invoicesReducer = (state = initialState, { type, payload } = []) => {
  switch (type) {
    /* istanbul ignore next */
    case ActionTypes.INVOICE_ID:
      return { ...state, invoiceId: payload };
    case ActionTypes.GET_INVOICES_DATA:
      return { ...state, getAllInvoicesData: { ...payload } };
    case ActionTypes.GET_INVOICE_DATA:
      return { ...state, getInvoiceData: { ...payload } };
    case ActionTypes.GET_VENDOR_DATA:
      return { ...state, getVendorInvoiceData: { ...payload } };
    case ActionTypes.GET_VENDOR_WORK_ORDER_DATA:
      return { ...state, getVendorWorkOrderData: { ...payload } };
    case ActionTypes.GET_DOCUMENTS_DATA:
      return { ...state, getAllDocumentsData: { ...payload } };
    case ActionTypes.GET_CONTRACTS_DATA:
      return { ...state, getAllContractsData: { ...payload } };
    case ActionTypes.GET_INVOICE_WORKORDERS_DATA:
      return { ...state, getAllInvoiceWorkOrdersData: { ...payload } };
    case ActionTypes.GET_INVOICE_MAINTENANCERECORDS:
      return { ...state, getAllInvoiceMaintenanceRecordsData: { ...payload } };
    case ActionTypes.GET_CUSTOMER_WORKORDERS:
      return { ...state, getCustomerWorkOrders: { ...payload } };
    case ActionTypes.GET_CUSTOMER_MAINTENANCERECORDS:
      return { ...state, getCustomerMaintenanceRecords: { ...payload } };
    case ActionTypes.GET_USER_VENDOR_DETAIL:
      return { ...state, getUserVendorDetail: { ...payload } };
    default:
      return state;
  }
};

export default invoicesReducer;
