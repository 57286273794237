import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { Box } from "@material-ui/core";
import classNames from "classnames";

//style
import useStyles from "../styles";
import { FETCH_ALL_MAINTANANCE_RECORDS } from "../../../GraphQL/Queries";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { getMaintenanceRecordsData } from "../../../redux/actions/invoiceActions";

export default function Contracts({ invoiceId }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [maintenanceRecordsTotal, setMaintenanceRecordsTotal] =
    React.useState();

  const token = useSelector((state) => state?.auth?.authUserData?.token);
  const invoiceMaintenanceRecordsData = useSelector(
    (state) =>
      state?.invoices?.getAllInvoiceMaintenanceRecordsData
        ?.allMaintenanceRecordsByInvoiceId,
  );

  const {
    error: maintenanceRecordsError,
    loading: maintenanceRecordsLoading,
    data: invoicemaintenanceRecords,
  } = useQuery(FETCH_ALL_MAINTANANCE_RECORDS, {
    variables: { token, id: invoiceId },
  });

  React.useEffect(() => {
    dispatch(getMaintenanceRecordsData(invoicemaintenanceRecords));
  }, [invoicemaintenanceRecords]);
  /* istanbul ignore next */
  return (
    <TableContainer className={classNames(classes.tableContainer)}>
      {invoiceMaintenanceRecordsData &&
        invoiceMaintenanceRecordsData?.length ? (
        <>
          <Table
            className={classNames(classes.table)}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  className={classNames(classes.tableCell2)}
                >
                  Internal Value
                </TableCell>
                <TableCell
                  align="left"
                  className={classNames(classes.tableCell3)}
                >
                  Start Range
                </TableCell>
                <TableCell
                  align="left"
                  className={classNames(classes.tableCell4)}
                >
                  End Range
                </TableCell>
                <TableCell
                  align="left"
                  className={classNames(classes.tableCell1)}
                >
                  Invoiced At
                </TableCell>
                <TableCell
                  align="left"
                  className={classNames(classes.tableCell2)}
                >
                  Sub Total
                </TableCell>
                <TableCell
                  align="left"
                  className={classNames(classes.tableCell2)}
                >
                  Total
                </TableCell>
                <TableCell
                  align="left"
                  className={classNames(classes.tableCell2)}
                >
                  Created At
                </TableCell>
                <TableCell
                  align="left"
                  className={classNames(classes.tableCell3)}
                >
                  Updated At
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceMaintenanceRecordsData &&
                invoiceMaintenanceRecordsData.map((row) => (
                  <TableRow
                    key={row.Date}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="left"
                      className={classNames(classes.tableCell6)}
                    >
                      {row.internalVal}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classNames(classes.tableCell6)}
                    >
                      {row?.startRange?.substring(0, 10)}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classNames(classes.tableCell6)}
                    >
                      {row?.endRange?.substring(0, 10)}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      className={classNames(classes.tableCell5)}
                    >
                      {row?.invoicedAt?.substring(0, 10)}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classNames(classes.tableCell6)}
                    >
                      {row.subtotal}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classNames(classes.tableCell6)}
                    >
                      {row.subtotal + row.tax}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classNames(classes.tableCell6)}
                    >
                      {row?.createdAt?.substring(0, 10)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <div className={classNames(classes.tableCell0)}>
            <div className={classNames(classes.tableCell20)}>
              Total
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              ${" "}
              {invoiceMaintenanceRecordsData &&
                invoiceMaintenanceRecordsData.reduce(
                  (accumulator, current) =>
                    accumulator + parseInt(current?.subtotal),
                  0,
                )}
            </div>
          </div>
        </>
      ) : (
        "No Maintenance Contracts found for this invoice!"
      )}
    </TableContainer>
  );
}
