import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Popover from "@mui/material/Popover";
import { toast } from "react-toastify";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import { format } from "date-fns";

import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";

import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import SearchBar from "material-ui-search-bar";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import classNames from "classnames";

// import {
//   FETCH_ALL_DOCUMENTS,
//   FETCH_DOCUMENT,
// } from "../../../../GraphQL/Queries";
import useStyles from "../styles";
import AddDocumentForm from "../components/forms/AddDocumentForm";
import { UPDATE_DOCUMENT } from "../../../GraphQL/Mutations";
import { useMutation, useQuery } from "@apollo/client";

function createData(name, userType, createdAt, archivedAt) {
  return {
    name,
    userType,
    createdAt,
    archivedAt,
  };
}

const rows = [
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
];
/* istanbul ignore next */
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
/* istanbul ignore next */
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
/* istanbul ignore next */
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "userType",
    numeric: false,
    disablePadding: false,
    label: "User Type",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Created At",
  },
  {
    id: "archivedAt",
    numeric: false,
    disablePadding: false,
    label: "Archived At",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];
/* istanbul ignore next */
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all desserts",
                        }}
                    />
                </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            // padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function DocumentsTable({
  documentsData,
  search,
  perPage,
  refetchDocument,
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [checked, setChecked] = React.useState([]);

  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(perPage);
  const [searchValue, setSearchValue] = React.useState("");
  const [documentEdit, setDocumentEdit] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = useSelector((state) => state?.auth?.authUserData?.token);
  /* istanbul ignore next */
  const handleEditClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  /* istanbul ignore next */
  const handleEditClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  React.useEffect(() => {
    setSearchValue(search);
  }, [search]);

  console.log(documentsData, "seasas");
  // const {
  //   error: documentErrors,
  //   loading: documentLoading,
  //   data: SingleDocumentData,
  // } = useQuery(FETCH_DOCUMENT, {
  //   variables: { token, id: documentData?.id },

  //   skip: !edit,
  // });
  /* istanbul ignore next */
  const [updateDocument] = useMutation(UPDATE_DOCUMENT, {
    onCompleted: (data) => {
      // Access the response data here
      toast.success("Document updated succesfully!");

      refetchDocument();
    },
    onError: (error) => {
      toast.error("Unable to update document!");
    },
  });
  /* istanbul ignore next */
  const handlestatusChange = (event, row) => {
    if (row?.archivedAt != null && row?.archivedAt != "") {
      updateDocument({
        variables: {
          token,
          id: row?.id,
          name: row?.name,

          userType: row?.userType,
          archivedAt: "",
        },
      });
    } else {
      updateDocument({
        variables: {
          token,
          id: row?.id,
          name: row?.name,
          userType: row?.userType,
          archivedAt: new Date(),
        },
      });
    }
  };

  console.log(checked, "checked");
  // Selectors from state
  /* istanbul ignore next */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "desc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  //selct all rows from table
  /* istanbul ignore next */
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  /* istanbul ignore next */
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  //page change
  /* istanbul ignore next */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  //Row change
  /* istanbul ignore next */
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  /* istanbul ignore next */
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  /* istanbul ignore next */
  const onNameClick = (event, data) => {
    setDocumentEdit(data);
    setAnchorEl(event.currentTarget);
  };
/* istanbul ignore next */

  return (
    <Box sx={{ width: "100%" }}>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleEditClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 150, left: 850 }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <AddDocumentForm
          closeModal={handleEditClose}
          refetchDocument={refetchDocument}
          documentData={documentEdit}
          edit={true}
        />
      </Popover>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer sx={{ padding: "32px" }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {console.log(search, "search")}
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
              {documentsData &&
                searchValue &&
                documentsData

                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .filter((searchData) =>
                    searchData?.name
                      ?.toLowerCase()
                      ?.includes(searchValue.toLowerCase()),
                  )
                  .map((row, index) => {
                    const isItemSelected = isSelected(row);
                    const labelId = `enhanced-table-checkbox-${row}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row}
                        selected={isItemSelected}
                      >
                        <TableCell>
                          <div className={"cursor-pointer"}>{row.name}</div>
                        </TableCell>
                        <TableCell>
                          {/* <Link to={"invoicetabs/dummyTabs"}> */}
                          {row?.userType}
                          {/* </Link> */}
                        </TableCell>
                        <TableCell>
                          {row?.createdAt
                            ? row?.createdAt?.substring(0, 10)
                            : "N/A"}
                        </TableCell>
                        <TableCell>
                          {row?.archivedAt
                            ? row?.archivedAt?.substring(0, 10)
                            : "N/A"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              {documentsData &&
                !searchValue &&
                stableSort(documentsData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row);
                    const labelId = `enhanced-table-checkbox-${row}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row}
                        selected={isItemSelected}
                      >
                        <TableCell>
                          <div
                            onClick={(event) => onNameClick(event, row)}
                            className={"cursor-pointer"}
                          >
                            {row.name}
                          </div>
                        </TableCell>
                        <TableCell>
                          {/* <Link to={"invoicetabs/dummyTabs"}> */}
                          {row?.userType}
                          {/* </Link> */}
                        </TableCell>
                        <TableCell>
                          {row?.createdAt
                            ? row?.createdAt?.substring(0, 10)
                            : "N/A"}
                        </TableCell>
                        <TableCell>
                          {row?.archivedAt
                            ? row?.archived?.substring(0, 10)
                            : "N/A"}
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={!row?.archivedAt}
                            onClick={(event) => handlestatusChange(event, row)}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 35, 50]}
          component="div"
          count={documentsData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
