import { makeStyles } from "@material-ui/styles";
import { blue } from "@mui/material/colors";

export default makeStyles((theme) => ({
  Input: {
    width: "552px",
    height: "35px",
  },
  InputLabel: {
    paddingTop: "10px",
  },
  textareaInput: {
    width: "552px",
    height: "100px",
  },
  SaveButton: {
    background: "#1976d2 !important",
    color: "white !important",
    border: "1px solid rgba(25, 118, 210, 0.5) !important",
    borderRadius: "4px !important",
  },
  cancelButton: {
    border: "1px solid rgba(25, 118, 210, 0.5) !important",
    borderRadius: "4px !important",
  },

  totalText: {
    marginTop: "50px",
    fontSize: "15px",
    fontWeight: "900",
  },
}));
