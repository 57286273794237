import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({

// box and modal styling
    style: {
        position: 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        height: '238px',
        padding: '32px',
        boxShadow: 24,
        backgroundColor: 'white',
        p: 4,
    },
    style2:{
        marginTop: '-55px',
        display: 'flex',
        flexdirection: 'row',
        alignitems: 'flexstart',
        padding: '32px',
        gap: '20px',
        marginLeft: '19px',
        
    },

    //icon styling
    
    closeicon: {
        float: 'right',
        marginTop: '-2px'
    },

    //font styling

    span1: {
        fontWeight: 'bold',
        marginLeft: '45px',
        fontSize: '24px',
        fontfamily: 'Ubuntu',
        fontstyle: 'normal',
        fontweight: '500',
        width: '336px',
        height: '58px',

        
        
    },

    span2: {
        fontWeight: 'bold',
        marginLeft: '85px',
        fontSize: '24px',
        fontfamily: 'Ubuntu',
        fontstyle: 'normal',
        fontweight: '500',
        width: '336px',
        height: '38px',
    },

    span3: {
        marginLeft: '37px',
        fontSize: '16px',
        fontfamily: 'Ubuntu',
        fontstyle: 'normal',
        fontweight: '500',
        
    },

    span4: {
        marginLeft: '75px',
        fontSize: '16px',
        fontfamily: 'Ubuntu',
        fontstyle: 'normal',
        fontweight: '500',
        
    },

    //button styling

    cancelbutton: {
        float: 'left',
        width: "127px",
        fontfamily: 'Ubuntu',
        fontstyle: 'normal',
        fontweight: '500',
        fontsize: '16px',
        lineheight: '18px',
        fontWeight: 'bold',
        border: '1px solid #3f51b5',
        color:'#023E8A',
    },

    savebutton: {
        float:'right',
        width: "83px",
        background: '#023E8A',
        borderradius: '3px',
    }

}));