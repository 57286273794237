import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Invoices from "../../pages/invoices/Invoices";

// context
import { useLayoutState } from "../../context/LayoutContext";
import SingleInvoice from "../../pages/workOrders/SubPages/SingleInvoice";
import InvoiceStatusTable from "../../pages/invoices/components/InvoiceStatusTable";
import { useSelector } from "react-redux";
import DocumentModal from "../DocumentModal/DocumentModal";
import { useEffect } from "react";
import { FETCH_CURRENT_USER } from "../../GraphQL/Queries";
import { useQuery } from "@apollo/client";
import InvoiceTabs from "../../pages/invoices/components/InvoiceTabs/InvoiceTabs";
import Error from "../../pages/error/Error";
import ComingSoon from "../../pages/comingSoon/ComingSoon";
import Users from "../../pages/users/users";
import WorkOrders from "../../pages/workOrders/WorkOrders";

function Layout(props) {
  const isLoading = useSelector((state) => state?.loaderStatus?.showLoading);
  console.log(isLoading, "state?.loaderStatus");
  const classes = useStyles();
  const token = useSelector((state) => state?.auth?.authUserData?.token);

  // Terms and condition Modal states and function
  const [open, setOpen] = React.useState(false);
  /* istanbul ignore next */
  const handleOpen = () => setOpen(true);
  /* istanbul ignore next */
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const {
    error: currentUserError,
    loading: currentUserLoading,
    data: user,
    refetch: reFetchCurrentUser,
  } = useQuery(FETCH_CURRENT_USER, {
    variables: {
      token: token,
    },
  });

  /* istanbul ignore next */
  useEffect(() => {
    if (user && user?.currentUser?.udaId != null) {
      handleOpen();
    }
  }, [user]);

  // global
  const layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Sidebar />
        <Header history={props.history} />
        {user?.currentUser?.udaId != null && (
          <DocumentModal
            handleOpen={handleOpen}
            handleClose={handleClose}
            setOpen={setOpen}
            open={open}
            info={user && user?.currentUser}
            reFetchCurrentUser={reFetchCurrentUser}
            token={token}
          />
        )}

        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route
              path="/app/invoices/filter/:status"
              component={InvoiceStatusTable}
            />
            <Route path="/app/invoices/:invoiceId" component={InvoiceTabs} />
            {/* <Route path="/app/dashboard" component={Dashboard} /> */}
            <Route path="/app/invoices" component={Invoices} />
            <Route
              path="/app/workorders/:invoiceNumber"
              component={SingleInvoice}
            />
            <Route path="/app/workorders" component={WorkOrders} />

            <Route path="/app/users" component={Users} />
          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
