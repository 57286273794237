import { gql } from "@apollo/client";

// export const FETCH_ALL_INVOICES = gql`
//   query Invoices($token: String!) {
//     allInvoices(token: $token) {
//       id
//       internalId
//       amount
//       tax
//       comment
//       pin
//       aasmState
//       createdAt
//       customer {
//         id
//         name
//         internalName
//         parentRelationships {
//           id
//           customerInternalVal
//         }
//       }
//     }
//   }
// `;

// istanbul ignore next

export const FETCH_ALL_INVOICES = gql`
  query Invoices(
    $token: String!
    $startDate: String
    $endDate: String
    $status: [String!]
  ) {
    allInvoices(
      token: $token
      startDate: $startDate
      endDate: $endDate
      status: $status
    ) {
      id
      internalId
      amount
      tax
      comment
      pin
      aasmState
      createdAt
      customer {
        id
        name
        internalName
        parentRelationships {
          id
          customerInternalVal
        }
      }
    }
  }
`;

export const FETCH_END_CUSTOMER_INVOICES = gql`
  query EndCustomerInvoices($ids: [Int!]!, $token: String!) {
    endCustomerInvoices(ids: $ids, token: $token) {
      id
      internalId
      amount
      tax
      comment
      pin
      invoicedAt
      archivedAt
      createdAt
      aasmState
      fleetServiceCharge
      startRange
      endRange
      customer {
        id
        name
        internalName
      }
    }
  }
`;

export const FETCH_SINGLE_WORK_ORDER = gql`
  query WorkOrder($token: String!, $id: Int!) {
    workOrder(token: $token, id: $id) {
      id
      serviceType
      lineItemTotal
      serviceDescription
      keyOnMeter
      pumpMeter
      driveMeter
      downtime
      deadmanMeter
      invoiceType
      internalId
      startedAt
      stoppedAt
      invoicedAt
      countAsPm
      countAsMfc
      po
      aasmState
      disputed {
        field
        lineItemField
        internalId
        reason
        correctValue
      }
      customer {
        id
        name
        internalName
        parentRelationships {
          id
          customerInternalVal
        }
      }
      equipment {
        id
        serialNumber
        model
        vendorEquipment {
          id
          vendorEquipmentId
        }
      }
      vendor {
        id
        name
      }
      lineItems {
        id
        productGroup
        description
        quantity
        unitPrice
        amount
        tax
        internalId
        workSystem {
          id
          value
        }
        workCategory {
          id
          value
        }
      }
      reviews {
        id
        reviewableType
        message
        createdAt
        completedAt
        user {
          id
          firstName
          lastName
        }
      }
      logs {
        oldValue
        newValue
        id
        startedAt
        endedAt
        fieldName
        fieldType
      }
      notes {
        id
        user {
          id
          firstName
          lastName
        }
        content
        createdAt
      }
    }
  }
`;

export const FETCH_WORK_ORDER = gql`
  query WorkOrder($token: String!, $id: Int!) {
    allWorkOrdersByInvoiceId(token: $token, id: $id) {
      id
      lineItemTotal
      serviceType
      serviceDescription
      keyOnMeter
      pumpMeter
      driveMeter
      deadmanMeter
      downtime
      invoiceType
      internalId
      startedAt
      stoppedAt
      invoicedAt
      countAsPm
      countAsMfc
      disputed {
        field
        internalId
        lineItemField
        correctValue
        reason
      }
      po
      aasmState
      customer {
        id
        name
        internalName
        parentRelationships {
          id
          customerInternalVal
        }
      }
      equipment {
        id
        serialNumber
        model
        vendorEquipment {
          id
          vendorEquipmentId
        }
      }
      vendor {
        id
        name
      }
      lineItems {
        id
        productGroup
        description
        quantity
        unitPrice
        amount
        tax
        internalId
        workSystem {
          id
          value
        }
        workCategory {
          id
          value
        }
      }
      reviews {
        id
        reviewableType
        message
        createdAt
        completedAt
        user {
          id
          firstName
          lastName
        }
      }
      notes {
        id
        user {
          id
          firstName
          lastName
        }
        content
        createdAt
      }
    }
  }
`;

export const FETCH_ALL_DOCUMENTS = gql`
  query Documents($token: String!) {
    allDocuments(token: $token) {
      id
      name
      userType
      createdAt
      archivedAt
    }
  }
`;

export const FETCH_DOCUMENT_BY_EMAIL = gql`
  query Document($token: String!, $id: Int!) {
    documentByEmail(token: $token, id: $id) {
      id
      name
      userType
      createdAt
      archivedAt
    }
  }
`;

export const FETCH_ALL_CONTRACTS = gql`
  query Contracts($token: String!) {
    allContracts(token: $token) {
      id
      internalName
      startRange
      endRange
      billingValue
      customers {
        id
        name
        internalName
        parentRelationships {
          id
          customerInternalVal
        }
      }
    }
  }
`;

export const FETCH_CONTRACT_BY_ID = gql`
  query Contract($token: String!, $id: Int!) {
    contract(token: $token, id: $id) {
      id
      internalName
      startRange
      endRange
      billingValue
      customers {
        id
        name
        internalName
        parentRelationships {
          id
          customerInternalVal
        }
      }
    }
  }
`;

export const FETCH_ALL_MAINTANANCE_RECORDS = gql`
  query MaintenanceRecord($token: String!, $id: Int!) {
    allMaintenanceRecordsByInvoiceId(token: $token, id: $id) {
      equipment {
        id
        serialNumber
        model
        vendorEquipment {
          id
          vendorEquipmentId
        }
      }
      id
      amount
      internalVal
      invoicedAt
      startRange
      endRange
      serialNumber
      subtotal
      tax
      createdAt
      updatedAt
    }
  }
`;

export const FETCH_ALL_CUSTOMERS = gql`
  query Customer($token: String!) {
    invoiceCustomers(token: $token) {
      id
      name
      address1
      address2
      city
      province
      postalCode
      country
      hpd
      dpw
      internalName
      archivedAt
    }
  }
`;

export const FETCH_ALL_VENDORS = gql`
  query Customer($token: String!) {
    vendors(token: $token) {
      id
      name
      address1
      address2
      city
      province
      postalCode
      country
      hpd
      dpw
      internalName
      archivedAt
    }
  }
`;

export const FETCH_CUSTOMER_RANGE_WORK_ORDER = gql`
  query WorkOrder(
    $token: String!
    $id: Int!
    $startRange: String!
    $endRange: String!
  ) {
    customerRangeWorkOrder(
      token: $token
      id: $id
      startRange: $startRange
      endRange: $endRange
    ) {
      id
      lineItemTotal
      serviceType
      serviceDescription
      keyOnMeter
      pumpMeter
      driveMeter
      deadmanMeter
      downtime
      invoiceType
      internalId
      startedAt
      stoppedAt
      invoicedAt
      countAsPm
      countAsMfc
      po
      aasmState
      customer {
        id
        name
        internalName
        parentRelationships {
          id
          customerInternalVal
        }
      }
      equipment {
        id
        serialNumber
        model
        vendorEquipment {
          id
          vendorEquipmentId
        }
      }
      vendor {
        id
        name
      }
      lineItems {
        id
        productGroup
        description
        quantity
        unitPrice
        amount
        tax
        internalId
        workSystem {
          id
          value
        }
        workCategory {
          id
          value
        }
      }
      reviews {
        id
        reviewableType
        message
        createdAt
        completedAt
        user {
          id
          firstName
          lastName
        }
      }
      notes {
        id
        user {
          id
          firstName
          lastName
        }
        content
        createdAt
      }
    }
  }
`;

export const FILTER_MAINTENANCE_RECORDS = gql`
  query MaintenanceRecord(
    $token: String!
    $id: Int!
    $startRange: String!
    $endRange: String!
  ) {
    filterMaintenanceRecord(
      token: $token
      id: $id
      startRange: $startRange
      endRange: $endRange
    ) {
      equipment {
        id
        serialNumber
        model
        vendorEquipment {
          id
          vendorEquipmentId
        }
      }
      id
      amount
      internalVal
      invoicedAt
      startRange
      endRange
      serialNumber
      subtotal
      tax
      createdAt
      updatedAt
    }
  }
`;

export const FETCH_CURRENT_USER = gql`
  query CurrentUser($token: String!) {
    currentUser(token: $token) {
      id
      email
      documentId
      documentContent
      documentName
      name
      role
      vendorId
      customers
      udaId
      
    }
  }
`;

export const FETCH_WORK_ORDERS_BY_STATE = gql`
  query AllWorkOrdersByState($token: String!, $state: String!) {
    allWorkOrdersByState(token: $token, state: $state) {
      id
      internalId
      vendor {
        id
        name
      }
    }
  }
`;

export const CUSTOMER_RELATIONSHIP_SEARCH = gql`
  query SearchCustomerRelationships(
    $token: String!
    $search: String!
    $vendorId: Int!
  ) {
    searchCustomerRelationships(
      token: $token
      search: $search
      vendorId: $vendorId
    ) {
      id
      customerInternalVal

      childCustomer {
        id
        name
        internalName
        contracts {
          id
          customerPo
          poRequired
        }
      }
    }
  }
`;

export const FETCH_EQUIPMENT_BY_CUSTOMER = gql`
  query Customer($token: String!, $id: Int!) {
    customer(id: $id, token: $token) {
      id
      equipment {
        id
        make
        model
        serialNumber
        fleetNumber
        vendorEquipment {
          id
          vendorEquipmentId
          customer {
            id
          }
        }
      }
    }
  }
`;

// * Query for Contract Equipment
export const FETCH_CONTRACT_DETAILS_BY_EQUIPMENT = gql`
  query EquipmentContract($token: String!, $equipmentId: Int!) {
    equipment(id: $equipmentId, token: $token) {
      id
      contracts {
        contractType
        amountType
        invoiceRate
        internalName
        billingValue
      }
    }
  }
`;

export const FETCH_ALL_WORK_CATEGORIES = gql`
  query WorkCategories($token: String!) {
    allWorkCategories(token: $token) {
      id
      value
    }
  }
`;

export const FETCH_VENDOR_WORK_ORDERS = gql`
  query WorkOrder(
    $token: String!
    $id: [Int!]!
    $startDate: String
    $query: String
    $endDate: String
    $status: [String!]
    $city: [String!]
    $invoiceType: [String!]
  ) {
    vendorWorkOrder(
      token: $token
      id: $id
      startDate: $startDate
      endDate: $endDate
      query: $query
      status: $status
      city: $city
      invoiceType: $invoiceType
    ) {
      id
      lineItemTotal
      serviceType
      serviceDescription
      keyOnMeter
      pumpMeter
      driveMeter
      downtime
      deadmanMeter
      invoiceType
      internalId
      startedAt
      stoppedAt
      invoicedAt
      countAsPm
      countAsMfc
      po
      aasmState
      invoice {
        id
        internalId
      }
      customer {
        id
        name
        internalName
        city
        parentRelationships {
          id
          customerInternalVal
        }
      }
      equipment {
        id
        serialNumber
        model
        vendorEquipment {
          id
          vendorEquipmentId
        }
      }
      vendor {
        id
        name
        city
      }
      lineItems {
        id
        productGroup
        description
        quantity
        unitPrice
        amount
        tax
        internalId
        workSystem {
          id
          value
        }
        workCategory {
          id
          value
        }
      }
      reviews {
        id
        reviewableType
        message
        createdAt
        completedAt
        user {
          id
          firstName
          lastName
        }
      }
      notes {
        id
        user {
          id
          firstName
          lastName
        }
        content
        createdAt
      }
    }
  }
`;

export const FETCH_ALL_WORK_SYSTEMS = gql`
  query WorkSystems($token: String!) {
    allWorkSystems(token: $token) {
      id
      value
    }
  }
`;

export const WORK_ORDER_SEARCH = gql`
  query SearchWorkOrders($token: String!, $search: String!) {
    searchWorkOrders(token: $token, search: $search) {
      id
      internalId
    }
  }
`;

export const FETCH_USER_VENDOR_DETAIL = gql`
  query Customer($token: String!, $id: Int!) {
    customer(token: $token, id: $id) {
      id
      name
      address1
      city
      parentRelationships {
        id
        customerInternalVal
      }
    }
  }
`;

export const FETCH_DOCUMENT = gql`
  query Document($token: String!, $id: Int!) {
    document(id: $id, token: $token) {
      id
      name
      content
      startRange
      endRange
      userType
      length
    }
  }
`;

export const FETCH_ALL_USER_GROUPS = gql`
  query UserGroups($token: String!) {
    allUserGroups(token: $token) {
      id
      name
    }
  }
`;

export const FETCH_CUSTOMERS = gql`
  query Customers($token: String!) {
    allCustomers(token: $token) {
      id
      name
      address1
      address2
      city
    }
  }
`;

export const CUSTOMER_SEARCH = gql`
  query SearchCustomers($token: String!, $search: String!, $excluding: String) {
    searchCustomers(token: $token, search: $search, excluding: $excluding) {
      id
      name
      internalName
      parentRelationships {
        id
        customerInternalVal
      }
    }
  }
`;

export const FETCH_CONTRACT_DETAILS_BY_CUSTOMER = gql`
  query FetchContractDetailsByCustomer($id: Int!, $token: String!) {
    fetchContractDetailsByCustomer(id: $id, token: $token) {
      id
      internalName
      startRange
      endRange
      billingValue
      invoiceRate
      contractType
      customers {
        id
        name
        internalName
        parentRelationships {
          id
          customerInternalVal
        }
      }
    }
  }
`;

export const GET_CUSTOMERS_BY_USER_ID = gql`
  query GetCustomersByUserId($id: Int!, $token: String!) {
    customerByUserId(id: $id, token: $token) {
      id
      name
      internalName
      address1
    }
  }
`;