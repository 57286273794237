import { ActionTypes } from "../constants/actionTypes";
import { toast } from "react-toastify";

export const userAuthenticate = (result) => async (dispatch) => {
  try {
    if (result && result?.errors?.length) {
      console.log(result?.errors[0]?.message, "inside errorrr");
      toast.error(result?.errors[0]?.message);
    }

    dispatch({
      type: ActionTypes.USER_AUTHENTICATE,
      payload: result,
    });
  } catch (e) {
    console.log(e, "e result");
  }
};

 /* istanbul ignore next */
export const getCurrentUser =
  (result, token, location, id, modal, history) => async (dispatch) => {
    try {
      if (result && result?.errors?.length) {
        console.log(result?.errors[0]?.message, "inside errorrr");
        toast.error(result?.errors[0]?.message);
      }

      console.log(modal, "result");

      if (result?.currentUser?.name) {
        dispatch({
          type: ActionTypes.USER_AUTHENTICATE,
          payload: { token, user: result.currentUser },
        });

        if (history != "!navigate") {
          if (location === "workorders" && id) {
            history.push(`/app/workorders/${id}`);
          } else if (location === "workorders" && modal) {
            history.push({
              pathname: `/app/workorders`,
              search: `?modal=${modal}`,
            });
          } else if (location === "workorders") {
            history.push(`/app/workorders`);
          } else if (location === "invoices" && id) {
            history.push(`/app/invoices/id`);
          } else if (location === "invoices") {
            history.push(`/app/invoices`);
          }
        }
      }
    } catch (e) {
      console.log(e, "e result");
    }
  };

export const logout = (authToken) => async (dispatch) => {
  try {
    dispatch({
      type: ActionTypes.LOGOUT,
    });
  } catch (e) {}
};
