import React, { useCallback, useEffect, useState } from "react";
import Select, { components, DropdownIndicatorProps } from "react-select";
import classNames from "classnames";
import Box from "@mui/material/Box";
import { useForm, Controller } from "react-hook-form";
import { Button, Grid, Input } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddlineItemsmodel from "../../../../components/Modal/AddLineItem/AddlineItemsmodel";
import Paper from "@mui/material/Paper";
import CallSplitIcon from "@mui/icons-material/CallSplit";

//Styles

import useStyles from "../../styles";

//Icons

import Plus from "../../../../assets/img/icons/plus.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import edit_pencil from "../../../../assets/img/icons/edit_pencil.svg";
import WhitePlus from "../../../../assets/img/icons/whitePlus.svg";
// import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  CUSTOMER_RELATIONSHIP_SEARCH,
  FETCH_ALL_WORK_CATEGORIES,
  FETCH_ALL_WORK_SYSTEMS,
  FETCH_EQUIPMENT_BY_CUSTOMER,
  GET_ALL_LINE_ITEMS,
} from "../../../../GraphQL/Queries";
import { useDispatch, useSelector } from "react-redux";
import BasicModal from "../../../../components/SaveChanges/SaveChanges";
import {
  UPDATE_BULK_WORKCATEGORY,
  UPDATE_LINE_ITEM,
} from "../../../../GraphQL/Mutations";
import { getInvoiceData } from "../../../../redux/actions/invoiceActions";
import moment from "moment/moment";
import { toast } from "react-toastify";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import SplitLineItemsModal from "../../../../components/Modal/AddLineItem/SplitLineItemsModal";
import { getStatusClass } from "../../../../helpers";
import ModalHistory from "../../../../components/History/ModalHistory";

// invoice type option for dropdowns
const invoiceType = [
  { label: "Customer", value: "Customer" },
  { label: "Total Care", value: "Total Care" },
  { label: "Extra Care", value: "Extra Care" },
  { label: "Internal", value: "Internal" },
];

const statusOptions = [
  { label: "Paid", value: "paid" },
  { label: "Internal", value: "internal" },
  { label: "Closed", value: "closed" },
  { label: "Accepted", value: "accepted" },
  { label: "Credited", value: "credited" },
  { label: "Open", value: "open" },
  { label: "Disputed", value: "disputed" },
  { label: "Submitted", value: "submitted" },
];

const EditInvoiceForm = ({
  isDisabled,
  openModal,
  setOpenModal,
  setisDisabled,
  data,
  refetchWorkOrder,
  openDeleteModal,
  setOpenDeleteModal,
  onValidDisputeClick,
  isFixed,
}) => {
  // let { invoiceNumber } = useParams();
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();
  const classes = useStyles();
  const dispatch = useDispatch();

  // state init
  const [file, setFile] = useState(null);
  const [focus, setFocus] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [workCategoryId, setWorkCategoryId] = useState();
  const [intialFormValues, setInitialFormValues] = useState({});
  const [errorsValue, setErrorsValue] = useState(false);
  const [customerName, setCustomerName] = useState(null);
  const [equipmentData, setequipmentData] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [vendorName, setVendorName] = useState(null);
  const [vendor_id, setVendorId] = useState(null);
  const [vendorFocus, setVendorFocus] = useState(false);
  const [isCustomer, setIsCustomer] = useState(false);
  const [lineItem, setLineItem] = useState({});

  const [lineItemDisabled, setLineItemDisabled] = useState(true);
  const [show, setShow] = useState(false);
  const [splitShow, setSplitShow] = useState(false);
  const [amount, setAmount] = useState({});

  //useselectors init
  const token = useSelector((state) => state?.auth?.authUserData?.token);
  const authRole = useSelector(
    (state) => state?.auth?.authUserData?.user?.role,
  );

  const workOrderData = useSelector(
    (state) => state?.invoices?.getInvoiceData?.workOrder,
  );

  const vendorId = useSelector(
    (state) => state?.auth?.authUserData?.user.vendorId,
  );

  //renderers
  useEffect(() => {
    setVendorName(intialFormValues?.vendor?.name);
    setVendorId(intialFormValues?.vendor?.id);
    setCustomerName(intialFormValues?.customer?.name);
    setCustomerId(intialFormValues?.customer?.id);
  }, [
    intialFormValues?.customer?.name,
    intialFormValues?.customer?.id,
    intialFormValues?.vendor?.name,
  ]);

  console.log("intialFormValues", intialFormValues);
  useEffect(() => {
    dispatch(getInvoiceData(data));
  }, [data]);

  /* istanbul ignore next */
  useEffect(() => {
    if (workOrderData) {
      setInitialFormValues({ ...workOrderData });
    }
  }, [workOrderData]);

  /* istanbul ignore next */
  useEffect(() => {
    if (lineItem?.unitPrice || lineItem?.quantity) {
      setLineItem({
        ...lineItem,
        amount: lineItem?.unitPrice * lineItem?.quantity,
      });
    }
  }, [lineItem?.unitPrice, lineItem?.quantity]);

  useEffect(() => {
    if (intialFormValues) {
      setAmount(getTotalAmount(intialFormValues?.lineItems));
    }
  }, [intialFormValues]);

  //-------------------Usequeries/Mutatations-----------//

  //api call to search for customers
  /* istanbul ignore next */
  const {
    error: customerRelationshipErrors,
    loading: customerRelationshipLoading,
    data: customerRelationshipSearch,
  } = useQuery(CUSTOMER_RELATIONSHIP_SEARCH, {
    variables: {
      token,
      vendorId,
      search: isCustomer ? customerName : vendorName,
    },
    skip: customerName?.length <= 3,
  });

  // api call to fetch quipments
  const {
    error: customerEquipmentError,
    loading: customerEquipmentLoading,
    data: customerEquipmentData,
  } = useQuery(FETCH_EQUIPMENT_BY_CUSTOMER, {
    variables: {
      token,
      id: customerId,
    },
    skip: !customerId,
  });

  //api call to fetch work cat
  const {
    error: workCategoriesErrors,
    loading: workCategoriesLoading,
    data: workCategories,
  } = useQuery(FETCH_ALL_WORK_CATEGORIES, {
    variables: {
      token,
    },
  });

  // api call to fetch work sys
  const {
    error: workSystemsError,
    loading: workSystemsLoading,
    data: workSystems,
  } = useQuery(FETCH_ALL_WORK_SYSTEMS, {
    variables: {
      token,
    },
  });

  // api to save line item
  /* istanbul ignore next */
  const [updateLineItem, { loading }] = useMutation(UPDATE_LINE_ITEM, {
    onCompleted: (data) => {
      setLineItemDisabled(true);
      toast.success("Line Item updated succesfully!");
      refetchWorkOrder();
    },
    onError: (error) => {
      toast.error("Unable to update Line Item!");
    },
  });

  /* istanbul ignore next */
  const [updateBulkWorkcategory, { loading: WorkCategoryLodaing }] =
    useMutation(UPDATE_BULK_WORKCATEGORY, {
      onCompleted: (data) => {
        console.log(data, "datadadadadada");
        // history.push(`/app/workorders/${data?.createWorkOrder?.workOrder?.id}`);
        refetchWorkOrder();
        // closeModal(); // Access the response data here
        toast.success("Categories updated!");
      },
      onError: (error) => {
        toast.success("Unable to update  Work Category!");
      },
    });

  const WorkCategoryData = workCategories?.allWorkCategories?.map((d) => ({
    label: d?.value,
    value: d?.id,
  }));

  // api to save line item
  /* istanbul ignore next */
  const onSaveLineItem = () => {
    console.log(lineItem, "lineItemlineItem");
    if (
      !lineItem?.description ||
      !lineItem?.quantity ||
      !lineItem?.unitPrice ||
      lineItem?.amount === null
    ) {
      setErrorsValue(true);
    } else {
      updateLineItem({
        variables: {
          token: token,
          id: lineItem?.id,
          productGroup: lineItem?.productGroup,
          description: lineItem?.description,
          quantity: parseFloat(lineItem?.quantity),
          unitPrice: parseFloat(lineItem?.unitPrice),
          tax: parseFloat(lineItem?.tax),
          amount:
            lineItem?.productGroup === "TAXES"
              ? 0
              : parseFloat(lineItem?.amount),
          workSystemId: parseInt(lineItem?.workSystem?.id),
          workCategoryId: parseInt(lineItem?.workCategory?.id),
          internalId: lineItem?.internalId,
        },
      });
      setErrorsValue(false);
    }
  };

  // Line Item tax,amount calculation logic
  /* istanbul ignore next */
  const getTotalAmount = (items) => {
    let subtotal = 0;
    let tax = 0;
    let total = 0;

    if (items?.length > 0) {
      subtotal = items?.reduce((acc, item) => acc + (item?.amount || 0), 0);
    
  
      // Calculate Sales Tax
      tax = items?.reduce((acc, item) => {
        return acc + item?.tax;
      }, 0);

      // Calculate Amount
      if (tax <= 0 && subtotal <= 0) {
        total = 0;
      } else {
        total = subtotal + tax;
      }
    }
    return { subtotal, tax, total };
  };

  //functions init

  // sercj for single customer
  /* istanbul ignore next */
  const handleSingleCustomer = (customer) => {
    setToggle(!toggle);
    setCustomerId(customer?.id);
    setFocus(false);

    setCustomerName(customer?.childCustomer.name);
  };

  /* istanbul ignore next */
  const handleSingleVendor = (vendor) => {
    setVendorId(vendor?.id);
    setVendorFocus(false);
    setVendorName(vendor?.childCustomer?.name);
  };

  /* istanbul ignore next */
  const handleCustomerSearchChange = (e) => {
    setCustomerName(e.target.value);
    setIsCustomer(true);
  };

  //handle VendorName value
  /* istanbul ignore next */
  const handleVendorSearchChange = (e) => {
    setVendorName(e.target.value);

    setIsCustomer(false);
  };

  const handleCustomerFocus = (e) => {
    setFocus(true);
  };

  const handleVendorFocus = (e) => {
    setVendorFocus(true);
  };
  /* istanbul ignore next */
  const handleWorkCategoryUpdate = () => {
    updateBulkWorkcategory({
      variables: {
        token,
        id: intialFormValues?.id,
        work_category: workCategoryId,
      },
    });
  };
  //open Edit modal
  /* istanbul ignore next */
  const editModeLineItem = (lineItem) => {
    setLineItem(lineItem);
    setLineItemDisabled(!lineItemDisabled);
  };

  /* istanbul ignore next */
  const splitLineItem = (lineItem) => {
    setLineItem(lineItem);

    setSplitShow(true);
  };

  const getLineItem = () => {
    return lineItem;
  };

  const DropdownIndicator = (props) => {
    return (
      <div>
        <components.DropdownIndicator {...props}>
          <img className={classNames(classes.plusIcon)} src={WhitePlus}></img>
        </components.DropdownIndicator>
      </div>
    );
  };
  /* istanbul ignore next */
  return (
    <>
      {
        <AddlineItemsmodel
          workSystems={workSystems}
          workCategories={workCategories}
          show={show}
          onHide={() => setShow(false)}
          workOrderId={intialFormValues?.id}
          token={token}
          refetchWorkOrder={refetchWorkOrder}
        />
      }
      {console.log(lineItem, "lineItemlineItem")}

      {
        <SplitLineItemsModal
          workSystems={workSystems}
          workCategories={workCategories}
          show={splitShow}
          lineItem={getLineItem()}
          onHide={() => setSplitShow(false)}
          workOrderId={intialFormValues?.id}
          token={token}
          refetchWorkOrder={refetchWorkOrder}
        />
      }

      <div className={classNames(classes.modalContainer)}>
        <div className={classNames(classes.headerContainerInvoice)}>
          <div className={classNames(classes.editInvoiceHeader)}>
            Work Order: {intialFormValues?.internalId}{" "}
          </div>
          {(data && authRole !== "customer" && (authRole === "admin" || authRole === "user") && !isDisabled) ? (
            <div className={classNames(classes.status)}>
              <Select
                className={`react_select`}
                closeMenuOnSelect={true}
                value={
                  intialFormValues && {
                    label:
                      intialFormValues?.aasmState &&
                      intialFormValues?.aasmState[0].toUpperCase() +
                        intialFormValues?.aasmState.slice(1),
                    value: intialFormValues?.aasmState,
                  }
                }
                options={statusOptions}
                onChange={(e) =>
                  setInitialFormValues({
                    ...intialFormValues,
                    aasmState: e.value,
                  })
                }
              />
            </div>
          ) : (
            <div className={classNames(classes.statusContainer)}>
              <div
                className={`${classes.status} ${getStatusClass(
                  intialFormValues?.aasmState,
                  classes,
                )}`}
              >
                {intialFormValues?.aasmState &&
                  intialFormValues?.aasmState[0].toUpperCase() +
                    intialFormValues?.aasmState.slice(1)}
              </div>
            </div>
          )}
        </div>
        <BasicModal
          open={openModal}
          setOpenModal={setOpenModal}
          formData={intialFormValues}
          customerId={customerId}
          equipmentData={equipmentData}
          token={token}
          vendor_id={vendor_id}
          setisDisabled={setisDisabled}
          setErrorsValue={setErrorsValue}
          refetchWorkOrder={refetchWorkOrder}
        />

        <DeleteModal
          open={openDeleteModal}
          setOpenModal={setOpenDeleteModal}
          token={token}
          refetchWorkOrder={refetchWorkOrder}
          id={lineItem?.id}
        />

        <form id="hook-form">
          <div className={classNames(classes.headerContainer)}>
            <Box
              sx={{
                padding: "40px 0 40px 0",
                borderBottom: "1px #D9D9D9 solid",
              }}
            >
              <Grid
                className={classNames(classes.formRow)}
                container
                spacing={2}
              >
                <Grid item xs={3} md={3}>
                  <div>
                    <label htmlFor="customer">
                      Customer <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="search">
                    <div className="input-group">
                      <input
                        // id="search"
                        id="customer"
                        name="search"
                        type="text"
                        disabled
                        className="form-control search_dropdown"
                        onFocus={(e) => handleCustomerFocus(e)}
                        value={customerName}
                        onChange={handleCustomerSearchChange}
                      />
                    </div>
                  </div>
                  {focus &&
                    customerRelationshipSearch &&
                    customerRelationshipSearch?.searchCustomerRelationships && (
                      <div className="search_drop">
                        {customerRelationshipSearch?.searchCustomerRelationships
                          ?.length > 0 &&
                          customerRelationshipSearch?.searchCustomerRelationships?.map(
                            (customer, i) => (
                              <>
                                <div
                                  className="search_items"
                                  onClick={() => handleSingleCustomer(customer)}
                                >
                                  {customer?.childCustomer?.name +
                                    customer?.customerInternalVal}
                                </div>
                              </>
                            ),
                          )}
                      </div>
                    )}
                </Grid>
                <Grid item xs={3} md={3}>
                  <div>
                    <label>
                      Vendor
                      <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>

                  <div className="search">
                    <div className="input-group">
                      <input
                        id="search"
                        name="search"
                        type="text"
                        disabled
                        className="form-control search_dropdown"
                        onFocus={(e) => handleVendorFocus(e)}
                        value={vendorName}
                        onChange={handleVendorSearchChange}
                      />
                    </div>
                  </div>
                  {vendorFocus &&
                    customerRelationshipSearch &&
                    customerRelationshipSearch?.searchCustomerRelationships && (
                      <div className="search_drop">
                        {customerRelationshipSearch?.searchCustomerRelationships
                          ?.length > 0 &&
                          customerRelationshipSearch?.searchCustomerRelationships?.map(
                            (vendor, i) => (
                              <>
                                <div
                                  className="search_items"
                                  onClick={() => handleSingleVendor(vendor)}
                                >
                                  {vendor?.childCustomer?.name +
                                    vendor?.customerInternalVal}
                                </div>
                              </>
                            ),
                          )}
                      </div>
                    )}
                </Grid>

                <Grid item xs={3} md={3}>
                  <div>
                    <label>
                      Equipment
                      <span style={{ color: "red" }}>*</span>{" "}
                    </label>
                  </div>
                  <Select
                    isDisabled
                    className={`react_select select_disabled_init`}
                    closeMenuOnSelect={true}
                    components={{ DropdownIndicator }}
                    onChange={(e) =>
                      setInitialFormValues({
                        ...intialFormValues,
                        equipment: {
                          id: e.value,
                          serialNumber: e.label,
                          model: e.model,
                        },
                      })
                    }
                    value={
                      intialFormValues && {
                        label:
                          intialFormValues?.equipment?.vendorEquipment[0]
                            ?.vendorEquipmentId,
                        value: intialFormValues?.equipment?.id,
                      }
                    }
                    options={
                      customerEquipmentData &&
                      customerEquipmentData?.customer?.equipment?.length > 0 &&
                      customerEquipmentData?.customer?.equipment?.map(
                        (equipment) => {
                          return {
                            label: equipment?.serialNumber,
                            value: equipment?.id,
                            model: equipment?.model,
                          };
                        },
                      )
                    }
                  />
                </Grid>
                <Grid item xs={3} md={3}>
                  <div>
                    <label>Model</label>
                  </div>

                  <Input
                    fullWidth
                    disabled
                    className={classes.textField}
                    value={intialFormValues?.equipment?.model}
                    {...register("modal")}
                  />
                </Grid>
              </Grid>
              <Grid
                className={classNames(classes.formRow)}
                container
                spacing={2}
              >
                <Grid item xs={3} md={3}>
                  <div>
                    <label>Serial Number</label>
                  </div>

                  <Input
                    fullWidth
                    disabled
                    className={classes.textField}
                    value={intialFormValues?.equipment?.serialNumber}
                    {...register("serialNumber")}
                  />
                </Grid>

                <Grid item xs={3} md={3}>
                  <div>
                    <label htmlFor="servicetyp">
                      Service Type
                      <span className={classNames(classes.mandatory)}>*</span>
                    </label>
                  </div>
                  <Select
                    id="servicetyp"
                    isDisabled={isDisabled}
                    className={`react_select ${
                      isDisabled && "disabled_select"
                    }`}
                    closeMenuOnSelect={true}
                    value={{
                      label: intialFormValues?.serviceType,
                      value: intialFormValues?.serviceType,
                    }}
                    onChange={(e) =>
                      setInitialFormValues({
                        ...intialFormValues,
                        serviceType: e.value,
                      })
                    }
                    components={{ DropdownIndicator }}
                    options={
                      workCategories &&
                      workCategories?.allWorkCategories?.map((cat) => {
                        return { label: cat?.value, value: cat?.value };
                      })
                    }
                  />
                  {!intialFormValues?.serviceType && errorsValue && (
                    <span style={{ color: "red" }}>
                      {" "}
                      This field is required
                    </span>
                  )}
                </Grid>
                <Grid item xs={3} md={3}>
                  <div>
                    <label htmlFor="KeyOnMeter">Key on Meter</label>
                  </div>

                  <Input
                    id="KeyOnMeter"
                    fullWidth
                    disabled={isDisabled}
                    type="number"
                    className={classes.textField}
                    value={intialFormValues?.keyOnMeter}
                    onChange={(e) =>
                      setInitialFormValues({
                        ...intialFormValues,
                        keyOnMeter: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={3} md={3}>
                  <div>
                    <label htmlFor="drivemeter">Drive Meter</label>
                  </div>

                  <Input
                    id="drivemeter"
                    fullWidth
                    disabled={isDisabled}
                    type="number"
                    className={classes.textField}
                    value={intialFormValues?.driveMeter}
                    onChange={(e) =>
                      setInitialFormValues({
                        ...intialFormValues,
                        driveMeter: e.target.value,
                      })
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                className={classNames(classes.formRow)}
                container
                spacing={2}
              >
                <Grid item xs={3} md={2}>
                  <div>
                    <label htmlFor="pumpmeter">Pump Meter</label>
                  </div>

                  <Input
                    id="pumpmeter"
                    fullWidth
                    disabled={isDisabled}
                    type="number"
                    className={classes.textField}
                    value={intialFormValues?.pumpMeter}
                    onChange={(e) =>
                      setInitialFormValues({
                        ...intialFormValues,
                        pumpMeter: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={3} md={2}>
                  <div>
                    <label htmlFor="downtime">Downtime</label>
                  </div>

                  <Input
                    id="downtime"
                    fullWidth
                    disabled={isDisabled}
                    type="number"
                    className={classes.textField}
                    value={intialFormValues?.downtime}
                    onChange={(e) =>
                      setInitialFormValues({
                        ...intialFormValues,
                        downtime: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={3} md={2}>
                  <div>
                    <label htmlFor="deadman">Deadman Meter</label>
                  </div>

                  <Input
                    id="deadmanMeter"
                    fullWidth
                    disabled={isDisabled}
                    type="number"
                    className={classes.textField}
                    value={intialFormValues?.deadmanMeter || "N/A"}
                    onChange={(e) =>
                      setInitialFormValues({
                        ...intialFormValues,
                        deadmanMeter: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={3} md={3}>
                  <div>
                    <label htmlFor="startdate">Start Date</label>
                  </div>

                  <Input
                    id="startdate"
                    type={"date"}
                    disabled={isDisabled}
                    fullWidth
                    className={classes.textField}
                    value={moment(intialFormValues?.startedAt).format(
                      "YYYY-MM-DD",
                    )}
                    onChange={(e) =>
                      setInitialFormValues({
                        ...intialFormValues,
                        startedAt: moment(e.target.value).format("MM-DD-YYYY"),
                      })
                    }
                    keyboardIcon={<img src={Plus}>a</img>}
                  />
                </Grid>
                <Grid item xs={3} md={3}>
                  <div>
                    <label htmlFor="enddate">End Date</label>
                  </div>

                  <Input
                    id="enddate"
                    type={"date"}
                    disabled={isDisabled}
                    fullWidth
                    className={classes.textField}
                    value={moment(intialFormValues?.stoppedAt).format(
                      "YYYY-MM-DD",
                    )}
                    onChange={(e) =>
                      setInitialFormValues({
                        ...intialFormValues,
                        stoppedAt: moment(e.target.value).format("MM-DD-YYYY"),
                      })
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                className={classNames(classes.formRow)}
                container
                spacing={2}
              >
                <Grid item xs={4} md={2}>
                  <div>
                    <label htmlFor="woi">
                      Work Order ID<span style={{ color: "red" }}>*</span>
                    </label>
                  </div>

                  <Input
                    id="woi"
                    fullWidth
                    className={classes.textField}
                    value={intialFormValues?.internalId}
                    disabled
                    onChange={(e) =>
                      setInitialFormValues({
                        ...intialFormValues,
                        internalId: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={4} md={2}>
                  <div>
                    <label>Purchase Order No.</label>
                  </div>
                  <Input
                    id="po"
                    fullWidth
                    className={classes.textField}
                    value={intialFormValues?.po || "N/A"}
                    disabled
                    onChange={(e) =>
                      setInitialFormValues({
                        ...intialFormValues,
                        po: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={4} md={4}>
                  <div>
                    <label htmlFor="intype">
                      Invoice Type<span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <Controller
                    name="invoiceType"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <Select
                        id="intype"
                        {...field}
                        className={`react_select ${
                          isDisabled && "disabled_select"
                        }`}
                        closeMenuOnSelect={true}
                        components={{ DropdownIndicator }}
                        isDisabled={isDisabled}
                        value={{
                          label: intialFormValues?.invoiceType,
                          value: intialFormValues?.invoiceType,
                        }}
                        onChange={(e) =>
                          setInitialFormValues({
                            ...intialFormValues,
                            invoiceType: e.value,
                          })
                        }
                        options={invoiceType}
                      />
                    )}
                  />
                  {errors.associateWorkOrders &&
                    errors.associateWorkOrders.type === "required" && (
                      <span className={classNames(classes.mandatory)}>
                        {" "}
                        This field is required
                      </span>
                    )}
                </Grid>
                <Grid item xs={4} md={4}>
                  <div>
                    <label htmlFor="invoiceat">
                      Invoiced At<span style={{ color: "red" }}>*</span>
                    </label>
                  </div>

                  <Input
                    id="invoiceat"
                    type={"date"}
                    fullWidth
                    className={classes.textField}
                    keyboardIcon={<img src={Plus}>a</img>}
                    disabled={isDisabled}
                    value={moment(intialFormValues?.invoicedAt).format(
                      "YYYY-MM-DD",
                    )}
                    onChange={(e) =>
                      setInitialFormValues({
                        ...intialFormValues,
                        internalId: moment(e.target.value).format("YYYY-MM-DD"),
                      })
                    }
                  />
                  {errors.invoicedAt && (
                    <span style={{ color: "red" }}>
                      {" "}
                      This field is required
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} md={12}>
                  <div>
                    <label htmlFor="desc">Description</label>
                  </div>

                  <textarea
                    id="desc"
                    disabled={isDisabled}
                    row={100}
                    col={100}
                    className={classNames(
                      classes.textField,
                      classes.description_textArea,
                    )}
                    value={intialFormValues?.serviceDescription}
                    onChange={(e) =>
                      setInitialFormValues({
                        ...intialFormValues,
                        serviceDescription: e.target.value,
                      })
                    }
                  />
                </Grid>
              </Grid>{" "}
              <Grid
                className={classNames(classes.formRow)}
                container
                spacing={2}
              >
                <Grid item xs={6} md={6}>
                  <div>
                    <label htmlFor="chkbox1">
                      Does this Work Order qualify as a Planned Maintenance?
                    </label>
                  </div>

                  <input
                    id="chkbox1"
                    type="checkbox"
                    fullWidth
                    checked={intialFormValues?.countAsPm}
                    className={classes.textField}
                    disabled={isDisabled}
                    onChange={(e) =>
                      setInitialFormValues({
                        ...intialFormValues,
                        countAsPm: e.target.checked,
                      })
                    }
                  />
                </Grid>{" "}
                <Grid item xs={6} md={6}>
                  <div>
                    <label>
                      Does this Work Order qualify as a Major Fluid Check?
                    </label>
                  </div>

                  <input
                    type="checkbox"
                    fullWidth
                    checked={intialFormValues?.countAsMfc}
                    disabled={isDisabled}
                    onChange={(e) =>
                      setInitialFormValues({
                        ...intialFormValues,
                        countAsMfc: e.target.checked,
                      })
                    }
                    className={classes.textField}
                  />
                </Grid>
              </Grid>
            </Box>
          </div>
        </form>

        {data &&
          authRole === "customer" &&
          data?.workOrder?.invoiceType === "Customer" && (
            <Box
              sx={{
                margin: "40px 0 40px 0",
                borderBottom: "1px #D9D9D9 solid",
              }}
            >
              <div className={classNames(classes.editInvoiceHeader)}>
                Line Items
              </div>

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Product Group</TableCell>
                      <TableCell>Internal Id</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Unit Price</TableCell>
                      <TableCell>Tax</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Work System</TableCell>
                      <TableCell>Work Category</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {intialFormValues &&
                      intialFormValues?.lineItems?.map((row, i) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          {console.log(row, "dwqdwqd")}
                          {lineItemDisabled || lineItem?.id !== row?.id ? (
                            <TableCell component="th" scope="row">
                              {console.log(lineItem, "dwqdwqdwqdwqdwqdwqd")}
                              {row.productGroup}
                            </TableCell>
                          ) : (
                            lineItem?.id === row?.id && (
                              <TableCell>
                                <Controller
                                  name="productGroup"
                                  rules={{ required: true }}
                                  control={control}
                                  render={({ field }) => (
                                    <select
                                      {...field}
                                      className={classes.lineItemTextField}
                                      closeMenuOnSelect={true}
                                      value={lineItem?.productGroup}
                                      onChange={(e) =>
                                        setLineItem({
                                          ...lineItem,
                                          productGroup: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="PARTS">Parts</option>
                                      <option value="LABOR">Labor</option>
                                      <option value="TAXES">Taxes</option>
                                      <option value="OTHERS">Others</option>
                                    </select>
                                  )}
                                />
                              </TableCell>
                            )
                          )}

                          {lineItemDisabled || lineItem?.id !== row?.id ? (
                            <TableCell>{row.internalId}</TableCell>
                          ) : (
                            lineItem?.id === row?.id && (
                              <TableCell>
                                <Input
                                  className={classes.lineItemTextField}
                                  defaultValue={lineItem.internalId}
                                  onChange={(e) =>
                                    setLineItem({
                                      ...lineItem,
                                      internalId: e.target.value,
                                    })
                                  }
                                ></Input>
                              </TableCell>
                            )
                          )}

                          {lineItemDisabled || lineItem?.id !== row?.id ? (
                            <TableCell>{row.description}</TableCell>
                          ) : (
                            lineItem?.id === row?.id && (
                              <TableCell>
                                <Input
                                  className={classes.lineItemTextFieldDesc}
                                  defaultValue={lineItem.description}
                                  onChange={(e) =>
                                    setLineItem({
                                      ...lineItem,
                                      description: e.target.value,
                                    })
                                  }
                                ></Input>
                                {!lineItem?.description && errorsValue && (
                                  <div style={{ color: "red" }}>
                                    {" "}
                                    This field is required
                                  </div>
                                )}{" "}
                              </TableCell>
                            )
                          )}

                          {lineItemDisabled || lineItem?.id !== row?.id ? (
                            <TableCell>{parseFloat(row.quantity)}</TableCell>
                          ) : (
                            lineItem?.id === row?.id && (
                              <TableCell>
                                <Input
                                  type="number"
                                  className={classes.lineItemTextField}
                                  value={lineItem.quantity}
                                  onChange={(e) =>
                                    setLineItem({
                                      ...lineItem,
                                      quantity: e.target.value,
                                    })
                                  }
                                ></Input>
                                {!lineItem?.quantity && errorsValue && (
                                  <div style={{ color: "red" }}>
                                    {" "}
                                    This field is required
                                  </div>
                                )}{" "}
                              </TableCell>
                            )
                          )}

                          {lineItemDisabled || lineItem?.id !== row?.id ? (
                            <TableCell>
                              {parseFloat(row.unitPrice).toFixed(2)}
                            </TableCell>
                          ) : (
                            lineItem?.id === row?.id && (
                              <TableCell>
                                <Input
                                  className={classes.lineItemTextField}
                                  value={parseFloat(lineItem.unitPrice)}
                                  onChange={(e) =>
                                    setLineItem({
                                      ...lineItem,
                                      unitPrice: e.target.value,
                                    })
                                  }
                                ></Input>
                                {!lineItem?.unitPrice && errorsValue && (
                                  <div style={{ color: "red" }}>
                                    {" "}
                                    This field is required
                                  </div>
                                )}{" "}
                              </TableCell>
                            )
                          )}

                          {lineItemDisabled || lineItem?.id !== row?.id ? (
                            <TableCell>
                              {parseFloat(row.tax).toFixed(2)}
                            </TableCell>
                          ) : (
                            lineItem?.id === row?.id && (
                              <TableCell>
                                <Input
                                  className={classes.lineItemTextField}
                                  value={parseFloat(lineItem.tax)}
                                  onChange={(e) =>
                                    setLineItem({
                                      ...lineItem,
                                      tax: e.target.value,
                                    })
                                  }
                                ></Input>
                              </TableCell>
                            )
                          )}

                          {lineItemDisabled || lineItem?.id !== row?.id ? (
                            <TableCell>
                              {parseFloat(row.amount).toFixed(2)}
                            </TableCell>
                          ) : (
                            lineItem?.id === row?.id && (
                              <TableCell>
                                <Input
                                  disabled={true}
                                  className={classes.lineItemTextField}
                                  value={
                                    lineItem?.productGroup === "TAXES"
                                      ? 0
                                      : parseFloat(lineItem?.amount)
                                  }
                                  onChange={(e) =>
                                    setLineItem({
                                      ...lineItem,
                                      amount: e.target.value,
                                    })
                                  }
                                ></Input>
                                {!lineItem?.amount && errorsValue && (
                                  <div style={{ color: "red" }}>
                                    {" "}
                                    This field is required
                                  </div>
                                )}{" "}
                              </TableCell>
                            )
                          )}

                          {lineItemDisabled || lineItem?.id !== row?.id ? (
                            <TableCell>{row.workSystem?.value}</TableCell>
                          ) : (
                            lineItem?.id === row?.id && (
                              <TableCell>
                                <Controller
                                  name="invoiceType"
                                  rules={{ required: true }}
                                  control={control}
                                  render={({ field }) => (
                                    <select
                                      {...field}
                                      closeMenuOnSelect={true}
                                      className={classes.lineItemTextField}
                                      defaultValue={lineItem?.workSystem?.value}
                                      onChange={(e) =>
                                        setLineItem({
                                          ...lineItem,
                                          workSystem: {
                                            ...lineItem.workSystem,
                                            id: e.target.value,
                                          },
                                        })
                                      }
                                    >
                                      <option value={""}></option>
                                      {workSystems &&
                                        workSystems?.allWorkSystems?.map(
                                          (sys) => (
                                            <option value={sys?.id}>
                                              {sys?.value}
                                            </option>
                                          ),
                                        )}
                                    </select>
                                  )}
                                />
                              </TableCell>
                            )
                          )}

                          {lineItemDisabled || lineItem?.id !== row?.id ? (
                            <TableCell>{row.workCategory?.value}</TableCell>
                          ) : (
                            lineItem?.id === row?.id && (
                              <TableCell>
                                <select
                                  className={classes.lineItemTextField}
                                  closeMenuOnSelect={true}
                                  defaultValue={lineItem?.workCategory?.value}
                                  onChange={(e) =>
                                    setLineItem({
                                      ...lineItem,
                                      workCategory: {
                                        ...lineItem.workCategory,
                                        id: e.target.value,
                                      },
                                    })
                                  }
                                >
                                  {/* <option value={""}></option> */}
                                  {workCategories &&
                                    workCategories?.allWorkCategories?.map(
                                      (cat) => (
                                        <option value={cat?.id}>
                                          {cat?.value}
                                        </option>
                                      ),
                                    )}
                                </select>
                              </TableCell>
                            )
                          )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid className={classes.breakdownContainer} container>
                <Grid md={7}>
                  <button
                    className="upload_button"
                    onClick={() => setShow(true)}
                  >
                    Add Line Items
                  </button>
                </Grid>
                <Grid className={classes.breakdownText} md={2}>
                  <div className={classes.breakdownText_amount}>Subtotal</div>
                  <div className={classes.breakdownText_amount}>
                    Sales tax rate
                  </div>
                  <div className={classes.breakdownText_amount}>Amount</div>
                </Grid>

                <Grid className={classes.breakdownText} md={1}>
                  <div className={classes.breakdownText_amount}>
                    ${" "}
                    {amount?.subtotal
                      ? parseFloat(amount?.subtotal).toFixed(2)
                      : 0}
                  </div>
                  <div className={classes.breakdownText_amount}>
                    $ {amount?.tax ? parseFloat(amount?.tax).toFixed(2) : 0}
                  </div>
                  <div className={classes.breakdownText_amount}>
                    $ {amount?.total ? parseFloat(amount?.total).toFixed(2) : 0}
                  </div>
                </Grid>
              </Grid>
            </Box>
          )}

        {data &&
          authRole === "customer" &&
          data?.workOrder?.invoiceType != "Customer" && (
            <Box
              sx={{
                margin: "40px 0 40px 0",
                borderBottom: "1px #D9D9D9 solid",
              }}
            >
              <div className={classNames(classes.editInvoiceHeader)}>
                Line Items
              </div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Product Group</TableCell>
                      <TableCell>Internal Id</TableCell>
                      <TableCell>Description</TableCell>

                      <TableCell>Work System</TableCell>
                      <TableCell>Work Category</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {intialFormValues &&
                      intialFormValues?.lineItems?.map((row, i) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          {console.log(row, "dwqdwqd")}
                          {lineItemDisabled || lineItem?.id !== row?.id ? (
                            <TableCell component="th" scope="row">
                              {console.log(lineItem, "dwqdwqdwqdwqdwqdwqd")}
                              {row.productGroup}
                            </TableCell>
                          ) : (
                            lineItem?.id === row?.id && (
                              <TableCell>
                                <Controller
                                  name="productGroup"
                                  rules={{ required: true }}
                                  control={control}
                                  render={({ field }) => (
                                    <select
                                      {...field}
                                      className={classes.lineItemTextField}
                                      closeMenuOnSelect={true}
                                      value={lineItem?.productGroup}
                                      onChange={(e) =>
                                        setLineItem({
                                          ...lineItem,
                                          productGroup: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="PARTS">Parts</option>
                                      <option value="LABOR">Labor</option>
                                      <option value="TAXES">Taxes</option>
                                      <option value="OTHERS">Others</option>
                                    </select>
                                  )}
                                />
                              </TableCell>
                            )
                          )}

                          {lineItemDisabled || lineItem?.id !== row?.id ? (
                            <TableCell>{row.internalId}</TableCell>
                          ) : (
                            lineItem?.id === row?.id && (
                              <TableCell>
                                <Input
                                  className={classes.lineItemTextField}
                                  defaultValue={lineItem.internalId}
                                  onChange={(e) =>
                                    setLineItem({
                                      ...lineItem,
                                      internalId: e.target.value,
                                    })
                                  }
                                ></Input>
                              </TableCell>
                            )
                          )}

                          {lineItemDisabled || lineItem?.id !== row?.id ? (
                            <TableCell>{row.description}</TableCell>
                          ) : (
                            lineItem?.id === row?.id && (
                              <TableCell>
                                <Input
                                  className={classes.lineItemTextFieldDesc}
                                  defaultValue={lineItem.description}
                                  onChange={(e) =>
                                    setLineItem({
                                      ...lineItem,
                                      description: e.target.value,
                                    })
                                  }
                                ></Input>
                                {!lineItem?.description && errorsValue && (
                                  <div style={{ color: "red" }}>
                                    {" "}
                                    This field is required
                                  </div>
                                )}{" "}
                              </TableCell>
                            )
                          )}

                          {lineItemDisabled || lineItem?.id !== row?.id ? (
                            <TableCell>{row.workSystem?.value}</TableCell>
                          ) : (
                            lineItem?.id === row?.id && (
                              <TableCell>
                                <Controller
                                  name="invoiceType"
                                  rules={{ required: true }}
                                  control={control}
                                  render={({ field }) => (
                                    <select
                                      {...field}
                                      closeMenuOnSelect={true}
                                      className={classes.lineItemTextField}
                                      defaultValue={lineItem?.workSystem?.value}
                                      onChange={(e) =>
                                        setLineItem({
                                          ...lineItem,
                                          workSystem: {
                                            ...lineItem.workSystem,
                                            id: e.target.value,
                                          },
                                        })
                                      }
                                    >
                                      <option value={""}></option>
                                      {workSystems &&
                                        workSystems?.allWorkSystems?.map(
                                          (sys) => (
                                            <option value={sys?.id}>
                                              {sys?.value}
                                            </option>
                                          ),
                                        )}
                                    </select>
                                  )}
                                />
                              </TableCell>
                            )
                          )}

                          {lineItemDisabled || lineItem?.id !== row?.id ? (
                            <TableCell>{row.workCategory?.value}</TableCell>
                          ) : (
                            lineItem?.id === row?.id && (
                              <TableCell>
                                <select
                                  className={classes.lineItemTextField}
                                  closeMenuOnSelect={true}
                                  defaultValue={lineItem?.workCategory?.value}
                                  onChange={(e) =>
                                    setLineItem({
                                      ...lineItem,
                                      workCategory: {
                                        ...lineItem.workCategory,
                                        id: e.target.value,
                                      },
                                    })
                                  }
                                >
                                  {/* <option value={""}></option> */}
                                  {workCategories &&
                                    workCategories?.allWorkCategories?.map(
                                      (cat) => (
                                        <option value={cat?.id}>
                                          {cat?.value}
                                        </option>
                                      ),
                                    )}
                                </select>
                              </TableCell>
                            )
                          )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid className={classes.breakdownContainer} container>
                <Grid md={7}>
                  <button
                    className="upload_button"
                    onClick={() => setShow(true)}
                  >
                    Add Line Items
                  </button>
                </Grid>
                <Grid className={classes.breakdownText} md={2}>
                  <div className={classes.breakdownText_amount}>Subtotal</div>
                  <div className={classes.breakdownText_amount}>
                    Sales tax rate
                  </div>
                  <div className={classes.breakdownText_amount}>Amount</div>
                </Grid>

                <Grid className={classes.breakdownText} md={1}>
                  <div className={classes.breakdownText_amount}>
                    ${" "}
                    {amount?.subtotal
                      ? parseFloat(amount?.subtotal).toFixed(2)
                      : 0}
                  </div>
                  <div className={classes.breakdownText_amount}>
                    $ {amount?.tax ? parseFloat(amount?.tax).toFixed(2) : 0}
                  </div>
                  <div className={classes.breakdownText_amount}>
                    $ {amount?.total ? parseFloat(amount?.total).toFixed(2) : 0}
                  </div>
                </Grid>
              </Grid>
            </Box>
          )}

        {((data &&
          authRole != "customer" &&
          data?.workOrder?.invoiceType != "Customer") ||
          authRole === "admin" ||
          authRole === "user" || authRole === "vendor") && (
          <Box
            sx={{
              margin: "40px 0 40px 0",
              borderBottom: "1px #D9D9D9 solid",
            }}
          >
            <div className={classNames(classes.editInvoiceHeader)}>
              <Box flexGrow={1}>Line Items</Box>
            </div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Product Group</TableCell>
                    <TableCell>Internal Id</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Unit Price</TableCell>
                    <TableCell>Tax</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Work System</TableCell>
                    <TableCell>
                      Work Category
                      <Box>
                        <Box display={"flex"}>
                          {" "}
                          <div className="workCategory-select">
                            <select
                              onChange={(e) =>
                                setWorkCategoryId(parseInt(e.target.value))
                              }
                              //   value={formData.existingRateCard}
                              className="workCat_select"
                            >
                              {workCategories &&
                                workCategories?.allWorkCategories?.map(
                                  (data) => (
                                    <option value={data?.id}>
                                      {data?.value}
                                    </option>
                                  ),
                                )}
                            </select>
                          </div>{" "}
                          <button
                            className={classes.bulkCategorybtn}
                            onClick={() => handleWorkCategoryUpdate()}
                          >
                            <Box fontSize={"13px"}>Bulk Update</Box>
                          </button>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {intialFormValues &&
                    intialFormValues?.lineItems?.map((row, i) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {console.log(row, "dwqdwqd")}
                        {lineItemDisabled || lineItem?.id !== row?.id ? (
                          <TableCell component="th" scope="row">
                            {console.log(lineItem, "dwqdwqdwqdwqdwqdwqd")}
                            {row.productGroup}
                          </TableCell>
                        ) : (
                          lineItem?.id === row?.id && (
                            <TableCell>
                              <Controller
                                name="productGroup"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                  <select
                                    {...field}
                                    className={classes.lineItemTextField}
                                    closeMenuOnSelect={true}
                                    value={lineItem?.productGroup}
                                    onChange={(e) =>
                                      setLineItem({
                                        ...lineItem,
                                        productGroup: e.target.value,
                                      })
                                    }
                                  >
                                    <option value="PARTS">Parts</option>
                                    <option value="LABOR">Labor</option>
                                    <option value="TAXES">Taxes</option>
                                    <option value="OTHERS">Others</option>
                                  </select>
                                )}
                              />
                            </TableCell>
                          )
                        )}

                        {lineItemDisabled || lineItem?.id !== row?.id ? (
                          <TableCell>{row.internalId}</TableCell>
                        ) : (
                          lineItem?.id === row?.id && (
                            <TableCell>
                              <Input
                                className={classes.lineItemTextField}
                                defaultValue={lineItem.internalId}
                                onChange={(e) =>
                                  setLineItem({
                                    ...lineItem,
                                    internalId: e.target.value,
                                  })
                                }
                              ></Input>
                            </TableCell>
                          )
                        )}

                        {lineItemDisabled || lineItem?.id !== row?.id ? (
                          <TableCell>{row.description}</TableCell>
                        ) : (
                          lineItem?.id === row?.id && (
                            <TableCell>
                              <Input
                                className={classes.lineItemTextFieldDesc}
                                defaultValue={lineItem.description}
                                onChange={(e) =>
                                  setLineItem({
                                    ...lineItem,
                                    description: e.target.value,
                                  })
                                }
                              ></Input>
                              {!lineItem?.description && errorsValue && (
                                <div style={{ color: "red" }}>
                                  {" "}
                                  This field is required
                                </div>
                              )}{" "}
                            </TableCell>
                          )
                        )}

                        {lineItemDisabled || lineItem?.id !== row?.id ? (
                          <TableCell>{parseFloat(row.quantity)}</TableCell>
                        ) : (
                          lineItem?.id === row?.id && (
                            <TableCell>
                              <Input
                                type="number"
                                className={classes.lineItemTextField}
                                value={lineItem.quantity}
                                onChange={(e) =>
                                  setLineItem({
                                    ...lineItem,
                                    quantity: e.target.value,
                                  })
                                }
                              ></Input>
                              {!lineItem?.quantity && errorsValue && (
                                <div style={{ color: "red" }}>
                                  {" "}
                                  This field is required
                                </div>
                              )}{" "}
                            </TableCell>
                          )
                        )}

                        {lineItemDisabled || lineItem?.id !== row?.id ? (
                          <TableCell>
                            {parseFloat(row.unitPrice).toFixed(2)}
                          </TableCell>
                        ) : (
                          lineItem?.id === row?.id && (
                            <TableCell>
                              <Input
                                className={classes.lineItemTextField}
                                value={parseFloat(lineItem.unitPrice)}
                                onChange={(e) =>
                                  setLineItem({
                                    ...lineItem,
                                    unitPrice: e.target.value,
                                  })
                                }
                              ></Input>
                              {!lineItem?.unitPrice && errorsValue && (
                                <div style={{ color: "red" }}>
                                  {" "}
                                  This field is required
                                </div>
                              )}{" "}
                            </TableCell>
                          )
                        )}

                        {lineItemDisabled || lineItem?.id !== row?.id ? (
                          <TableCell>
                            {parseFloat(row.tax).toFixed(2)}
                          </TableCell>
                        ) : (
                          lineItem?.id === row?.id && (
                            <TableCell>
                              <Input
                                className={classes.lineItemTextField}
                                value={parseFloat(lineItem.tax)}
                                onChange={(e) =>
                                  setLineItem({
                                    ...lineItem,
                                    tax: e.target.value,
                                  })
                                }
                              ></Input>
                            </TableCell>
                          )
                        )}

                        {lineItemDisabled || lineItem?.id !== row?.id ? (
                          <TableCell>
                            {parseFloat(row.amount).toFixed(2)}
                          </TableCell>
                        ) : (
                          lineItem?.id === row?.id && (
                            <TableCell>
                              <Input
                                disabled={true}
                                className={classes.lineItemTextField}
                                value={
                                  lineItem?.productGroup === "TAXES"
                                    ? 0
                                    : parseFloat(lineItem?.amount)
                                }
                                onChange={(e) =>
                                  setLineItem({
                                    ...lineItem,
                                    amount: e.target.value,
                                  })
                                }
                              ></Input>
                              {!lineItem?.amount && errorsValue && (
                                <div style={{ color: "red" }}>
                                  {" "}
                                  This field is required
                                </div>
                              )}{" "}
                            </TableCell>
                          )
                        )}

                        {lineItemDisabled || lineItem?.id !== row?.id ? (
                          <TableCell>{row.workSystem?.value}</TableCell>
                        ) : (
                          lineItem?.id === row?.id && (
                            <TableCell>
                              <Controller
                                name="invoiceType"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                  <select
                                    {...field}
                                    closeMenuOnSelect={true}
                                    className={classes.lineItemTextField}
                                    defaultValue={lineItem?.workSystem?.value}
                                    onChange={(e) =>
                                      setLineItem({
                                        ...lineItem,
                                        workSystem: {
                                          ...lineItem.workSystem,
                                          id: e.target.value,
                                        },
                                      })
                                    }
                                  >
                                    <option value={""}></option>
                                    {workSystems &&
                                      workSystems?.allWorkSystems?.map(
                                        (sys) => (
                                          <option value={sys?.id}>
                                            {sys?.value}
                                          </option>
                                        ),
                                      )}
                                  </select>
                                )}
                              />
                            </TableCell>
                          )
                        )}

                        {lineItemDisabled || lineItem?.id !== row?.id ? (
                          <TableCell>{row.workCategory?.value}</TableCell>
                        ) : (
                          lineItem?.id === row?.id && (
                            <TableCell>
                              <select
                                className={classes.lineItemTextField}
                                closeMenuOnSelect={true}
                                defaultValue={lineItem?.workCategory?.value}
                                onChange={(e) =>
                                  setLineItem({
                                    ...lineItem,
                                    workCategory: {
                                      ...lineItem.workCategory,
                                      id: e.target.value,
                                    },
                                  })
                                }
                              >
                                {/* <option value={""}></option> */}
                                {workCategories &&
                                  workCategories?.allWorkCategories?.map(
                                    (cat) => (
                                      <option value={cat?.id}>
                                        {cat?.value}
                                      </option>
                                    ),
                                  )}
                              </select>
                            </TableCell>
                          )
                        )}
                        <TableCell>
                          {row.productGroup === "LABOR" &&
                            (lineItemDisabled || lineItem?.id === row?.id) &&
                            lineItemDisabled && (
                              <Button
                                className="cursor-pointer"
                                onClick={() => splitLineItem(row)}
                              >
                                <CallSplitIcon />
                              </Button>
                            )}
                        </TableCell>
                        <TableCell>
                          {(lineItemDisabled || lineItem?.id === row?.id) &&
                            (lineItemDisabled ? (
                              <div
                                className="cursor-pointer"
                                onClick={() => editModeLineItem(row)}
                              >
                                <img src={edit_pencil} />
                              </div>
                            ) : (
                              <div
                                className="cursor-pointer"
                                onClick={() => onSaveLineItem()}
                              >
                                <SaveIcon sx={{ color: "#00b4d8" }} />
                              </div>
                            ))}
                        </TableCell>
                        <TableCell>
                          <div
                            onClick={() => {
                              setOpenDeleteModal(true);
                              setLineItem(row);
                            }}
                            className="cursor-pointer"
                          >
                            <DeleteIcon sx={{ color: "red" }} />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {((data &&
              authRole != "customer" &&
              data?.workOrder?.invoiceType != "Customer") ||
              authRole === "admin" ||
              authRole === "user" ||
              authRole === "vendor" ||
              (authRole === "customer" &&
                data?.workOrder?.invoiceType === "Customer")) && (
              <Grid className={classes.breakdownContainer} container>
                <Grid md={7}>
                  <button
                    className="upload_button"
                    onClick={() => setShow(true)}
                  >
                    Add Line Items
                  </button>
                </Grid>
                <Grid className={classes.breakdownText} md={2}>
                  <div className={classes.breakdownText_amount}>Subtotal</div>
                  <div className={classes.breakdownText_amount}>
                    Sales tax rate
                  </div>
                  <div className={classes.breakdownText_amount}>Amount</div>
                </Grid>

                <Grid className={classes.breakdownText} md={1}>
                  <div className={classes.breakdownText_amount}>
                    ${" "}
                    {amount?.subtotal
                      ? parseFloat(amount?.subtotal).toFixed(2)
                      : 0}
                  </div>
                  <div className={classes.breakdownText_amount}>
                    $ {amount?.tax ? parseFloat(amount?.tax).toFixed(2) : 0}
                  </div>
                  <div className={classes.breakdownText_amount}>
                    $ {amount?.total ? parseFloat(amount?.total).toFixed(2) : 0}
                  </div>
                </Grid>
              </Grid>
            )}
          </Box>
        )}
      </div>
    </>
  );
};

export default EditInvoiceForm;
