import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  container: {
    margin: "24px 0",
  },
  pageTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "28px",
  },
  typo: {
    fontSize: "20px",
    color: "#313131",
  },
  button: {
    boxShadow: theme.customShadows?.widget,
    textTransform: "none",
    "&:active": {
      boxShadow: theme.customShadows?.widgetWide,
    },
  },
}));
