import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";

import Themes from "./themes";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import "./App.css";
import { Provider, useSelector } from "react-redux";
import { loadState, saveState } from "./localStorage";
import store, { Persistor } from "./redux/store";
import { PersistGate } from "redux-persist/es/integration/react";

// Apollo Client

import SERVER_BASE_URL from "./config/config";

// Use Selector to get the data from store
// const authUserData = useSelector((state) => state?.auth?.authUserData);

// Layout Provider is used for sidebar and header layout (It is globally accessible throughout the application)

// Theme Provider is used for Global CSS, for branding and reusing

// App Component has the routing logics throughout the Applications

const persistedState = loadState();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={Persistor}>
      <LayoutProvider>
        <UserProvider>
          <ThemeProvider theme={Themes.default}>
            <CssBaseline />

            <App />
            <ToastContainer
              position="bottom-center"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
            />
          </ThemeProvider>
        </UserProvider>
      </LayoutProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
