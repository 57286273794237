import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  cardBox: {
    marginLeft: 0,
    marginBottom: "32px",
  },
  button: {
    background: "#FFFFFF",
    border: "1px solid #DEDEDE",
    borderRadius: "3px",
    paddingTop: "13px",
    paddingBottom: "13px",
    maxHeight: "32px",

    minWidth: "32px",
    margin: "0px 8px",
    color: "#313131",
    fontWeight: "400",
  },
  bulkCategorybtn: {
    background: "#023e8a",
    borderRadius: "3px",
    cursor: "pointer",
    color: "#ffffff",
    margin: "0",
    width: "100%",
    padding: "0",
  },
  vendorDiscription: {
    marginTop: "20px",
  },
  modalContainer: {
    margin: "0px 32px",
  },
  addModalContainer: {
    margin: "0px 32px",
    width: "1016px",
  },
  editableInput: {
    width: "50px",
    marginTop: "15px",
    marginLeft: "10px",
  },
  headerContainer: {
    borderBottom: "1px solid #DEDEDE",
  },
  headerContainerInvoice: {
    borderBottom: "1px solid #DEDEDE",
    display: "flex",
    justifyContent: "space-between",
  },
  statusContainer: {
    fontWeight: "500",
    fontSize: "15px",
    marginTop: "15px",
  },
  status: {
    padding: "5px 15px",
    color: "black",
    borderRadius: "30px",
  },
  modalHeader: {
    padding: "16px 0px",
    fontWeight: "500",
    fontSize: "16px",
    color: "#pink",
  },
  editInvoiceHeader: {
    padding: "16px 0px",
    fontWeight: "500",
    fontSize: "20px",
    color: "#313131",
    paddingRight: "200px",
    display: "flex",
  },
  textField: {
    background: "#F3F6F8",
    padding: "0px 10px",
    width: "336px",
    height: "32px",
    border: "1px solid #DEDEDE",
  },
  lineItemTextField: {
    background: "#F3F6F8",
    padding: "0px 10px",
    width: "100px",
    height: "32px",
    border: "1px solid #DEDEDE",
  },

  workCategorySelect: {
    width: "100px",
    border: "1px solid #DEDEDE",
    height: "38px",
    marginRight: "10px",
    padding: "0px 10px",
    background: "#F3F6F8",
  },
  lineItemTextFieldDesc: {
    background: "#F3F6F8",
    padding: "0px 10px",
    width: "100%",
    height: "32px",
    border: "1px solid #DEDEDE",
  },
  description_textArea: {
    height: "80px",
    width: "100%",
  },

  description: {
    width: "100%",

    padding: "4px 0 5px",
  },

  fileUploader: {
    background: "#F3F6F8",
    border: "1px dashed #C1C1C1",
    borderRadius: "3px",
    height: "180px",
  },
  fileTypeText: {
    color: "#6F6F6F",
    fontWeight: "400",
    fontSize: "10px",
  },
  formRow: {
    marginBottom: "24px",
  },
  breakdownContainer: {
    margin: "32px 0 40px 0",
  },
  breakdownText: {
    color: "#202020",
    fontWeight: "500",
    fontSize: "16px",
  },
  paper: {
    display: "flex",
    alignItems: "center",
    width: 612,
    background: "#FFFFFF",
    border: "1px solid #DEDEDE",
    borderRadius: "3px",
    maxHeight: "32px",
    marginRight: "8px",
  },
  paper1: {
    display: "flex",
    alignItems: "center",
    width: 75,
    background: "#FFFFFF",
    border: "1px solid #DEDEDE",
    borderRadius: "3px",
    maxHeight: "32px",
    // marginRight: "8px",
  },
  style: {
    p: 4,
    fontSize: "20px",
    margin: "20",
  },

  //for table file

  table: {
    minWidth: 950,
  },

  tableCell1: {
    fontfamily: "Ubuntu",
    fontWeight: "bold!important",
    fontsize: "16px",
    width: "200px",
  },

  tableCell2: {
    fontFamily: "Ubuntu",
    fontWeight: "bold!important",
    fontSize: "14px",
    width: "150px",
  },

  tableCell3: {
    fontFamily: "Ubuntu",
    fontWeight: "bold !important",
    fontSize: "14px",
    width: "150px",
  },

  tableCell4: {
    fontFamily: "Ubuntu",
    fontWeight: "bold!important",
    fontSize: "5px",
    width: "180px",
  },

  tableCell5: {
    verticalAlign: "top",
    fontSize: "1px",
  },

  tableCell6: {
    width: "99px",
    verticalAlign: "top",
    fontSize: "13px",
  },

  tableCell0: {
    backgroundColor: "#B5D0D6",
    height: "61px",
    minWidth: 950,
  },

  tableCell20: {
    marginLeft: "635px",
    padding: "12px 0",
  },

  tableCell22: {
    marginLeft: "600px",
  },

  tableCell23: {
    marginLeft: "600px",
  },

  box: {
    display: "flex",
    alignItems: "center",
  },

  expantableContent: {
    fontSize: "12px",
    padding: "10px",
  },

  headingSubContent: {
    fontWeight: "500",
  },

  breakdownText_amount: {
    marginBottom: "24px",
  },

  plusIcon: {
    color: "white",
  },

  mandatory: {
    color: "red",
  },

  selectDropdown: {
    marginBottom: "24px",
  },

  aasmStateOpen: {
    background: "#7DCEA0", //Green
    borderRadius: "40px",
    padding: "4px 16px 4px 16px",
  },
  aasmStateDisputed: {
    background: "#F1948A", //Red
    borderRadius: "40px",
    padding: "4px 16px 4px 16px",
  },
  aasmStatePaid: {
    background: "#C39BD3", //Purple
    borderRadius: "40px",
    padding: "4px 16px 4px 16px",
  },
  aasmStateClosed: {
    background: "#7DCEA0", //Dark Green
    borderRadius: "40px",
    padding: "4px 16px 4px 16px",
  },
  aasmStateInternal: {
    background: "#F7DC6F", //Yellow
    borderRadius: "40px",
    padding: "4px 16px 4px 16px",
  },
  aasmStateAccepted: {
    background: "#F0B27A", //Orange
    borderRadius: "40px",
    padding: "4px 16px 4px 16px",
  },
  aasmStateCredited: {
    background: "#BB8FCE", //Light Purple
    borderRadius: "40px",
    padding: "4px 16px 4px 16px",
  },
  aasmStateSubmitted: {
    background: "#85C1E9", //Blue
    borderRadius: "40px",
    padding: "4px 16px 4px 16px",
  },
  aasmStateAssigned: {
    background: "#76D7C4", //Green
    borderRadius: "40px",
    padding: "4px 16px 4px 16px",
  },  
  aasmStateUploaded: {
    background: "#7FB3D5", //Gunmetal Blue
    borderRadius: "40px",
    padding: "4px 16px 4px 16px",
  },
  aasmStateBatched: {
    background: "#BFC9CA", //Grey
    borderRadius: "40px",
    padding: "4px 16px 4px 16px",
  },
  aasmStateInvoiced: {
    background: "#C39BD3", //Purple
    borderRadius: "40px",
    padding: "4px 16px 4px 16px",
  },

  internalId: {
    color: "blue",
  },

  editText: {
    color: "#0076B6",
    cursor: "pointer",
    marginLeft: "5px",
    marginTop: "-10px",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "18px",
  },

  flexBox: {
    display: "flex",
  },

  header: {
    margin: "30px 20px 10px 20px",
    fontWeight: "800",
  },

  loader: {
    marginTop: "100px !important",
  },

  contentRow: {
    paddingTop: "10px",
  },

  noteName: {
    fontWeight: "800",
  },

  appliedFilterText: {
    fontSize: "12px",
    fontWeight: "800",
  },
  appliedFilter: {
    background: "linear-gradient(to bottom, #023E8A, #0b354e) !important",
    padding: "2px",
    fontSize: "12px",
    padding: "3px 5px 3px 5px",
    marginTop: "-3px",
    marginRight: "5px",
    color: "white !important",
    display: "flex",
  },
  clearAll: {
    background: "#504e4e",
    padding: "2px",
    fontSize: "12px",
    padding: "3px 5px 3px 5px",
    marginTop: "-3px",
    marginRight: "5px",
    color: "white !important",
    display: "flex",
  },
  appliedFilterTextMain: {
    fontSize: "12px",
    padding: "3px 5px 3px 5px",
    fontSize: "12px",
    marginRight: "10px",
    marginTop: "-3px",
    fontWeight: "800",
    background: "#504e4e",
    color: "white",
  },
}));
