import React, { useState, useEffect } from "react";
import BasicCard from "../../components/InvoiceCard/Card";
import Box from "@mui/material/Box";
import { Grid, Input } from "@mui/material";
import classNames from "classnames";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

import { useQuery, gql } from "@apollo/client";
import {
  FETCH_ALL_CONTRACTS,
  FETCH_ALL_DOCUMENTS,
  FETCH_ALL_INVOICES,
  FETCH_CURRENT_USER,
  FETCH_USER_VENDOR_DETAIL,
  FETCH_VENDOR_WORK_ORDERS,
} from "../../GraphQL/Queries";

// File Uploader
import { FileUploader } from "react-drag-drop-files";

//DateRange
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

//Styles

import useStyles from "./styles";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Button, TextField } from "@material-ui/core";

//Icons and other imports

import Calendar from "../../assets/img/icons/calendar.svg";
import underConstruction from "../../assets/img/construction.png";
import open_invoice from "../../assets/img/icons/open_invoice.svg";
import Closed_Invoice from "../../assets/img/icons/Closed_Invoice.svg";
import FilterIcon from "../../assets/img/icons/filter.svg";
import CardPage from "../../assets/img/menu/CardPage.svg";
import Plus from "../../assets/img/icons/plus.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getContractsData,
  getDocumentsData,
  getInvoicesData,
  getUserVendorDetails,
  getVendorWorkOrderData,
} from "../../redux/actions/invoiceActions";
import Filter from "../../components/Filter/Filter";
import { getCurrentUser } from "../../redux/actions/authActions";
import ComingSoon from "../comingSoon/ComingSoon";
import AddNewUserForm from "./components/forms/addNewUserForm";
import AddNewUserGroupForm from "./components/forms/AddNewUserGroupForm";

// File Types to be uploaded

const fileTypes = ["JPG", "PNG", "GIF"];

export default function Users(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  //States maintained

  const [icon, setIcon] = React.useState(null);
  const [tabsparentState, settabsParentState] = useState("Overall");
  const [openModal, setOpenModal] = React.useState(false);
  const [filterApply, setFilterApply] = React.useState(false);
  const [filterName, setFilterName] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [search, setSearch] = useState();
  const [file, setFile] = useState(null);
  const [status, setStatus] = useState([]);
  const [location, setLocation] = useState([]);
  const [invoiceType, setInvoiceType] = useState([]);
  const [workOrderStatusForFilter, setWorkOrderStatusForFilter] = useState([]);
  const [workOrderLocationsForFilter, setWorkOrderLocationsForFilter] =
    useState([]);
  const [invoicesDataForFilter, setInvoicesStatusForFilter] = useState([]);
  const [invoiceTypeForFilter, setInvoiceTypeForFilter] = useState([]);
  const [state, setState] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  // Use Selector to get the data from store
  const authUserData = useSelector((state) => state?.auth?.authUserData);

  const invoicesData = useSelector(
    (state) => state?.invoices?.getAllInvoicesData?.allInvoices,
  );

  const contractsData = useSelector(
    (state) => state?.invoices?.getAllContractsData?.allContracts,
  );

  const documentsData = useSelector(
    (state) => state?.invoices?.getAllDocumentsData?.allDocuments,
  );

  const vendorInvoiceData = useSelector(
    (state) => state?.invoices?.getVendorInvoiceData?.vendorWorkOrder,
  );

  const token = useSelector((state) => state?.auth?.authUserData?.token);
  const vendorId = useSelector(
    (state) => state?.auth?.authUserData?.user?.vendorId,
  );

  const userId = useSelector((state) => state?.auth?.authUserData?.user?.id);

  const userRole = useSelector(
    (state) => state?.auth?.authUserData?.user?.role,
  );

  // ------------------Queries Maintained ---------------------------//

  // Api call to fetch all Macro invoices
  /* istanbul ignore next */
  const {
    error: allInvoicesError,
    loading: allInvoicesLoading,
    data: allInvoicesData,
  } = useQuery(FETCH_ALL_INVOICES, {
    variables: {
      token,
      startDate: state[0]?.startDate ? state[0]?.startDate : undefined,
      endDate: state[0]?.endDate ? state[0]?.endDate : undefined,
      status: status?.length > 0 ? status : undefined,
    },
    skip:
      tabsparentState != "End Customer Invoices" &&
      tabsparentState != "Overall",
  });
  console.log(allInvoicesData, "invoicesData");

  // Api call to fetch all Documents
  const {
    error: allDocumentsError,
    loading: allDocumentsLoading,
    data: allDocumentsData,
    refetch: refetchDocument,
  } = useQuery(FETCH_ALL_DOCUMENTS, {
    variables: { token },
  });

  // Api call to fetch all Contracts
  const {
    error: allContracts,
    loading: allContractsLoading,
    data: allContractsData,
  } = useQuery(FETCH_ALL_CONTRACTS, {
    variables: { token },
  });

  // Api call to fetch current user
  const {
    error: currentUserError,
    loading: currentUserLoading,
    data: user,
  } = useQuery(FETCH_CURRENT_USER, {
    variables: {
      token: token,
    },
  });

  // Api call to fetch  vendor detail
  const {
    error: userVendorError,
    loading: userVendorLoading,
    data: userVendorDetail,
  } = useQuery(FETCH_USER_VENDOR_DETAIL, {
    variables: {
      token: token,
      id: user?.currentUser?.vendorId,
    },
    skip: !user,
  });

  // Api call to fetch  vendor work orders
  /* istanbul ignore next */
  const {
    error: vendorWorkOrdersErrors,
    loading: vendorWorkOrdersLoading,
    data: vendorWorkOrders,
    refetch,
  } = useQuery(FETCH_VENDOR_WORK_ORDERS, {
    variables: {
      token,
      id: userRole === "vendor" ? vendorId : vendorId,
      startDate: state[0]?.startDate ? state[0]?.startDate : undefined,
      endDate: state[0]?.endDate ? state[0]?.endDate : undefined,
      status: filterApply && status?.length > 0 ? status : undefined,
      city: filterApply && location?.length > 0 ? location : undefined,
      query: search ? search : undefined,
      invoiceType:
        filterApply && invoiceType?.length > 0 ? invoiceType : undefined,
    },
    skip:
      tabsparentState != "Vendor Invoices" &&
      tabsparentState != "Overall" &&
      !userRole,
  });

  // Renderers Maintained

  // Rendering to save all the filters in a state
  /* istanbul ignore next */
  useEffect(() => {
    setWorkOrderStatusForFilter([
      ...new Set(vendorInvoiceData?.map((item) => item?.aasmState)),
    ]);
    setWorkOrderLocationsForFilter([
      ...new Set(vendorInvoiceData?.map((item) => item?.customer?.city)),
    ]);
    setInvoiceTypeForFilter([
      ...new Set(vendorInvoiceData?.map((item) => item?.invoiceType)),
    ]);

    // setInvoicesStatusForFilter([
    //   ...new Set(invoicesData?.map((item) => item?.aasmState)),
    // ]);
  }, [vendorInvoiceData]);

  useEffect(() => {
    setStatus([]);
    setLocation([]);
    setInvoiceType([]);
    setState([
      {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    ]);
  }, [tabsparentState]);

  useEffect(() => {
    if (user) {
      dispatch(getCurrentUser(user, token, "!navigate"));
    }
  }, [user]);

  // use effect to grab and update all data from redux as soon as the api is called
  useEffect(() => {
    dispatch(getInvoicesData(allInvoicesData));
    dispatch(getDocumentsData(allDocumentsData));
    dispatch(getContractsData(allContractsData));
    dispatch(getVendorWorkOrderData(vendorWorkOrders));
    dispatch(getUserVendorDetails(userVendorDetail));
  }, [
    allInvoicesData,
    allContractsData,
    allDocumentsData,
    vendorWorkOrders,
    userVendorDetail,
  ]);

  // Functions Maintained

  // apply filter function
  /* istanbul ignore next */
  const onFilterApply = () => {
    setFilterApply(true);
    setOpenModal(false);
  };

  // tabs change
  /* istanbul ignore next */
  function handleChildChange(childState) {
    settabsParentState(childState);
  }

  //set file
  const handleChange = (file) => {
    setFile(file);
  };

  // handling popover
  /* istanbul ignore next */
  const handleClick = (event, type) => {
    setIcon(type);
    setAnchorEl(event.currentTarget);
  };

  //open Filter Modal
  /* istanbul ignore next */
  const handleFilterClick = (event, type) => {
    setOpenModal(true);
    setIcon(type);
    onReset();
    // setAnchorEl(event.currentTarget);
  };

  // Reset the state
  /* istanbul ignore next */
  const onReset = () => {
    setStatus([]);
    setInvoiceType([]);
    setLocation([]);
    setFilterApply(false);
  };

  // set anchor tag null
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  //open PopOver Display
  const PopoverDisplay = () => {
    return (
      <div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 150, left: 850 }}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {/* Calendar Popover */}
          {icon === "calendar" && (
            <DateRangePicker
              className="datePicker"
              onChange={(item) => setState([item.selection])}
              showSelectionPreview={false}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={state}
              direction="horizontal"
            />
          )}
          {/* Upload Popover */}
          {icon === "upload" && (
            <>
              <div className={classNames(classes.modalContainer)}>
                <div className={classNames(classes.headerContainer)}>
                  <div className={classNames(classes.modalHeader)}>
                    Add Document
                  </div>
                </div>

                <div className={classNames(classes.headerContainer)}>
                  <Box sx={{ margin: "24px 0 12px 0" }}>
                    <div>
                      <label>Title</label>
                    </div>
                    <Input className={classes.textField} />
                  </Box>
                  <Box
                    sx={{
                      margin: "24px 0 12px 0",
                    }}
                  >
                    <div>
                      <label>Attach Document</label>
                    </div>
                    <Box className={"fileUploader"}>
                      <FileUploader
                        handleChange={handleChange}
                        name="file"
                        types={fileTypes}
                      />
                      <div>
                        <label className={classes.fileTypeText}>
                          Accepted File Types: .PDF and CSV only
                        </label>
                      </div>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      margin: "24px 0 24px 0",
                      display: "flex",
                      flexDirection: "row-reverse",
                    }}
                  >
                    <button className="upload_button">Upload</button>
                    <button className="cancel_button">Cancel</button>
                  </Box>
                </div>
              </div>
            </>
          )}
          {/* Create New User popover */}
          {icon === "user" && <AddNewUserForm closeModal={handleClose} />}
          {icon === "userGroup" && (
            <AddNewUserGroupForm closeModal={handleClose} />
          )}
        </Popover>
      </div>
    );
  };

  return (
    <>
      <div>
        <Box
          sx={{
            display: "flex",
            borderRadius: 1,
            alignItems: "center",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <PageTitle breadcrumb={false} title={"Users"} subTitle={"dwqdwq"} />
          </Box>
          {tabsparentState != "Overall" && (
            <>
              <Box>
                <Box className={classes.paper}>
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search here by customer’s name, vendor’s invoice or location. "
                    text="Search"
                  />
                </Box>
              </Box>
              <Box className={classNames(classes.buttonContainer)}>
                <Button
                  onClick={(e) => handleClick(e, "calendar")}
                  className={classNames(classes.button)}
                >
                  <img src={Calendar}></img>
                </Button>
                <Button
                  onClick={(e) => handleFilterClick(e, "filter")}
                  className={classNames(classes.button)}
                >
                  <img src={FilterIcon}></img>
                </Button>
              </Box>
            </>
          )}{" "}
          <Box className={classNames(classes.buttonContainer)}>
            <Button
              onClick={(e) => handleClick(e, "user")}
              className={classNames(classes.button)}
              data-testId="Ad-btn"
            >
              <img src={Plus}></img> &nbsp;&nbsp;Add User
            </Button>
          </Box>{" "}
          <Box className={classNames(classes.buttonContainer)}>
            <Button
              onClick={(e) => handleClick(e, "userGroup")}
              className={classNames(classes.button)}
              data-testId="Ad-grp-btn"
            >
              <img src={Plus}></img> &nbsp;&nbsp;Add User Group
            </Button>
          </Box>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          //   bgcolor={"white"}
          mt={"100px"}
        >
          <img src={underConstruction}></img>
        </Box>
      </div>
      {PopoverDisplay()}
    </>
  );
}
