import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import classNames from "classnames";

//style
import useStyles from "./style";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  width: "292px",
  backgroundColor: "white",
  // border: '2px solid #000',
  boxShadow: 24,
  // p: 1.95,
};

export default function Filter({
  open,
  onClose,
  status,
  setStatus,
  tabsparentState,
  workOrderStatusForFilter,
  invoicesDataForFilter,
  workOrderLocationsForFilter,
  invoiceTypeForFilter,
  location,
  setLocation,
  invoiceType,
  setInvoiceType,
  onFilterApply,
  setFilterApply,
  onReset,
}) {
  const classes = useStyles();
  const [filterName, setFilterName] = useState("Status");

  const statusMap = [
    "disputed",
    "internal",
    "paid",
    "closed",
    "submitted",
    "assigned",
    "uploaded",
    "invoiced",
    "open",
    "credited",
  ];


  /* istanbul ignore next */
  const handleChange = (event, type) => {
    const { name, checked } = event.target;

    if (type === "status") {
      if (checked) {
        setStatus([...status, name]);
      } else {
        setStatus(status.filter((s) => s !== name));
      }
    } else if (type === "location") {
      if (checked) {
        setLocation([...location, name]);
      } else {
        setLocation(location.filter((s) => s !== name));
      }
    } else if (type === "invoiceType") {
      if (checked) {
        setInvoiceType([...invoiceType, name]);
      } else {
        setInvoiceType(invoiceType.filter((s) => s !== name));
      }
    }
  };
  /* istanbul ignore next */
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box container spacing={1} className={classes.heading}>
          <div className={classNames(classes.headingContainer)}>Filters</div>
        </Box>
        {tabsparentState === "End Customer Invoices" && (
          <Box>
            <Grid
              container
              spacing={2}
              className={classNames(classes.filterRow)}
            >
              <Grid item xs={4} className={classNames(classes.box1)}>
                <div
                  onClick={() => setFilterName("Status")}
                  item
                  xs={12}
                  className={classNames(classes.div1)}
                >
                  Status
                </div>
              </Grid>

              <Grid item xs={8}>
                {statusMap &&
                  statusMap?.map((statusLabel) => {
                    return (
                      <div item xs={12}>
                        <input
                          onChange={(e) => handleChange(e, "status")}
                          type="checkbox"
                          name={statusLabel}
                          checked={status.includes(statusLabel)}
                        />
                        <label>{statusLabel?.toUpperCase()}</label>
                      </div>
                    );
                  })}
              </Grid>
            </Grid>
          </Box>
        )}

        {tabsparentState === "Vendor Invoices" && (
          <Box>
            <Grid
              container
              spacing={2}
              className={classNames(classes.filterRow)}
            >
              <Grid item xs={5} className={classNames(classes.box1)}>
                <div
                  onClick={() => setFilterName("Status")}
                  item
                  xs={12}
                  className={classNames(
                    classes.div1,
                    filterName === "Status" && "active",
                  )}
                >
                  Status
                </div>
                {workOrderLocationsForFilter &&
                  workOrderLocationsForFilter.length > 0 && (
                    <div
                      onClick={() => setFilterName("Location")}
                      item
                      xs={12}
                      className={classNames(
                        classes.div1,
                        filterName === "Location" && "active",
                      )}
                    >
                      Location
                    </div>
                  )}
                {invoiceTypeForFilter && invoiceTypeForFilter.length > 0 && (
                  <div
                    onClick={() => setFilterName("Invoice Type")}
                    item
                    xs={12}
                    className={classNames(
                      classes.div1,
                      filterName === "Invoice Type" && "active",
                    )}
                  >
                    Invoice Type
                  </div>
                )}
              </Grid>

              <Grid item xs={7}>
                {filterName === "Status" &&
                  statusMap &&
                  statusMap?.map((statusLabel) => {
                    return (
                      <div item xs={12}>
                        <input
                          onChange={(e) => handleChange(e, "status")}
                          type="checkbox"
                          name={statusLabel}
                          checked={status.includes(statusLabel)}
                        />
                        <label>{statusLabel?.toUpperCase()}</label>
                      </div>
                    );
                  })}
                {filterName === "Location" &&
                  workOrderLocationsForFilter &&
                  workOrderLocationsForFilter?.map((locationLabel) => {
                    return (
                      <div item xs={12}>
                        <input
                          onChange={(e) => handleChange(e, "location")}
                          type="checkbox"
                          name={locationLabel}
                          checked={location.includes(locationLabel)}
                        />
                        <label>{locationLabel?.toUpperCase()}</label>
                      </div>
                    );
                  })}
                {filterName === "Invoice Type" &&
                  invoiceTypeForFilter &&
                  invoiceTypeForFilter?.map((it) => {
                    return (
                      <div item xs={12}>
                        <input
                          onChange={(e) => handleChange(e, "invoiceType")}
                          type="checkbox"
                          name={it}
                          checked={invoiceType.includes(it)}
                        />
                        <label>{it?.toUpperCase()}</label>
                      </div>
                    );
                  })}
              </Grid>
            </Grid>
          </Box>
        )}

        <Box className={classNames(classes.box2)}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className={classNames(classes.buttonContainer)}>
                <Button
                  variant="outlined"
                  className={classNames(classes.button1)}
                  onClick={() => onReset()}
                >
                  Reset
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  variant="contained"
                  // className={classNames(classes.button2)}
                  onClick={() => onFilterApply()}
                >
                  Apply
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
}
