import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  // for modal file

  style: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "832px",
    height: "424px",
    padding: "32px",
    boxShadow: 24,
    backgroundColor: "white",
    p: 4,
    overflowY: "scroll",
  },
  heading: {
    fontStyle: "normal",
    fontSize: "20px",
    fontFamily: "ubuntu",
    fontWeight: "bold",
    display: "flex",
  },

  //for table file

  tableContainer: {
    borderRadius: "0",
  },

  table: {
    minWidth: 650,
  },
  tableRow:{
    padding: "14px",
  },


  tableCell1: {
    fontFamily: "Ubuntu",
    fontWeight: "bold",
    fontSize: "14px",
    width: "140px",
  },

  tableCell2: {
    fontFamily: "Ubuntu",
    fontWeight: "bold",
    fontSize: "14px",
    width: "170px",
  },

  tableCell3: {
    fontFamily: "Ubuntu",
    fontWeight: "bold",
    fontSize: "14px",
    width: "100px",
  },

  tableCell4: {
    fontFamily: "Ubuntu",
    fontWeight: "bold",
    fontSize: "14px",
    width: "300px",
  },

  tableCell5: {
    verticalAlign: "top",
    fontSize: "13px",
  },

  tableCell6: {
    width: "99px",
    verticalAlign: "top",
    fontSize: "13px",
  },
  box: {
    display: "flex",
    alignItems: "center",
  },
}));
