import React, { useCallback, useState } from "react";
import Select, { components } from "react-select";
import classNames from "classnames";
import Box from "@mui/material/Box";
import { useForm, Controller } from "react-hook-form";
import { Grid, Input } from "@mui/material";

//Styles

import useStyles from "../../styles";

//Icons

import Plus from "../../../../assets/img/icons/plus.svg";
import WhitePlus from "../../../../assets/img/icons/whitePlus.svg";
import {
  FETCH_ALL_CUSTOMERS,
  FETCH_CUSTOMER_RANGE_WORK_ORDER,
  FILTER_MAINTENANCE_RECORDS,
} from "../../../../GraphQL/Queries";
import { useMutation, useQuery } from "@apollo/client";
import { getAllCustomers } from "../../../../redux/actions/customerActions";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomerMaintenanceRecords,
  getCustomerWorkOrders,
} from "../../../../redux/actions/invoiceActions";
import { useEffect } from "react";
import { ColorRing } from "react-loader-spinner";
import { ADD_INVOICE } from "../../../../GraphQL/Mutations";
import Loader from "../../../../components/Loader/Loader";

// File types initialization
const fileTypes = ["JPG", "PNG", "GIF"];

const AddInvoiceForm = ({ closeModal }) => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const classes = useStyles();
  const dispatch = useDispatch();

  // States maintained
  const [customerId, setCustomerId] = useState(null);
  const [file, setFile] = useState(null);
  const [stateChangesComplete, setStateChangesComplete] = useState(false);

  // Use Selectors Maintained
  const token = useSelector((state) => state?.auth?.authUserData?.token);
  const allCustomers = useSelector(
    (state) => state?.customers?.getAllCustomers?.invoiceCustomers,
  );

  const customerWorkOrders = useSelector(
    (state) => state?.invoices?.getCustomerWorkOrders?.customerRangeWorkOrder,
  );
  const customerMaintenanceRecords = useSelector(
    (state) =>
      state?.invoices?.getCustomerMaintenanceRecords?.filterMaintenanceRecord,
  );

  // -------------------UseQueries/Mutations handled----------------------//

  //Api call to fetch all customers
  const {
    error: customersError,
    loading: customersLoading,
    data: customers,
  } = useQuery(FETCH_ALL_CUSTOMERS, {
    variables: { token },
  });

  // Api call to fetch work orders in a particular range and customer id
  const {
    error: customerRangeWorkOrdersError,
    loading: customerRangeWorkOrdersLoading,
    data: customerRangeWorkOrders,
  } = useQuery(FETCH_CUSTOMER_RANGE_WORK_ORDER, {
    variables: {
      token,
      id: customerId,
      startRange: watch("startDate"),
      endRange: watch("endDate"),
    },
    skip: !stateChangesComplete,
  });

  // Grab maintainance records based on customer id and date range
  const {
    error: customerRangeMaintenanceRecordError,
    loading: customerRangeMaintenanceRecordLoading,
    data: customerRangeMaintenanceRecords,
  } = useQuery(FILTER_MAINTENANCE_RECORDS, {
    variables: {
      token,
      id: customerId,
      startRange: watch("startDate"),
      endRange: watch("endDate"),
    },
    skip: !stateChangesComplete,
  });

  // Api to add an invoice
  /* istanbul ignore next */
  const onAddInvoice = useCallback(({ addInvoice: result } = {}) => {
    const { user, token, errors = [] } = result || {};
    if (!errors) {
      closeModal();
    }
  }, []);

  const [addInvoice] = useMutation(ADD_INVOICE, {
    onCompleted: onAddInvoice,
  });

  // Submit to call the add invoice api
  /* istanbul ignore next */
  const onSubmit = (data) => {
    console.log(data, "dadadadada");
    addInvoice({
      variables: {
        token: token,
        customerId: data?.CustomerSelect?.value,
        workOrderIds: data?.associateWorkOrders?.map(
          (workOrders) => workOrders.value,
        ),
        maintenaceRecordIds: data?.maintenanceContracts?.map(
          (contracts) => contracts.value,
        ),
        startRange: data?.startDate,
        endRange: data?.endDate,
        comment: data?.comments,
      },
    });
  };

  // Renders Maintained
  /* istanbul ignore next */
  useEffect(() => {
    if (watch("CustomerSelect") && watch("startDate") && watch("endDate")) {
      setStateChangesComplete(true);
    }
  }, [watch("CustomerSelect"), watch("startDate"), watch("endDate")]);

  useEffect(() => {
    const customerId = watch("CustomerSelect");
    setCustomerId(customerId?.value);
  }, [watch("CustomerSelect")]);

  React.useEffect(() => {
    dispatch(getCustomerWorkOrders(customerRangeWorkOrders));
  }, [customerRangeWorkOrders]);

  React.useEffect(() => {
    dispatch(getAllCustomers(customers));
  }, [customers]);

  React.useEffect(() => {
    dispatch(getCustomerMaintenanceRecords(customerRangeMaintenanceRecords));
  }, [customerRangeMaintenanceRecords]);

  // Dropdown icon
  const DropdownIndicator = (props) => {
    return (
      <div>
        <components.DropdownIndicator {...props}>
          <img className={classNames(classes.plusIcon)} src={WhitePlus}></img>
        </components.DropdownIndicator>
      </div>
    );
  };
  /* istanbul ignore next */
  return (
    <>
      <div className={classNames(classes.addModalContainer)}>
        <div className={classNames(classes.headerContainer)}>
          <div className={classNames(classes.modalHeader)}>Invoices</div>
        </div>
        {customerRangeMaintenanceRecordLoading ||
          customerRangeWorkOrdersLoading ||
          customersLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Loader />

            <span>Loading...</span>
          </Box>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classNames(classes.headerContainer)}>
              <Box sx={{ margin: "24px 0 12px 0" }}>
                <Grid
                  className={classNames(classes.formRow)}
                  container
                  spacing={2}
                >
                  <Grid item xs={12} md={12}>
                    <div>
                      <label htmlFor="Customer">
                        Customer{" "}
                        <span className={classNames(classes.mandatory)}>*</span>
                      </label>
                    </div>
                    <Controller
                      name="CustomerSelect"
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <Select
                          id="Customer"
                          {...field}
                          closeMenuOnSelect={true}
                          components={{ DropdownIndicator }}
                          className="react_select"
                          options={
                            allCustomers &&
                            allCustomers?.map((customer) => {
                              return {
                                label: customer?.name,
                                value: customer?.id,
                              };
                            })
                          }
                        // options={colourOptions}

                        // options={colourOptions}
                        />
                      )}
                    />
                    {errors.CustomerSelect &&
                      errors.CustomerSelect.type === "required" && (
                        <span className={classNames(classes.mandatory)}>
                          This field is required
                        </span>
                      )}
                  </Grid>
                </Grid>

                <Grid
                  className={classNames(classes.formRow)}
                  container
                  spacing={2}
                >
                  <Grid item xs={6} md={6}>
                    <div>
                      <label htmlFor="StartDate">
                        Start Date
                        <span className={classNames(classes.mandatory)}>*</span>
                      </label>
                    </div>

                    <Input
                      id="StartDate"
                      type={"date"}
                      fullWidth
                      className={classes.textField}
                      keyboardIcon={<img src={Plus}>a</img>}
                      {...register("startDate", { required: true })}
                    />
                    {errors.startDate && (
                      <span className={classNames(classes.mandatory)}>
                        {" "}
                        This field is required
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <div>
                      <label htmlFor="EndDate">
                        End Date
                        <span className={classNames(classes.mandatory)}>*</span>
                      </label>
                    </div>

                    <Input
                      id="EndDate"
                      type={"date"}
                      fullWidth
                      className={classes.textField}
                      {...register("endDate", { required: true })}
                    />
                    {errors.endDate && (
                      <span className={classNames(classes.mandatory)}>
                        {" "}
                        This field is required
                      </span>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  className={classNames(classes.formRow)}
                  container
                  spacing={2}
                >
                  <Grid item xs={12} md={12}>
                    <div>
                      <label htmlFor="Associated">Associated Workorders</label>
                    </div>
                    <Controller
                      name="associateWorkOrders"
                      control={control}
                      render={({ field }) => (
                        <Select
                          id="Associated"
                          {...field}
                          options={
                            customerWorkOrders &&
                            customerWorkOrders?.map((workOrder) => {
                              return {
                                label: workOrder?.internalId,
                                value: workOrder?.id,
                              };
                            })
                          }
                          // options={colourOptions}
                          className="react_select"
                          isMulti
                          closeMenuOnSelect={false}
                          // hideSelectedOptions={false}
                          components={{ DropdownIndicator }}
                        // components={{ Option, MultiValue, DropdownIndicator }}
                        // allowSelectAll={true}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div>
                      <label htmlFor="Maintenance">Maintenance Contracts</label>
                    </div>
                    <Controller
                      name="maintenanceContracts"
                      control={control}
                      render={({ field }) => (
                        <Select
                          id=""
                          {...field}
                          options={
                            customerMaintenanceRecords &&
                            customerMaintenanceRecords.map(
                              (maintenanceRecord) => {
                                return {
                                  label: maintenanceRecord?.serialNumber,
                                  value: maintenanceRecord?.id,
                                };
                              },
                            )
                          }
                          // options={colourOptions}
                          className="react_select"
                          isMulti
                          closeMenuOnSelect={false}
                          // hideSelectedOptions={false}
                          // components={{ Option, MultiValue, DropdownIndicator }}
                          components={{ DropdownIndicator }}
                        // allowSelectAll={true}
                        />
                      )}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={12}>
                    <div>
                      <label>
                        Sales Invoice
                        <span className={classNames(classes.mandatory)}>*</span>
                      </label>
                    </div>
                    <Controller
                      name="salesInvoice"
                      control={control}
                      render={({ field }) => (
                        <DropdownSelect
                          {...field}
                          options={colourOptions}
                          isMulti
                          className="react_select"
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{ Option, MultiValue, DropdownIndicator }}
                          allowSelectAll={true}
                        />
                      )}
                    />
                  </Grid> */}
                </Grid>

                <Grid
                  className={classNames(classes.formRow)}
                  container
                  spacing={2}
                >
                  <Grid item xs={12} md={12}>
                    <div>
                      <label htmlFor="Comments">Comments</label>
                    </div>
                    <Controller
                      name="comments"
                      control={control}
                      render={({ field }) => (
                        <Input
                          id="Commnets"
                          {...register("comments", { required: false })}
                          fullWidth
                          className={classes.textField}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box
                sx={{
                  margin: "24px 0 24px 0",
                  display: "flex",
                  flexDirection: "row-reverse",
                }}
              >
                <button className="upload_button" type="submit">
                  Save
                </button>
                <button className="cancel_button">Reset</button>
              </Box>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default AddInvoiceForm;
