import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { Box } from "@material-ui/core";
import classNames from "classnames";

//style
import useStyles from "../styles";

function createData(
  Date,
  DocumentNo,
  Location,
  Site,
  Lines,
  Subtotal,
  Tax,
  Total,
  Remaining,
  DueDate,
) {
  return {
    Date,
    DocumentNo,
    Location,
    Site,
    Lines,
    Subtotal,
    Tax,
    Total,
    Remaining,
    DueDate,
  };
}

const rows = [
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "Jacksonville",
    "1-Qty:1,0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "Merritt",
    "1-Qty:1,0",
    "70,979.00",
    "4308.74",
    "75,287.74",
    "75,287.74",
    "11/04/2022",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "Orlando",
    "1-Qty:1,0",
    "38,678.00",
    "2,345.68",
    "45,655.00",
    "41,023.68",
    "11/04/2022",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "-",
    "1-Qty:1,0",
    "70,979.00",
    "0",
    "70,979.00",
    "70,979.00",
    "11/04/2022",
  ),
];

export default function Sales() {
  const classes = useStyles();
  return (
    <TableContainer className={classNames(classes.tableContainer)}>
      <Table className={classNames(classes.table)} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left" className={classNames(classes.tableCell1)}>
              Date
            </TableCell>
            <TableCell align="left" className={classNames(classes.tableCell2)}>
              DocumentNo
            </TableCell>
            <TableCell align="left" className={classNames(classes.tableCell3)}>
              Location
            </TableCell>
            <TableCell align="left" className={classNames(classes.tableCell4)}>
              Site
            </TableCell>
            <TableCell align="left" className={classNames(classes.tableCell1)}>
              Lines
            </TableCell>
            <TableCell align="left" className={classNames(classes.tableCell2)}>
              Subtotal
            </TableCell>
            <TableCell align="left" className={classNames(classes.tableCell3)}>
              Tax
            </TableCell>
            <TableCell align="left" className={classNames(classes.tableCell4)}>
              Total
            </TableCell>
            <TableCell align="left" className={classNames(classes.tableCell3)}>
              Remaining
            </TableCell>
            <TableCell align="left" className={classNames(classes.tableCell4)}>
              DueDate
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.Date}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                align="left"
                className={classNames(classes.tableCell5)}
              >
                {row.Date}
              </TableCell>
              <TableCell
                align="left"
                className={classNames(classes.tableCell6)}
              >
                {row.DocumentNo}
              </TableCell>
              <TableCell
                align="left"
                className={classNames(classes.tableCell6)}
              >
                {row.Location}
              </TableCell>
              <TableCell
                align="left"
                className={classNames(classes.tableCell6)}
              >
                {row.Site}
              </TableCell>
              <TableCell className={classNames(classes.tableCell5)}>
                {row.Lines}
              </TableCell>
              <TableCell
                align="left"
                className={classNames(classes.tableCell6)}
              >
                {row.Subtotal}
              </TableCell>
              <TableCell
                align="left"
                className={classNames(classes.tableCell6)}
              >
                {row.Tax}
              </TableCell>
              <TableCell
                align="left"
                className={classNames(classes.tableCell6)}
              >
                {row.Total}
              </TableCell>
              <TableCell
                align="left"
                className={classNames(classes.tableCell6)}
              >
                {row.Remaining}
              </TableCell>
              <TableCell
                align="left"
                className={classNames(classes.tableCell6)}
              >
                {row.DueDate}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classNames(classes.tableCell0)}>
        <div className={classNames(classes.tableCell20)}>
          Total
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          232,945.42
        </div>
      </div>
    </TableContainer>
  );
}
