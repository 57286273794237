import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "react-select";
import classNames from "classnames";
import useStyles from "./styles";
import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  FETCH_ALL_WORK_CATEGORIES,
  FETCH_ALL_WORK_SYSTEMS,
} from "../../../GraphQL/Queries";
import { CREATE_LINE_ITEM } from "../../../GraphQL/Mutations";
import { toast } from "react-toastify";

export default function AddlineItemsmodel({
  show,
  onHide,
  workSystems,
  workCategories,
  workOrderId,
  token,
  refetchWorkOrder,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState({});
  const [errorsValue, setErrorsValue] = useState(false);

  const productGroup = [
    { label: "PARTS", value: "PARTS" },
    { label: "LABOR", value: "LABOR" },
    { label: "TAXES", value: "TAXES" },
    { label: "OTHER", value: "OTHER" },
  ];
  /* istanbul ignore next */
  const WorkSystemData = workSystems?.allWorkSystems?.map((d) => ({
    label: d?.value,
    value: d?.id,
  }));
  /* istanbul ignore next */
  const WorkCategoryData = workCategories?.allWorkCategories?.map((d) => ({
    label: d?.value,
    value: d?.id,
  }));

  /* istanbul ignore next */
  const handleSellerForm = (item, name) => {
    setFormData({ ...formData, [name]: item });
  };

  /* istanbul ignore next */
  const [createLineItem, { loading }] = useMutation(CREATE_LINE_ITEM, {
    onCompleted: (data) => {
      toast.success("Work Order updated succesfully!");
      refetchWorkOrder();
      onHide();
    },
    onError: (error) => {
      toast.error("Unable to update Work Order!");
    },
  });
  /* istanbul ignore next */
  React.useEffect(() => {
    if (formData?.unitPrice || formData?.quantity) {
      setFormData({
        ...formData,
        Amount: formData?.unitPrice * formData?.Quantity,
      });
    }
  }, [formData?.unitPrice, formData?.Quantity]);

  const handleCancel = () => {
    setFormData({});
    setErrorsValue(false);
    onHide();
  };

  /* istanbul ignore next */
  const onLineItemSubmit = () => {
    if (
      !formData?.Description ||
      !formData?.Quantity ||
      !formData?.unitPrice ||
      !formData?.Amount
    ) {
      setErrorsValue(true);
    } else {
      createLineItem({
        variables: {
          token,
          workOrderId: workOrderId,
          productGroup: formData?.productGroup?.value,
          description: formData?.Description,
          quantity: parseFloat(formData?.Quantity),
          unitPrice: parseFloat(formData?.unitPrice),
          tax: parseFloat(formData?.tax),
          amount:
            formData?.productGroup?.value === "TAXES"
              ? 0
              : parseFloat(formData?.Amount),
          workSystemId: parseInt(formData?.workSystem?.value),
          workCategoryId: parseInt(formData?.workCategory?.value),
          internalId: formData?.internalId,
        },
      });
      onHide();
      setFormData({});
      setErrorsValue(false);
    }
  };

  /* istanbul ignore next */
  return (
    <div>
      <Dialog
        open={show}
        onClose={onHide}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ADD LINE ITEMS</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="row modal_input">
              <div className="col-4 formLabel">Product Group</div>
              <div className="col-8">
                <div class={"select_react"}>
                  <Select
                    options={productGroup}
                    onChange={(value) =>
                      handleSellerForm(value, "productGroup")
                    }
                  //   value={formData.existingRateCard}
                  />
                </div>
                {/* <p className="error dialogError">{errors.existingRateCard}</p> */}
              </div>
            </div>{" "}
            <div className="row modal_input">
              <div className={classNames(classes.InputLabel)}>Work System</div>
              <div className="col-8">
                <div class={"select_react"}>
                  <Select
                    options={WorkSystemData}
                    onChange={(value) => handleSellerForm(value, "workSystem")}
                  //   value={formData.existingRateCard}
                  />
                </div>
                {/* <p className="error dialogError">{errors.existingRateCard}</p> */}
              </div>
            </div>
            <div className="row modal_input">
              <div className={classNames(classes.InputLabel)}>
                Work Category
              </div>
              <div className="col-8">
                <div class={"select_react"}>
                  <Select
                    options={WorkCategoryData}
                    onChange={(value) =>
                      handleSellerForm(value, "workCategory")
                    }
                  //   value={formData.existingRateCard}
                  />
                </div>
                {/* <p className="error dialogError">{errors.existingRateCard}</p> */}
              </div>
            </div>
            <div className="row modal_input">
              <div className={classNames(classes.InputLabel)}>Internal ID</div>

              <div className="col-8">
                <input
                  onChange={(e) =>
                    handleSellerForm(e.target.value, "internalId")
                  }
                  // value={formData.margin}
                  className={classNames(classes.Input)}
                  type="text"
                // maxLength={7}
                // onBlur={handleMarginVal}
                />
                {/* <p className="error dialogError">{errors.margin}</p> */}
              </div>
            </div>
            <div className="row modal_input">
              <div className={classNames(classes.InputLabel)} htmlFor="Description">Description</div>

              <div className="col-8">
                <input
                  id="Description"
                  onChange={(e) =>
                    handleSellerForm(e.target.value, "Description")
                  }
                  // value={formData.margin}
                  className={classNames(classes.Input)}
                  type="text"
                // maxLength={7}
                // onBlur={handleMarginVal}
                />
                {!formData?.Description && errorsValue && (
                  <span style={{ color: "red" }}> This field is required</span>
                )}
              </div>
            </div>
            <div className="row modal_input">
              <div className={classNames(classes.InputLabel)}>Quantity</div>

              <div className="col-8">
                <input
                  onChange={(e) => handleSellerForm(e.target.value, "Quantity")}
                  // value={formData.margin}
                  className={classNames(classes.Input)}
                  type="number"
                // maxLength={7}
                // onBlur={handleMarginVal}
                />
                {!formData?.Quantity && errorsValue && (
                  <span style={{ color: "red" }}> This field is required</span>
                )}
              </div>
            </div>
            <div className="row modal_input">
              <div className={classNames(classes.InputLabel)}>
                Unit Price ($)
              </div>

              <div className="col-8">
                <input
                  onChange={(e) =>
                    handleSellerForm(e.target.value, "unitPrice")
                  }
                  // value={formData.margin}
                  className={classNames(classes.Input)}
                  type="number"
                // maxLength={7}
                // onBlur={handleMarginVal}
                />
                {!formData?.unitPrice && errorsValue && (
                  <span style={{ color: "red" }}> This field is required</span>
                )}
              </div>
            </div>
            <div className="row modal_input">
              <div className={classNames(classes.InputLabel)}>Tax ($)</div>

              <div className="col-8">
                <input
                  onChange={(e) => handleSellerForm(e.target.value, "tax")}
                  // value={formData.margin}
                  className={classNames(classes.Input)}
                  type="number"
                // maxLength={7}
                // onBlur={handleMarginVal}
                />
                {/* <p className="error dialogError">{errors.margin}</p> */}
              </div>
            </div>
            <div className="row modal_input">
              <div className={classNames(classes.InputLabel)}>Amount ($)</div>

              <div className="col-8">
                <input
                  className={classNames(classes.Input)}
                  type="number"
                  value={formData?.Amount}
                  disabled
                />
                {!formData?.Amount && errorsValue && (
                  <span style={{ color: "red" }}> This field is required</span>
                )}
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classNames(classes.cancelButton)}
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
          <Button
            className={classNames(classes.SaveButton)}
            onClick={() => onLineItemSubmit()}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
