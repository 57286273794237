import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import classNames from "classnames";
import Box from "@mui/material/Box";
import { useForm, Controller } from "react-hook-form";
import { Grid, Input } from "@mui/material";

//Styles

import useStyles from "../../styles";

//Icons

import Plus from "../../../../assets/img/icons/plus.svg";
import WhitePlus from "../../../../assets/img/icons/whitePlus.svg";
import { useMutation, useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import {
  CREATE_DOCUMENT,
  UPDATE_DOCUMENT,
} from "../../../../GraphQL/Mutations";
import { toast } from "react-toastify";
import {
  FETCH_ALL_DOCUMENTS,
  FETCH_DOCUMENT,
} from "../../../../GraphQL/Queries";
import moment from "moment";

// All options for user Types
const userTypesOptions = [
  {
    label: "Admin",
    value: "admin",
  },
  {
    label: "External Customer",
    value: "external customer",
  },
  {
    label: "Fleet Director",
    value: "fleet director",
  },
  {
    label: "Service Manager",
    value: "service manager",
  },
  {
    label: "Vendor",
    value: "vendor",
  },
];

const AddDocumentForm = ({
  closeModal,
  refetchDocument,
  documentData,
  edit,
}) => {
  // React hook form initialization
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const classes = useStyles();
  const dispatch = useDispatch();

  // States maintained
  const [document, setDocument] = useState(
    "TDW is a clearinghouse that provides invoice consolidation and fleet management services to end customers. By accepting, you agree to provide maintenance services for the end customer using TDW, acknowledge that the end customer is responsible for timely invoice payment, and agree to hold TDW harmless for the end customer's delays in payment. TDW may provide advice and guidance for repairs but is not liable for any accident or injury at an end customer location and cannot be made party to a lawsuit stemming from such incidents. The vendor will hold TDW harmless and maintains the duty to notify end customer local management of any necessary safety-related repairs and provide recommended service intervals.",
  );

  const [currentDoc, setCurrentDoc] = useState({});

  // Use selectors initialized
  const token = useSelector((state) => state?.auth?.authUserData?.token);
  const authRole = useSelector(
    (state) => state?.auth?.authUserData?.user?.role,
  );

  console.log(currentDoc, "currentDoc");

  // Dropdown icon here
  const DropdownIndicator = (props) => {
    return (
      <div>
        <components.DropdownIndicator {...props}>
          <img className={classNames(classes.plusIcon)} src={WhitePlus}></img>
        </components.DropdownIndicator>
      </div>
    );
  };

  // -------------Mutation/Queries-----------------------//
  const {
    error: documentErrors,
    loading: documentLoading,
    data: SingleDocumentData,
  } = useQuery(FETCH_DOCUMENT, {
    variables: { token, id: documentData?.id },

    skip: !edit,
  });
  /* istanbul ignore next */
  const [addDocument, { loading }] = useMutation(CREATE_DOCUMENT, {
    onCompleted: (data) => {
      closeModal(); // Access the response data here
      toast.success("Document added succesfully!");
      refetchDocument();
    },
    onError: (error) => {
      toast.error("Unable to add document!");
    },
  });

  /* istanbul ignore next */
  const [updateDocument] = useMutation(UPDATE_DOCUMENT, {
    onCompleted: (data) => {
      closeModal(); // Access the response data here
      toast.success("Document updated succesfully!");
      setCurrentDoc({});
      refetchDocument();
    },
    onError: (error) => {
      toast.error("Unable to update document!");
    },
  });

  // Document add submit function
  /* istanbul ignore next */
  const onSubmit = (data) => {
    if (edit) {
      updateDocument({
        variables: {
          token,
          id: currentDoc?.id,
          name: currentDoc?.name,
          content: currentDoc?.content,
          startRange: currentDoc?.startRange,
          endRange: currentDoc?.endRange,
          userType: currentDoc?.userType,
          length: parseInt(currentDoc?.length),
        },
      });
    } else {
      addDocument({
        variables: {
          token: token,
          name: data?.name,
          content: document,
          startRange: data?.startRange,
          endRange: data?.endRange,
          userType: data?.userType?.value,
          length: parseInt(data?.length),
        },
      });
    }
  };

  // Renderers init

  useEffect(() => {
    setCurrentDoc(SingleDocumentData?.document);
  }, [SingleDocumentData]);

  /* istanbul ignore next */
  return (
    <>
      <div className={classNames(classes.addModalContainer)}>
        <div className={classNames(classes.headerContainer)}>
          <div className={classNames(classes.modalHeader)}>
            {!edit
              ? "New Document"
              : "Edit " + SingleDocumentData?.document?.name}
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classNames(classes.headerContainer)}>
            <Box sx={{ margin: "24px 0 12px 0" }}>
              <Grid
                className={classNames(classes.formRow)}
                container
                spacing={2}
              >
                <Grid item xs={12} md={12}>
                  <div>
                    <label>
                      Name
                      <span className={classNames(classes.mandatory)}>*</span>
                    </label>
                  </div>

                  {!edit ? (
                    <Input
                      type={"text"}
                      fullWidth
                      className={classes.textField}
                      keyboardIcon={<img src={Plus}>a</img>}
                      {...register("name", { required: true })}
                    />
                  ) : (
                    <Input
                      type={"text"}
                      fullWidth
                      value={currentDoc?.name}
                      onChange={(e) =>
                        setCurrentDoc({ ...currentDoc, name: e.target.value })
                      }
                      disabled={authRole != "admin"}
                      className={classes.textField}
                      keyboardIcon={<img src={Plus}>a</img>}
                    />
                  )}

                  {errors.name && (
                    <span className={classNames(classes.mandatory)}>
                      {" "}
                      This field is required
                    </span>
                  )}
                </Grid>
              </Grid>

              <Grid
                className={classNames(classes.formRow)}
                container
                spacing={2}
              >
                <Grid item xs={12} md={12}>
                  <div>
                    <label>
                      Content
                      <span className={classNames(classes.mandatory)}>*</span>
                    </label>
                  </div>
                  {!edit ? (
                    <textarea
                      type={"text"}
                      fullWidth
                      className={classNames(
                        classes.textField,
                        classes.description_textArea,
                      )}
                      onChange={(e) => setDocument(e.target.value)}
                      value={document}
                      keyboardIcon={<img src={Plus}>a</img>}
                    />
                  ) : (
                    <textarea
                      type={"text"}
                      fullWidth
                      disabled={authRole != "admin"}
                      className={classNames(
                        classes.textField,
                        classes.description_textArea,
                      )}
                      onChange={(e) =>
                        setCurrentDoc({
                          ...currentDoc,
                          content: e.target.value,
                        })
                      }
                      value={currentDoc?.content}
                      keyboardIcon={<img src={Plus}>a</img>}
                    />
                  )}

                  {errors.content && (
                    <span className={classNames(classes.mandatory)}>
                      {" "}
                      This field is required
                    </span>
                  )}
                </Grid>
                <Grid item xs={6} md={6}>
                  <div>
                    <label>
                      Start Range
                      <span className={classNames(classes.mandatory)}>*</span>
                    </label>
                  </div>

                  {!edit ? (
                    <Input
                      type={"date"}
                      fullWidth
                      className={classes.textField}
                      keyboardIcon={<img src={Plus}>a</img>}
                      {...register("startRange", { required: true })}
                    />
                  ) : (
                    <Input
                      type={"date"}
                      fullWidth
                      disabled={authRole != "admin"}
                      value={moment(currentDoc?.startRange).format(
                        "YYYY-MM-DD",
                      )}
                      className={classes.textField}
                      keyboardIcon={<img src={Plus}>a</img>}
                      onChange={(e) =>
                        setCurrentDoc({
                          ...currentDoc,
                          startRange: e.target.value,
                        })
                      }
                    />
                  )}

                  {errors.startRange && (
                    <span className={classNames(classes.mandatory)}>
                      {" "}
                      This field is required
                    </span>
                  )}
                </Grid>{" "}
                <Grid item xs={6} md={6}>
                  <div>
                    <label>End Range</label>
                  </div>

                  {!edit ? (
                    <Input
                      type={"date"}
                      fullWidth
                      className={classes.textField}
                      keyboardIcon={<img src={Plus}>a</img>}
                      {...register("endRange", { required: false })}
                    />
                  ) : (
                    <Input
                      type={"date"}
                      fullWidth
                      disabled={authRole != "admin"}
                      className={classes.textField}
                      keyboardIcon={<img src={Plus}>a</img>}
                      value={moment(currentDoc?.endRange).format("YYYY-MM-DD")}
                      onChange={(e) =>
                        setCurrentDoc({
                          ...currentDoc,
                          endRange: e.target.value,
                        })
                      }
                    />
                  )}

                  {errors.endRange && (
                    <span className={classNames(classes.mandatory)}>
                      {" "}
                      This field is required
                    </span>
                  )}
                </Grid>
              </Grid>

              <Grid
                className={classNames(classes.formRow)}
                container
                spacing={2}
              >
                {" "}
                <Grid item xs={6} md={6}>
                  <div>
                    <label>
                      User Type
                      <span className={classNames(classes.mandatory)}>*</span>
                    </label>
                  </div>
                  {!edit ? (
                    <Controller
                      name="userType"
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          className="react_select"
                          closeMenuOnSelect={true}
                          components={{ DropdownIndicator }}
                          // onChange={(e) => handleEquipmentValue(e)}
                          // defaultValue={[colourOptions[4], colourOptions[5]]}
                          // isMulti
                          options={userTypesOptions}
                        />
                      )}
                    />
                  ) : (
                    <Select
                      className="react_select"
                      closeMenuOnSelect={true}
                      components={{ DropdownIndicator }}
                      onChange={(e) =>
                        setCurrentDoc({ ...currentDoc, userType: e.value })
                      }
                      isDisabled={authRole != "admin"}
                      value={{
                        label: currentDoc?.userType,
                        value: currentDoc?.userType,
                      }}
                      // isMulti
                      options={userTypesOptions}
                    />
                  )}

                  {errors.userType && (
                    <span className={classNames(classes.mandatory)}>
                      {" "}
                      This field is required
                    </span>
                  )}
                </Grid>{" "}
                <Grid item xs={6} md={6}>
                  <div>
                    <label>Length</label>
                  </div>
                  {!edit ? (
                    <Input
                      type={"number"}
                      fullWidth
                      className={classes.textField}
                      keyboardIcon={<img src={Plus}>a</img>}
                      {...register("length", { required: false })}
                    />
                  ) : (
                    <Input
                      type={"number"}
                      fullWidth
                      className={classes.textField}
                      disabled={authRole != "admin"}
                      value={currentDoc?.length}
                      keyboardIcon={<img src={Plus}>a</img>}
                      onChange={(e) =>
                        setCurrentDoc({ ...currentDoc, length: e.target.value })
                      }
                    />
                  )}

                  {errors.length && (
                    <span className={classNames(classes.mandatory)}>
                      {" "}
                      This field is required
                    </span>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                margin: "24px 0 24px 0",
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <button className="upload_button" type="submit">
                Save
              </button>
              <button className="cancel_button">Reset</button>
            </Box>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddDocumentForm;
