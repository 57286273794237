import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "react-select";
import classNames from "classnames";
import useStyles from "../AddLineItem/styles";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { UPDATE_WORK_ORDER } from "../../../GraphQL/Mutations";
import { useMutation } from "@apollo/client";
import { Box, Grid, Input } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";

export default function AddRaiseDisputemodel({
  show,
  onHide,
  formData,
  refetch,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [disputedNote, setDisputedNote] = useState({});
  const [keys, setKeys] = useState([
    { value: "keyOnMeter", label: "Key On Meter" },
    { value: "driveMeter", label: "Drive Meter" },
    { value: "pumpMeter", label: "Pump Meter" },
    { value: "downtime", label: "Downtime" },
    { value: "serviceType", label: "Service Type" },
    { value: "invoiceType", label: "Invoice Type" },
    { value: "invoicedAt", label: "Invoiced At" },
    { value: "countAsPm", label: "Planned Maintenance" },
    { value: "countAsMfc", label: "Major Fluid Check" },
    { value: "lineItems", label: "Line Items" },
  ]);
  const [lineItemKeys, setLineItemKeys] = useState([
    { value: "description", label: "Description" },
    { value: "quantity", label: "Quantity" },
    { value: "unitPrice", label: "Unit Price" },
    { value: "tax", label: "Tax" },
    { value: "amount", label: "Amount" },
    { value: "workSystem", label: "Work System" },
    { value: "workCategories", label: "Work Categories" },
  ]);
  const [errorsValue, setErrorsValue] = useState(false);
  const [rows, setRows] = useState([
    {
      id: 1,
      field: "",
      internalId: "",
      lineItemField: "",
      correctValue: "",
      reason: "",
    },
  ]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const token = useSelector((state) => state?.auth?.authUserData?.token);
  /* istanbul ignore next */
  const [editWorkOrder, { loading }] = useMutation(UPDATE_WORK_ORDER, {
    onCompleted: (data) => {
      onHide(); // Access the response data here
      toast.success("Work Order updated succesfully!");
      refetch();
    },
    onError: (error) => {
      toast.error("Unable to update Work Order!");
    },
  });
  /* istanbul ignore next */
  const onFormSubmit = (e) => {
    editWorkOrder({
      variables: {
        token: token,
        id: formData?.id,
        serviceType: formData?.serviceType,
        serviceDescription: formData?.serviceDescription,
        keyOnMeter: parseInt(formData?.keyOnMeter),
        driveMeter: parseInt(formData?.driveMeter),
        pumpMeter: parseInt(formData?.pumpMeter),
        downtime: parseFloat(formData?.downtime),
        invoiceType: formData?.invoiceType,
        startedAt: formData?.startedAt,
        stoppedAt: formData?.stoppedAt,
        countAsPm: formData?.countAsPm,
        countAsMfc: formData?.countAsMfc,
        aasmState: "disputed",
        disputed: rows.map(
          ({ field, internalId, lineItemField, correctValue, reason }) => ({
            field,
            internalId,
            lineItemField,
            correctValue,
            reason,
          }),
        ),
        invoicedAt: formData?.invoicedAt,
      },
    });
  };

  console.log(formData, "ascnsan");

  console.log(formData, "froamnnac");

  /* istanbul ignore next */
  const addRow = () => {
    const newId = rows[rows.length - 1].id + 1;
    const newRow = {
      id: newId,
      field: "",
      internalId: "",
      lineItemField: "",
      correctValue: "",
      reason: "",
    };
    setRows([...rows, newRow]);
  };

  /* istanbul ignore next */
  const removeRow = (idToRemove) => {
    const updatedRows = rows.filter((row) => row.id !== idToRemove);
    setRows(updatedRows);
  };
  /* istanbul ignore next */
  const handleValue1Change = (e, id) => {
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        const selectedOption = e.target.value;
        setSelectedOptions([...selectedOptions, selectedOption]);
        return { ...row, [e.target.name]: selectedOption };
      }
      return row;
    });
    setRows(updatedRows);
  };
  /* istanbul ignore next */
  const handleValue2Change = (e, id) => {
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, [e.target.name]: e.target.value };
      }
      return row;
    });
    setRows(updatedRows);
  };
  /* istanbul ignore next */
  const handleValue3Change = (e, id) => {
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, reason: e.target.value };
      }
      return row;
    });
    setRows(updatedRows);
  };

  console.log(rows, "rowswrwoqewqpdwq");

  return (
    <div>
      <Dialog
        open={show}
        onClose={onHide}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dispute_dropdown"
      >
        <div className="textArea-dialog">
          <DialogTitle id="alert-dialog-title">Raise Dispute</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <>
                {rows.map((row) => (
                  <Box key={row.id} display={"flex"} alignItems={"center"}>
                    <Box mr={4} mt={2}>
                      <label htmlFor="selectfield">Select Field: </label>
                      <select
                        id="selectfield"
                        name="field"
                        value={row?.field}
                        onChange={(e) => handleValue1Change(e, row.id)}
                        className="form-control search_dropdown"
                      >
                        <option selectedOption>Select an option</option>
                        {keys &&
                          keys?.map((data) => (
                            <option value={data?.value}>{data.label}</option>
                          ))}
                      </select>
                    </Box>
                    {row.field === "lineItems" && (
                      <>
                        <Box mr={4} mt={2}>
                          <label>Internal ID: </label>
                          <select
                            name="internalId"
                            value={row?.internalId}
                            onChange={(e) => handleValue1Change(e, row.id)}
                            className="form-control search_dropdown"
                          >
                            <option selectedOption>Select an option</option>
                            {formData &&
                              formData?.lineItems?.map((data) => (
                                <option>{data.internalId}</option>
                              ))}
                          </select>
                        </Box>{" "}
                        <Box mr={4} mt={2}>
                          <label>Line Item Field: </label>
                          <select
                            name="lineItemField"
                            value={row?.lineItemField}
                            onChange={(e) => handleValue1Change(e, row.id)}
                            className="form-control search_dropdown"
                          >
                            <option selectedOption>Select an option</option>
                            {lineItemKeys &&
                              lineItemKeys?.map((data) => (
                                <option value={data?.value}>
                                  {data?.label}
                                </option>
                              ))}
                          </select>
                        </Box>
                      </>
                    )}

                    {row.field === "countAsPm" || row.field === "countAsMfc" ? (
                      <Box mr={4} mt={2}>
                        <label>Correct Value: </label>
                        <select
                          name="correctValue"
                          className="form-control search_dropdown"
                          onChange={(e) => handleValue2Change(e, row.id)}
                          value={row.correctValue}
                        >
                          <option value={"true"}>Yes</option>
                          <option value={"false"}>No</option>
                        </select>
                      </Box>
                    ) : (
                      <Box mr={4} mt={2}>
                        <label>Correct Value: </label>
                        <input
                          name="correctValue"
                          type={"text"}
                          value={row.correctValue}
                          className="form-control search_dropdown"
                          onChange={(e) => handleValue2Change(e, row.id)}
                        ></input>
                      </Box>
                    )}

                    <Box mr={4} mt={2}>
                      <label>Reason: </label>
                      <input
                        name="reason"
                        type={"text"}
                        value={row.reason}
                        className="form-control search_dropdown"
                        onChange={(e) => handleValue3Change(e, row.id)}
                      ></input>
                    </Box>
                    <Box mt={5}>
                      {rows?.length > 1 && (
                        <DisabledByDefaultIcon
                          className="cursor_pointer"
                          onClick={() => removeRow(row?.id)}
                        />
                      )}
                    </Box>
                    <Box mt={5}>
                      <AddBoxIcon className="cursor_pointer" onClick={addRow} />
                    </Box>
                  </Box>
                ))}
              </>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className={classNames(classes.cancelButton)}
              onClick={() => onHide()}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
            <Button
              className={classNames(classes.SaveButton)}
              onClick={() => onFormSubmit()}
            >
              Raise Dispute
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
