import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

//Styles

import useStyles from "./styles";

export default function BasicCard({
  invoiceCount,
  cardName,
  invoice_image,
  data,
  tableName,
  status,
  search,
}) {
  const [tableData, setTableData] = React.useState([]);
  const classes = useStyles();
  let history = useHistory();

  React.useEffect(() => {
    setTableData(data);
  }, [data]);

  const handleClick = () => {
    history.push({
      pathname: `/app/invoices/filter/${status}`,

      state: {
        allInvoiceData: tableData,
        status,
        cardName,
        tableName,
      },
    });
  };

  return (
    <Box className={classNames(classes.card)}>
      <CardContent>
        <Grid container>
          <Grid xs={12} md={3}>
            <Box>
              <img src={invoice_image}></img>
            </Box>
          </Grid>

          <Grid xs={12} md={6}>
            <Box>
              <div>
                <span className={classNames(classes.count)}>
                  {invoiceCount}
                </span>
              </div>
              <span className={classNames(classes.cardName)}>
                {cardName[0].toUpperCase() + cardName.slice(1)}
              </span>
              {/* <div>
                <span className={classNames(classes.cardDescription)}>
                  in 30 days
                </span>
              </div> */}
            </Box>
          </Grid>

          {/* <Grid xs={12} md={3}>
            <Button size="small" onClick={handleClick}>
              View All
            </Button>
          </Grid> */}
        </Grid>
      </CardContent>
    </Box>
  );
}
