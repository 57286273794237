import { ActionTypes } from "../constants/actionTypes";

const initialState = {};

const customerReducer = (state = initialState, { type, payload } = []) => {
  switch (type) {
    case ActionTypes.GET_ALL_CUSTOMERS:
      return { ...state, getAllCustomers: { ...payload } };
      case ActionTypes.GET_ALL_VENDORS:
        return { ...state, getAllVendors: { ...payload } };
    default:
      return state;
  }
};

export default customerReducer;
