import React, { useState, useEffect } from "react";
import BasicCard from "../../components/InvoiceCard/Card";
import Box from "@mui/material/Box";
import { Grid, Input } from "@mui/material";
import classNames from "classnames";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

import { useQuery, gql } from "@apollo/client";
import {
  CUSTOMER_SEARCH,
  FETCH_ALL_CONTRACTS,
  FETCH_ALL_CUSTOMERS,
  FETCH_ALL_DOCUMENTS,
  FETCH_ALL_INVOICES,
  FETCH_CURRENT_USER,
  FETCH_CUSTOMERS,
  FETCH_USER_VENDOR_DETAIL,
  FETCH_VENDOR_WORK_ORDERS,
  FETCH_END_CUSTOMER_INVOICES,
  FETCH_CONTRACT_DETAILS_BY_CUSTOMER,
  FETCH_DOCUMENT_BY_EMAIL,
  GET_CUSTOMERS_BY_USER_ID,
} from "../../GraphQL/Queries";

// File Uploader
import { FileUploader } from "react-drag-drop-files";

//DateRange
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

//Styles

import useStyles from "./styles";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Button, TextField } from "@material-ui/core";

//Icons and other imports

import Calendar from "../../assets/img/icons/calendar.svg";
import open_invoice from "../../assets/img/icons/open_invoice.svg";
import Closed_Invoice from "../../assets/img/icons/Closed_Invoice.svg";
import FilterIcon from "../../assets/img/icons/filter.svg";
import CardPage from "../../assets/img/menu/CardPage.svg";
import Plus from "../../assets/img/icons/plus.svg";
import AddInvoiceForm from "./components/forms/AddInvoiceForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getContractsData,
  getDocumentsData,
  getInvoicesData,
  getUserVendorDetails,
  getVendorWorkOrderData,
} from "../../redux/actions/invoiceActions";
import Filter from "../../components/Filter/Filter";
import AddMicroInvoiceForm from "./components/forms/AddMicroInvoiceForm";
import { getCurrentUser } from "../../redux/actions/authActions";
import Tabs from "./components/Tabs";
import AddDocumentForm from "./components/forms/AddDocumentForm";
import AddCustomerForm from "./components/forms/AddCustomerForm";
import AddEquipmentForm from "./components/forms/AddEquipmentForm";
import { getAllCustomerInvoiceStatus, getAllVendorStatus } from "../../helpers";
import CardContent from "./components/cardContent/CardContent";
import moment from "moment";

// File Types to be uploaded

const fileTypes = ["JPG", "PNG", "GIF"];

export default function Invoices(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  /* istanbul ignore next */

  //States maintained

  const [icon, setIcon] = React.useState(null);
  const [tabsparentState, settabsParentState] = useState("Overall");
  const [openModal, setOpenModal] = React.useState(false);
  const [filterApply, setFilterApply] = React.useState(false);
  const [filterName, setFilterName] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [focus, setFocus] = React.useState(false);
  const [search, setSearch] = useState();
  const [vendorSearch, setVendorSearch] = useState();
  const [invoiceSearch, setInvoiceSearch] = useState();
  const [searchBy, setSearchBy] = useState("Customer Name");
  const [file, setFile] = useState(null);
  const [status, setStatus] = useState([]);
  const [location, setLocation] = useState([]);
  const [city, setCity] = useState([]);
  const [invoiceType, setInvoiceType] = useState([]);
  const [workOrderStatusForFilter, setWorkOrderStatusForFilter] = useState([]);
  const [workOrderLocationsForFilter, setWorkOrderLocationsForFilter] =
    useState([]);
  const [invoicesDataForFilter, setInvoicesStatusForFilter] = useState([]);
  const [invoiceTypeForFilter, setInvoiceTypeForFilter] = useState([]);
  const [date, setDate] = useState([
    {
      startDate: "",
      endDate: "",
      key: "selection",
    },
  ]);

  console.log("mainn paggee");
  // Use Selector to get the data from store
  const authUserData = useSelector((state) => state?.auth?.authUserData);

  const invoicesData = useSelector(
    (state) => state?.invoices?.getAllInvoicesData?.endCustomerInvoices,
  );

  const contractsData = useSelector(
    (state) =>
      state?.invoices?.getAllContractsData?.fetchContractDetailsByCustomer,
  );

  const documentsData = useSelector(
    (state) => state?.invoices?.allDocumentsData?.documentByEmail,
  );

  const vendorInvoiceData = useSelector(
    (state) => state?.invoices?.getVendorInvoiceData?.vendorWorkOrder,
  );

  const token = useSelector((state) => state?.auth?.authUserData?.token);
  const vendorId = useSelector(
    (state) => state?.auth?.authUserData?.user?.vendorId,
  );

  const email = useSelector((state) => state?.auth?.authUserData?.user?.email);

  const userId = useSelector((state) => state?.auth?.authUserData?.user?.id);

  const userRole = useSelector(
    (state) => state?.auth?.authUserData?.user?.role,
  );

  const customersArray = useSelector(state => state?.auth?.authUserData?.user?.customers) || [];
  console.log(customersArray, "customersArray");
  
  // Attempt to parse the array
  let customerIds;
  try {
    customerIds = JSON.parse(customersArray);
  } catch (error) {
    console.error('Error parsing JSON:', error);
    customerIds = [];
  }
  
  // Ensure it's an array
  if (!Array.isArray(customerIds)) {
    console.error('Error: customersArray is not an array');
    customerIds = [];
  } else {
    // Ensure all elements are valid integers
    customerIds = customerIds.map(id => parseInt(id, 10));
  
    // Filter out NaN values
    customerIds = customerIds.filter(id => !isNaN(id));
  }
  
  console.log(customerIds, "Customers");

  // ------------------Queries Maintained ---------------------------//

  // Api call to fetch all Macro invoices
  /* istanbul ignore next */
  const {
    error: endCustomerInvoiceError,
    loading: endCUstomerInvoiceLoading,
    data: endCustomerInvoiceData,
  } = useQuery(FETCH_END_CUSTOMER_INVOICES, {
    variables: {
      ids: customerIds,
      token,
      startDate: date[0]?.startDate ? new Date(date[0]?.startDate) : undefined,
      endDate: date[0]?.endDate ? new Date(date[0]?.endDate) : undefined,
      status: filterApply && status?.length > 0 ? status : undefined,
      query: invoiceSearch ? invoiceSearch : undefined,
    },
  });
  console.log(endCustomerInvoiceData, "invoicesData");
  // Api call to fetch all Documents
  const {
    error: allDocumentsError,
    loading: allDocumentsLoading,
    data: allDocumentsData,
    refetch: refetchDocument,
  } = useQuery(FETCH_DOCUMENT_BY_EMAIL, {
    variables: { 
      token,
      id: userId,
     },
  });

  console.log(documentsData, "documentsData");
  // Api call to fetch current user
  const {
    error: currentUserError,
    loading: currentUserLoading,
    data: user,
  } = useQuery(FETCH_CURRENT_USER, {
    variables: {
      token: token,
    },
  });
  

  // Api call to fetch all Customers
  // const {
  //   error: allCustomersError,
  //   loading: allCustomersLoading,
  //   data: allCustomersData,
  //   refetch: refetchCustomers,
  // } = useQuery(FETCH_CUSTOMERS, {
  //   variables: { token },
  // });

  const {
    error: allContracts,
    loading: allContractsLoading,
    data: allContractsData,
  } = useQuery(FETCH_CONTRACT_DETAILS_BY_CUSTOMER, {
    variables: {
      id: user?.currentUser?.vendorId,
      token: token,
    },
  });

  // Api call to fetch  vendor detail
  const {
    error: userVendorError,
    loading: userVendorLoading,
    data: userVendorDetail,
  } = useQuery(FETCH_USER_VENDOR_DETAIL, {
    variables: {
      token: token,
      id: user?.currentUser?.vendorId,
    },
    skip: !user,
  });

  // Api call to fetch  vendor work orders
  const {
    error: searchCustomersErrors,
    loading: searchCustomersLoading,
    data: searchCustomers,
  } = useQuery(CUSTOMER_SEARCH, {
    variables: {
      token,
      search: search ? search : undefined,
    },
    skip: !userRole,
  });

  // Api call to fetch  vendor work orders
  /* istanbul ignore next */
  const {
    error: vendorWorkOrdersErrors,
    loading: vendorWorkOrdersLoading,
    data: vendorWorkOrders,
    refetch,
  } = useQuery(FETCH_VENDOR_WORK_ORDERS, {
    variables: {
      token,
      id: userRole === "vendor" ? vendorId : customerIds,
      startDate: date[0]?.startDate ? new Date(date[0]?.startDate) : undefined,
      endDate: date[0]?.endDate ? new Date(date[0]?.endDate) : undefined,
      status: filterApply && status?.length > 0 ? status : undefined,
      city:
        filterApply && location
          ? location
          : !filterApply && city
          ? city
          : undefined,
      query:
        searchBy === "Customer Name" && vendorSearch
          ? vendorSearch
          : undefined || (searchBy === "Invoice Number" && search)
          ? search
          : undefined,
      invoiceType:
        filterApply && invoiceType?.length > 0 ? invoiceType : undefined,
    },
    skip: !userRole,
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const tabTypes = [
    "Overall",
    userRole === "vendor" ? null : "End Customer Invoices",
    // "Vendor Invoices For Customer",
    "Vendor Invoices",
    userRole === "vendor" ? null : "Contracts",
    "Documents",
  ];

  // Renderers Maintained

  // Rendering to save all the filters in a state
  /* istanbul ignore next */
  useEffect(() => {
    setWorkOrderStatusForFilter([
      ...new Set(vendorInvoiceData?.map((item) => item?.aasmState)),
    ]);
    setWorkOrderLocationsForFilter([
      ...new Set(vendorInvoiceData?.map((item) => item?.customer?.city)),
    ]);
    setInvoiceTypeForFilter([
      ...new Set(vendorInvoiceData?.map((item) => item?.invoiceType)),
    ]);

    setInvoicesStatusForFilter([
      ...new Set(invoicesData?.map((item) => item?.aasmState)),
    ]);
  }, [vendorInvoiceData, invoicesData]);

  // clear search on tabs change

  useEffect(() => {
    setSearch(null);
  }, [tabsparentState]);

  useEffect(() => {
    setStatus([]);
    setLocation([]);
    setInvoiceType([]);
    setDate([
      {
        startDate: "",
        endDate: "",
        key: "selection",
      },
    ]);
  }, [tabsparentState]);
  /* istanbul ignore next */
  useEffect(() => {
    if (user) {
      dispatch(getCurrentUser(user, token, "!navigate"));
    }
  }, [user]);

  // use effect to grab and update all data from redux as soon as the api is called
  useEffect(() => {
    dispatch(getInvoicesData(endCustomerInvoiceData));
    dispatch(getDocumentsData(allDocumentsData));
    dispatch(getContractsData(allContractsData));
    dispatch(getVendorWorkOrderData(vendorWorkOrders));
    dispatch(getUserVendorDetails(userVendorDetail));
  }, [
    endCustomerInvoiceData,
    allContractsData,
    allDocumentsData,
    vendorWorkOrders,
    userVendorDetail,
  ]);

  // Functions Maintained

  // apply filter function
  /* istanbul ignore next */
  const onFilterApply = () => {
    setFilterApply(true);
    setOpenModal(false);
  };

  // tabs change
  /* istanbul ignore next */
  function handleChildChange(childState) {
    settabsParentState(childState);
  }

  //set file
  /* istanbul ignore next */
  const handleChange = (file) => {
    setFile(file);
  };

  // handling popover
  /* istanbul ignore next */
  const handleClick = (event, type) => {
    setIcon(type);
    setAnchorEl(event.currentTarget);
  };
  /* istanbul ignore next */
  const handleSearchItemClick = (value) => {
    setFocus(false);
    setCity(value?.internalName);
    setSearch(value?.name + " " + value.internalName);
    setVendorSearch(value?.name);
  };
  /* istanbul ignore next */
  const clearAll = () => {
    setFocus(false);
    setCity("");
    setSearch("");
    setVendorSearch("");
  };

  //open Filter Modal
  /* istanbul ignore next */
  const handleFilterClick = (event, type) => {
    setOpenModal(true);
    setIcon(type);
    onReset();
    // setAnchorEl(event.currentTarget);
  };

  const handleFocus = (e) => {
    // dispatch(searchBooks(e.target.value, accessToken));
    setFocus(true);
  };

  // Reset the state
  /* istanbul ignore next */
  const onReset = () => {
    setStatus([]);
    setInvoiceType([]);
    setLocation([]);
    setFilterApply(false);
  };

  // set anchor tag null
  const handleClose = () => {
    setAnchorEl(null);
  };

  const vendorStatus = getAllVendorStatus();
  const customerInvoiceStatus = getAllCustomerInvoiceStatus();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  //open PopOver Display
  /* istanbul ignore next */
  const PopoverDisplay = () => {
    return (
      <div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 75, left: 850 }}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {/* Calendar Popover */}

          {icon === "calendar" && (
            <>
              <div className={classNames(classes.modalContainer)}>
                <div className={classNames(classes.headerContainer)}>
                  <Box sx={{ margin: "24px 0 12px 0" }}>
                    <div>
                      <label>From</label>
                    </div>
                    <input
                      value={date[0]?.startDate}
                      onChange={(e) =>
                        setDate([{ ...date[0], startDate: e.target.value }])
                      }
                      type="month"
                      className={classes.textField}
                    />
                  </Box>
                  <Box sx={{ margin: "24px 0 12px 0" }}>
                    <div>
                      <label>To</label>
                    </div>
                    <input
                      value={date[0]?.endDate}
                      onChange={(e) =>
                        setDate([{ ...date[0], endDate: e.target.value }])
                      }
                      type="month"
                      className={classes.textField}
                    />
                  </Box>
                  <Box
                    sx={{
                      margin: "24px 0 24px 0",
                      display: "flex",
                      flexDirection: "row-reverse",
                    }}
                  >
                    <button
                      onClick={() =>
                        setDate([{ ...date[0], endDate: "", startDate: "" }])
                      }
                      className="upload_button"
                    >
                      Clear
                    </button>
                  </Box>
                </div>
              </div>
            </>
          )}
          {/* Upload Popover */}
          {icon === "upload" && (
            <>
              <div className={classNames(classes.modalContainer)}>
                <div className={classNames(classes.headerContainer)}>
                  <div className={classNames(classes.modalHeader)}>
                    Add Document
                  </div>
                </div>

                <div className={classNames(classes.headerContainer)}>
                  <Box sx={{ margin: "24px 0 12px 0" }}>
                    <div>
                      <label>Title</label>
                    </div>
                    <Input className={classes.textField} />
                  </Box>
                  <Box
                    sx={{
                      margin: "24px 0 12px 0",
                    }}
                  >
                    <div>
                      <label>Attach Document</label>
                    </div>
                    <Box className={"fileUploader"}>
                      <FileUploader
                        handleChange={handleChange}
                        name="file"
                        types={fileTypes}
                      />
                      <div>
                        <label className={classes.fileTypeText}>
                          Accepted File Types: .PDF and CSV only
                        </label>
                      </div>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      margin: "24px 0 24px 0",
                      display: "flex",
                      flexDirection: "row-reverse",
                    }}
                  >
                    <button className="upload_button">Upload</button>
                    <button className="cancel_button">Cancel</button>
                  </Box>
                </div>
              </div>
            </>
          )}
          {/* Create New Invoice popover*/}
          {icon === "invoices" && <AddInvoiceForm closeModal={handleClose} />}

          {icon === "microInvoice" && (
            <AddMicroInvoiceForm
              refetchWorkOrders={refetch}
              closeModal={handleClose}
              handleClick={handleClick}
            />
          )}
          {icon === "documents" && (
            <AddDocumentForm
              closeModal={handleClose}
              refetchDocument={refetchDocument}
            />
          )}
          {icon === "customer" && <AddCustomerForm closeModal={handleClose} />}
          {icon === "equipment" && (
            <AddEquipmentForm
              closeModal={handleClose}
              handleClick={handleClick}
            />
          )}
        </Popover>
      </div>
    );
  };
  /* istanbul ignore next */
  return (
    <>
      <Filter
        open={openModal}
        onClose={() => setOpenModal(false)}
        status={status}
        setStatus={setStatus}
        tabsparentState={tabsparentState}
        workOrderStatusForFilter={workOrderStatusForFilter}
        invoicesDataForFilter={invoicesDataForFilter}
        workOrderLocationsForFilter={workOrderLocationsForFilter}
        invoiceTypeForFilter={invoiceTypeForFilter}
        location={location}
        setLocation={setLocation}
        invoiceType={invoiceType}
        setInvoiceType={setInvoiceType}
        onFilterApply={onFilterApply}
        setFilterApply={setFilterApply}
        onReset={onReset}
      />
      <div>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            borderRadius: 1,
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <PageTitle
              breadcrumb={false}
              title={"Invoices"}
              subTitle={"dwqdwq"}
            />
          </Box>

          {tabsparentState != "Overall" && (
            <>
              <Box>
                {tabsparentState === "Vendor Invoices" && (
                  <>
                    <Box className={classes.paper}>
                      <IconButton
                        type="button"
                        sx={{ p: "10px" }}
                        aria-label="search"
                      >
                        <SearchIcon />
                      </IconButton>{" "}
                      <Box display={"flex"}>
                        <select
                          className="searchbar_select"
                          onChange={(e) => {
                            setSearchBy(e.target.value);
                            clearAll();
                          }}
                        >
                          <option value={"Customer Name"}>
                            Customer Name/ Location
                          </option>
                          <option value={"Invoice Number"}>
                            Invoice Number
                          </option>
                        </select>
                      </Box>
                      {searchBy === "Customer Name" ? (
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          onChange={(e) => setSearch(e.target.value)}
                          text="Search"
                          placeholder="Search by Name or Location"
                          onFocus={(e) => handleFocus(e)}
                          value={search}
                        />
                      ) : (
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          onChange={(e) => setSearch(e.target.value)}
                          text="Search"
                          placeholder="Search by Invoice Number"
                          value={search}
                        />
                      )}
                      <Box className="cursor_pointer" onClick={clearAll} mr={2}>
                        Clear
                      </Box>
                      {focus &&
                        searchBy === "Customer Name" &&
                        searchCustomers &&
                        searchCustomers?.searchCustomers && (
                          <div className="cus_search_drop">
                            {searchCustomers?.searchCustomers?.length > 0 &&
                              searchCustomers?.searchCustomers?.map((d, i) => (
                                <>
                                  <div
                                    className="search_items"
                                    onClick={() => handleSearchItemClick(d)}
                                  >
                                    {d?.name + " " + d?.internalName}
                                  </div>
                                </>
                              ))}
                          </div>
                        )}
                    </Box>
                  </>
                )}
                {tabsparentState === "End Customer Invoices" && (
                  <Box className={classes.paper}>
                    <IconButton
                      type="button"
                      sx={{ p: "10px" }}
                      aria-label="search"
                    >
                      <SearchIcon />
                    </IconButton>
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      onChange={(e) => setInvoiceSearch(e.target.value)}
                      placeholder="Search here by customer’s name, TDW Invoice no."
                      text="Search"
                    />
                  </Box>
                )}
              </Box>
              <Box className={classNames(classes.buttonContainer)}>
                <Button
                  onClick={(e) => handleClick(e, "calendar")}
                  className={classNames(classes.button)}
                >
                  <img src={Calendar}></img>
                </Button>
                <Button
                  onClick={(e) => handleFilterClick(e, "filter")}
                  className={classNames(classes.button)}
                >
                  <img src={FilterIcon}></img>
                </Button>
              </Box>
            </>
          )}

          {userRole === "admin" && tabsparentState === "Vendor Invoices" && (
            <>
              <Box className={classNames(classes.buttonContainer)}>
                <Button
                  onClick={(e) => handleClick(e, "equipment")}
                  className={classNames(classes.button)}
                >
                  <img src={Plus}></img> &nbsp;&nbsp;Create Equipment
                </Button>
              </Box>
              <Box className={classNames(classes.buttonContainer)}>
                <Button
                  onClick={(e) => handleClick(e, "customer")}
                  className={classNames(classes.button)}
                >
                  <img src={Plus}></img> &nbsp;&nbsp;Create Customer
                </Button>
              </Box>
            </>
          )}

          {(userRole === "admin" ||
            userRole === "vendor" ||
            userRole === "user") &&
            tabsparentState === "Vendor Invoices" && (
              <>
                <Box className={classNames(classes.buttonContainer)}>
                  <Button
                    onClick={(e) => handleClick(e, "microInvoice")}
                    className={classNames(classes.button)}
                  >
                    <img src={Plus}></img> &nbsp;&nbsp;Create Invoice
                  </Button>
                </Box>
              </>
            )}

          <Box className={classNames(classes.buttonContainer)}>
            {userRole === "admin" && tabsparentState === "Documents" && (
              <Button
                onClick={(e) => handleClick(e, "documents")}
                className={classNames(classes.button)}
              >
                <img src={Plus}></img> &nbsp;&nbsp;Create
              </Button>
            )}
          </Box>
          <Box className={classNames(classes.buttonContainer)}>
            {userRole === "admin" &&
              tabsparentState === "End Customer Invoices" && (
                <Button
                  onClick={(e) => handleClick(e, "invoices")}
                  className={classNames(classes.button)}
                >
                  <img src={Plus}></img> &nbsp;&nbsp;Create
                </Button>
              )}
          </Box>
        </Box>
        <CardContent
          search={search}
          tabsparentState={tabsparentState}
          vendorStatus={vendorStatus}
          customerInvoiceStatus={customerInvoiceStatus}
          classNames={classNames}
          classes={classes}
          vendorInvoiceData={vendorInvoiceData}
          invoicesData={invoicesData}
          image={open_invoice}
        />
        <Tabs
          tabColor={"#E9E9E9"}
          types={tabTypes.filter((type) => type !== null)} // Filter out null values
          onChange={handleChildChange}
          invoicesData={invoicesData}
          contractsData={contractsData}
          documentsData={documentsData}
          // customersData={allCustomersData}
          vendorInvoiceData={vendorInvoiceData}
          search={search}
          invoiceSearch={invoiceSearch}
          dateRange={date}
          setDate={setDate}
          loading={{
            allContractsLoading,
            allDocumentsLoading,
            endCUstomerInvoiceLoading,
            vendorWorkOrdersLoading,
          }}
          filters={{ status, location, invoiceType, date }}
          refetchDocument={refetchDocument}
          setLocation={setLocation}
          setStatus={setStatus}
          setInvoiceType={setInvoiceType}
          location={location}
          status={status}
          invoiceType={invoiceType}
        />
      </div>
      {PopoverDisplay()}
    </>
  );
}
