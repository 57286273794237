/* istanbul ignore next */
export function getStatusClass(aasmState, classes) {
  const statusMap = {
    disputed: classes.aasmStateDisputed,
    internal: classes.aasmStateInternal,
    paid: classes.aasmStatePaid,
    closed: classes.aasmStateClosed,
    submitted: classes.aasmStateSubmitted,
    batched: classes.aasmStateBatched,
    accepted: classes.aasmStateAccepted,
    assigned: classes.aasmStateAssigned,
    uploaded: classes.aasmStateUploaded,
    invoiced: classes.aasmStateInvoiced,
    open: classes.aasmStateOpen,
    credited: classes.aasmStateCredited,
  };
  /* istanbul ignore next */
  return statusMap[aasmState] || "";
}

export function getAllVendorStatus() {
  const status = [
    "disputed",
    "internal",
    "paid",
    "closed",
    "submitted",
    "batched",
    "accepted",
    "assigned",
    "uploaded",
    "invoiced",
    "open",
    "credited",
  ];
  return status || "";
}

export function getAllCustomerInvoiceStatus() {
  const status = ["paid", "closed", "open", "internal"];
  return status || "";
}
