import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { getStatusClass } from "../../../helpers";

import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import edit_pencil from "../../../assets/img/icons/edit_pencil.svg";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Export_Button from "../../../assets/img/icons/Export_Button.svg";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import SearchBar from "material-ui-search-bar";
import { Link, useHistory } from "react-router-dom";
import moment from "moment/moment";
import { format } from "date-fns";
import { useSelector } from "react-redux";

import classNames from "classnames";
import exportIcon from "../../../assets/img/icons/exportIcon.svg";

import useStyles from "../styles";

const MAX_CHARS = 100;

/* istanbul ignore next */
function createData(
  Date,
  DocumemtNo,
  Location,
  Lines,
  Subtotal,
  Tax,
  Total,
  Remaining,
  DueDate,
  Status,
) {
  return {
    Date,
    DocumemtNo,
    Location,
    Lines,
    Subtotal,
    Tax,
    Total,
    Remaining,
    DueDate,
    Status,
  };
}
/* istanbul ignore next */
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
/* istanbul ignore next */
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
/* istanbul ignore next */
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "internalId",
    numeric: false,
    disablePadding: true,
    label: "Vendor Invoice No.",
  },
  {
    id: "customerName",
    numeric: false,
    disablePadding: false,
    label: "Customer Name",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "Location",
  },

  {
    id: "invoiceType",
    numeric: false,
    disablePadding: false,
    label: "Invoice Type",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Invoice At",
  },
  {
    id: "serviceDescription",
    numeric: false,
    disablePadding: false,
    label: "Service Description",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: "Line Items Total",
  },

  {
    id: "aasmState",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "relationship",
    numeric: false,
    disablePadding: false,
    label: "Consolidated Invoice No.",
  },
  {
    id: "edit",
    numeric: false,
    disablePadding: false,
    label: "",
  },

  // {
  //   id: "export",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "",
  // },
];
/* istanbul ignore next */
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            // padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function VendorsInvoiceTable({
  invoicesData,
  perPage,
  filters,
  setStatus,
  setInvoiceType,
  setLocation,
  location,
  status,
  invoiceType,
  setDate,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(perPage);
  const [showMore, setShowMore] = React.useState(false);
  const [expandedRow, setExpandedRow] = React.useState(null);

  console.log(invoicesData, "invoiceDataa");
  /* istanbul ignore next */
  const handleShowMore = () => {
    setShowMore(!showMore);
  };
  /* istanbul ignore next */
  const handleRowExpand = (rowId) => {
    setExpandedRow(expandedRow === rowId ? null : rowId);
  };

  // Selectors from state
  /* istanbul ignore next */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "desc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  //select all the row in table
  /* istanbul ignore next */
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = invoicesData.map((n) => n);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  //used to concat selected row
  /* istanbul ignore next */
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  //page change function
  /* istanbul ignore next */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //Row change function
  /* istanbul ignore next */
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  /* istanbul ignore next */
  const removeFilter = (filter) => {
    setLocation(location?.filter((item) => item !== filter));
    setStatus(status?.filter((item) => item !== filter));
    setInvoiceType(invoiceType?.filter((item) => item !== filter));
  };
  /* istanbul ignore next */
  const clearAll = () => {
    setLocation([]);
    setStatus([]);
    setInvoiceType([]);
    setDate([{ ...filters?.date[0], endDate: "", startDate: "" }]);
  };
  /* istanbul ignore next */
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const userRole = useSelector(
    (state) => state?.auth?.authUserData?.user?.role,
  );
  
  // Avoid a layout jump when reaching the last page with empty rows.
  /* istanbul ignore next */
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - invoicesData?.length) : 0;
  /* istanbul ignore next */
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer sx={{ padding: "32px" }}>
          <Box marginBottom={"3px"}>
            <Box display={"flex"} justifyContent={"right"}>
              {/* <span className={classes.appliedFilterTextMain}>
                APPLIED FILTERS:
              </span> */}
              {(filters?.status?.length > 0 ||
                filters?.location?.length > 0 ||
                filters?.invoiceType?.length > 0 ||
                (filters?.date[0]?.startDate && filters?.date[0]?.endDate)) && (
                  <Box onClick={() => clearAll()} className={classes.clearAll}>
                    <Box>
                      <span className="cursor_pointer">Clear All</span>
                    </Box>
                    <Box ml={1}>
                      <span className="cursor_pointer">X</span>
                    </Box>
                  </Box>
                )}{" "}
              {filters?.date[0]?.startDate && filters?.date[0]?.endDate && (
                <>
                  <Box mr={1}>
                    <span className={classes.appliedFilter}>
                      From: {filters?.date[0]?.startDate}
                    </span>
                  </Box>
                  <Box mr={1}>
                    <span className={classes.appliedFilter}>
                      To: {filters?.date[0]?.endDate}
                    </span>
                  </Box>
                </>
              )}
              {filters?.status?.length > 0 && (
                <>
                  <span className={classes.appliedFilterText}>STATUS: </span>{" "}
                  {filters?.status?.map((status) => (
                    <>
                      <Box className={classes.appliedFilter}>
                        <Box>
                          <span>{status.toUpperCase()}</span>
                        </Box>
                        <Box
                          onClick={() => removeFilter(status)}
                          className="cursor_pointer"
                          ml={1}
                        >
                          <span>X</span>
                        </Box>
                      </Box>
                    </>
                  ))}
                </>
              )}
              {filters?.location?.length > 0 && (
                <>
                  <span className={classes.appliedFilterText}>CITY: </span>{" "}
                  {filters?.location?.map((city) => (
                    <>
                      <Box className={classes.appliedFilter}>
                        <Box>
                          <span>{city.toUpperCase()}</span>
                        </Box>
                        <Box
                          onClick={() => removeFilter(city)}
                          className="cursor_pointer"
                          ml={1}
                        >
                          <span>X</span>
                        </Box>
                      </Box>
                    </>
                  ))}
                </>
              )}
              {filters?.invoiceType?.length > 0 && (
                <>
                  <span className={classes.appliedFilterText}>TYPE: </span>{" "}
                  {filters?.invoiceType?.map((invoiceType) => (
                    <>
                      <Box className={classes.appliedFilter}>
                        <Box>
                          <span>{invoiceType.toUpperCase()}</span>
                        </Box>
                        <Box
                          onClick={() => removeFilter(invoiceType)}
                          className="cursor_pointer"
                          ml={1}
                        >
                          <span>X</span>
                        </Box>
                      </Box>
                    </>
                  ))}
                </>
              )}
            </Box>
          </Box>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={invoicesData?.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}

              {invoicesData &&
                stableSort(invoicesData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    console.log(row, "rooaaa");
                    const isItemSelected = isSelected(row);
                    const labelId = `enhanced-table-checkbox-${row}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row}
                        selected={isItemSelected}
                      >
                        <TableCell>
                          <div
                            onClick={() =>
                              history.push(`/app/workorders/${row?.id}`)
                            }
                            className="cursor-pointer"
                          >
                            {row.internalId}
                          </div>
                        </TableCell>
                        <TableCell>
                          {row?.customer?.name}
                          {/* </Link> */}
                        </TableCell>{" "}
                        <TableCell>{row?.customer?.city}</TableCell>
                        <TableCell>{row?.invoiceType}</TableCell>
                        <TableCell>
                          {row?.invoicedAt?.substring(0, 10)}
                        </TableCell>
                        <TableCell>
                          <Box className={classNames(classes.flexBox)}>
                            {expandedRow === row.id ? (
                              <Typography>{row?.serviceDescription}</Typography>
                            ) : (
                              <Typography>{`${row?.serviceDescription.slice(
                                0,
                                50,
                              )}...`}</Typography>
                            )}
                            <IconButton onClick={() => handleRowExpand(row.id)}>
                              {expandedRow === row.id ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </IconButton>
                          </Box>
                        </TableCell>
                        <TableCell>{`$ ${parseFloat(row?.lineItemTotal).toFixed(
                          2,
                        )}`}</TableCell>
                        <TableCell>
                          <span
                            className={`${classes.status} ${getStatusClass(
                              row?.aasmState,
                              classes,
                            )}`}
                          >
                            {row?.aasmState
                              ? row?.aasmState[0].toUpperCase() +
                              row?.aasmState.slice(1)
                              : ""}
                          </span>
                        </TableCell>
                        <TableCell>
                          {userRole === "vendor" ? (
                            // If user is vendor, render plain text
                            row?.invoice?.id ? (
                              <div>{row?.invoice?.internalId}</div>
                            ) : (
                              "N/A"
                            )
                          ) : (
                            // If user is not vendor, render as a link
                            row?.invoice?.id ? (
                              <div
                                onClick={() =>
                                  history.push({
                                    pathname: `/app/invoices/${row?.invoice?.id}`,
                                    state: { invoiceId: row?.invoice?.id },
                                  })
                                }
                                className="cursor-pointer"
                              >
                                {row?.invoice?.internalId}
                              </div>
                            ) : (
                              "N/A"
                            )
                          )}
                        </TableCell>
                        <TableCell>
                          <Link to={`workorders/${row?.id}`}>
                            <img src={edit_pencil} />
                          </Link>
                        </TableCell>
                        {/* <TableCell>
                          <img className={"clickable-image"} src={exportIcon} />
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
          <div className={classNames(classes.tableCell0)}>
            <div className={classNames(classes.tableCell20)}>
              Total
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              $
              {invoicesData &&
                invoicesData
                  ?.reduce(
                    (accumulator, currentItem) =>
                      accumulator + parseFloat(currentItem?.lineItemTotal),
                    0,
                  )
                  .toFixed(2)}
            </div>
          </div>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 35, 50]}
          component="div"
          count={invoicesData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
