import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Tabs from "../Tabs";
import { ActionTypes } from "../../../../redux/constants/actionTypes";

export default function InvoiceTabs(props) {
  const location = useLocation();
  const dispatch = useDispatch();

  /* istanbul ignore next */
  const invoice_ID = useSelector((state) => state?.invoices?.invoiceId);
  const invoiceId = location?.state?.invoiceId;
  const invoiceMaintenanceRecordsData = useSelector(
    (state) =>
      state?.invoices?.getAllInvoiceMaintenanceRecordsData
        ?.allMaintenanceRecordsByInvoiceId,
  );

  const invoiceWorkOrdersData = useSelector(
    (state) =>
      state?.invoices?.getAllInvoiceWorkOrdersData?.allWorkOrdersByInvoiceId,
  );
  /* istanbul ignore next */
  useEffect(() => {
    if (invoiceId) {
      dispatch({
        type: ActionTypes.INVOICE_ID,
        payload: invoiceId,
      });
    }
  }, [invoiceId]);

  return (
    <div>
      <Tabs
        invoiceId={invoiceId ? invoiceId : invoice_ID}
        types={[
          "All",
          invoiceMaintenanceRecordsData &&
            invoiceMaintenanceRecordsData?.length > 0 &&
            "Maintanance Contracts",
          invoiceWorkOrdersData &&
            invoiceWorkOrdersData?.length > 0 &&
            "Workorders",
        ]}
      />
    </div>
  );
}
