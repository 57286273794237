import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Export_Button from "../../../assets/img/icons/Export_Button.svg";
import edit_pencil from "../../../assets/img/icons/edit_pencil.svg";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import SearchBar from "material-ui-search-bar";
import { Link } from "react-router-dom";
import moment from "moment/moment";

import classNames from "classnames";

import useStyles from "../styles";

function createData(
  Date,
  DocumemtNo,
  Location,
  Lines,
  Subtotal,
  Tax,
  Total,
  Remaining,
  DueDate,
  Status,
) {
  return {
    Date,
    DocumemtNo,
    Location,
    Lines,
    Subtotal,
    Tax,
    Total,
    Remaining,
    DueDate,
    Status,
  };
}

const rows = [
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
  createData(
    "08/31/2022",
    "S-0190364",
    "27027-0002",
    "1-Qty:1.0",
    "43,000.00",
    "2,655.00",
    "45,655.00",
    "45,655.00",
    "11/04/2022",
    "Open",
  ),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
// sort table data
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "invoiceNo",
    numeric: false,
    disablePadding: true,
    label: "Date",
  },
  {
    id: "internalId",
    numeric: false,
    disablePadding: false,
    label: "Document No.",
  },
  {
    id: "customer",
    numeric: false,
    disablePadding: false,
    label: "Location",
  },
  {
    id: "serviceDescription",
    numeric: false,
    disablePadding: false,
    label: "Lines",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Subtotal",
  },
  {
    id: "dueDate",
    numeric: false,
    disablePadding: false,
    label: "Tax",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: "Total",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: "Remaining",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: "Due Date",
  },
  {
    id: "aasmState",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "edit",
    numeric: false,
    disablePadding: false,
    label: "",
  },
  {
    id: "export",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];
/* istanbul ignore next */
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all desserts",
                        }}
                    />
                </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function ContractsPage({ invoicesData, search }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchValue, setSearchValue] = React.useState("");

  React.useEffect(() => {
    setSearchValue(search);
  }, [search]);

  console.log(searchValue, "seasas");

  // Selectors from state
  /* istanbul ignore next */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "desc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  /* istanbul ignore next */
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  /* istanbul ignore next */
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  /* istanbul ignore next */
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  console.log(selected, "invoiceNo");
  console.log(
    "rowwwwwwwwwwwwwwwwwwwwwwww",
    rows.map((data) => console.log("data", data)),
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer sx={{ padding: "32px" }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {console.log(search, "search")}
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}

              {rows &&
                !searchValue &&
                stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row);
                    const labelId = `enhanced-table-checkbox-${row}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row}
                        selected={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            "aria-labelledby": labelId,
                                                        }}
                                                    />
                                                </TableCell> */}
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.Date}
                        </TableCell>
                        <TableCell>
                          {/* <Link to={"invoicetabs/dummyTabs"}> */}
                          {row?.DocumemtNo}
                          {/* </Link> */}
                        </TableCell>
                        <TableCell>{row?.Location}</TableCell>
                        <TableCell>{row?.Lines}</TableCell>
                        <TableCell>{row?.Subtotal}</TableCell>
                        <TableCell>{row?.Tax}</TableCell>
                        <TableCell>{row?.Total}</TableCell>
                        <TableCell>{row?.Remaining}</TableCell>
                        <TableCell>{row?.DueDate}</TableCell>
                        <TableCell>
                          <span className={classes.status}>{row?.Status}</span>
                        </TableCell>{" "}
                        <TableCell>
                          <Link to={`invoice/${row?.id}`}>
                            <img src={edit_pencil} />
                          </Link>
                        </TableCell>
                        <TableCell>
                          <img
                            className={"clickable-image"}
                            src={Export_Button}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
          <div className={classNames(classes.tableCell0)}>
            <div className={classNames(classes.tableCell20)}>
              Total
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              232,945.42
            </div>
          </div>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 35, 50]}
          component="div"
          count={invoicesData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
