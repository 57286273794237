import { makeStyles } from "@material-ui/styles";

const drawerWidth = 240;

export default makeStyles((theme) => ({
  card: {
    background: "#FFFFFF",
    border: "1px solid #DEDEDE",
  },
  cardContent: {
    display: "flex",
    flexDirection: "row",
    m: 0,
    p: 1,
    alignItems: "center",
    borderRadius: 1,
  },
  count: {
    fontWeight: 700,
    fontSize: "40px",
  },
  cardName: { fontWeight: 300, fontSize: "14px", lineHeight: "16px" },
  cardDescription: {
    fontFamily: "Ubuntu",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    marginTop: "8px",
  },
}));
