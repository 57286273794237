import { makeStyles } from "@material-ui/styles";

const drawerWidth = 240;

export default makeStyles((theme) => ({
  cardBox: {
    marginLeft: 0,
    marginBottom: "32px",
  },
  button: {
    background: "#FFFFFF !important",
    border: "1px solid #DEDEDE !important",
    borderRadius: "3px !important",
    paddingTop: "13px !important",
    paddingBottom: "13px !important",
    maxHeight: "32px !important",

    minWidth: "32px !important",
    margin: "0px 8px !important",
    color: "#313131 !important",
    fontWeight: "400 !important",
  },
  raiseDisputeButton: {
    background: "#F52D2D !important",
    border: "1px solid #DEDEDE !important",
    borderRadius: "3px !important",
    paddingTop: "13px !important",
    paddingBottom: "13px !important",
    maxHeight: "32px !important",

    minWidth: "32px !important",
    margin: "0px 8px !important",
    color: "#FFFFFF !important",
    fontWeight: "400 !important",
  },
  modalContainer: {
    margin: "0px 32px",
  },
  addModalContainer: {
    margin: "0px 32px",
    width: "1016px",
  },
  editInvoiceHeader: {
    padding: "16px 0px",
    fontWeight: "500",
    fontSize: "20px",
    color: "#313131",
  },
  textField: {
    background: "#F3F6F8",
    padding: "0px 10px",
    width: "336px",
    height: "32px",
    border: "1px solid #DEDEDE",
  },
  description_textArea: {
    height: "50px",
    width: "100%",
  },
  headerContainer: {
    borderBottom: "1px solid #DEDEDE",
  },
  headerContainerInvoice: {
    borderBottom: "1px solid #DEDEDE",
    display: "flex",
  },
  modalHeader: {
    padding: "16px 0px",
    fontWeight: "500",
    fontSize: "16px",
    color: "#313131",
  },
  textField: {
    background: "#F3F6F8",
    padding: "0px 10px",
    width: "336px",
    height: "32px",
    border: "1px solid #DEDEDE",
  },
  fileUploader: {
    background: "#F3F6F8",
    border: "1px dashed #C1C1C1",
    borderRadius: "3px",
    height: "180px",
  },
  fileTypeText: {
    color: "#6F6F6F",
    fontWeight: "400",
    fontSize: "10px",
  },
  formRow: {
    marginBottom: "24px",
  },

  editInvoiceModalContainer: {
    background: "white",
  },

  status: {
    background: " #FADCD9",
    borderRadius: "40px",
    padding: "4px 16px 4px 16px",
  },
}));
