import React, { useState } from "react";
import { useSelector } from "react-redux";
import Sales from "../InvoiceTable/SalesTable";
import Contracts from "../InvoiceTable/ContractsTable";
import Workorders from "../InvoiceTable/WorkordersTable";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import classNames from "classnames";
import styled from "styled-components";

//Styles
import useStyles from "../styles";
import OverallTable from "../InvoiceTable/OverallTable";
import EndCustomerInvoice from "../InvoiceTable/endCustomerInvoiceTable";
import ContractsPage from "../InvoiceTable/ContractsPage";
import VendorsInvoiceTable from "../InvoiceTable/VendorsInvoiceTable";
import DocumentsTable from "../InvoiceTable/DocumentsTable";
import VendorContracts from "../InvoiceTable/VendorContractsTable";
import Loader from "../../../components/Loader/Loader";
import CustomersTable from "../InvoiceTable/CustomersTable";

//styles for tab

//array of tabs

export default function Tabs(props) {
  const Tab = styled.button`
    font-size: 20px;
    padding: 10px 60px;
    cursor: pointer;
    opacity: 0.6;
    background: ${props?.tabColor ?? "#f5f9f8"};
    text-align: "center !important";
    border: 0;
    outline: 0;
    font-family: Ubuntu;
    font-weight: bold !important;
    padding: 10px 30px 25px 0px;

    ${({ active }) =>
      active &&
      `
    background: ${props?.tabColor && "white"};
    border-bottom: 0px solid black;
    opacity: 1;
  `}
  `;
  const ButtonGroup = styled.div`
    display: flex;
  `;

  const classes = useStyles();
  const types = props.types;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [active, setActive] = useState(types[0]);

  const userRole = useSelector(
    (state) => state?.auth?.authUserData?.user?.role,
  );

  /* istanbul ignore next */
  function handleChange(type) {
    /* istanbul ignore next */
    if (type === "End Customer Invoices" || type === "Vendor Invoices") {
      setActive(type);
      props.onChange(type);
    } else if (type === "Vendor Contracts" || type === "Documents") {
      setActive(type);
      props.onChange(type);
    } else {
      setActive(type);
      props.onChange(type);
    }

    // Call the callback function with the updated state
  }

  return (
    <>
      <Box className={classNames(classes.style)}>
        {/* tabs logic */}
        <ButtonGroup>
          {types.map((type) => (
            <Tab
              className={props?.tabColor && "MainTab"}
              key={type}
              active={active === type}
              onClick={() => handleChange(type)}
            >
              {type}
            </Tab>
          ))}
        </ButtonGroup>

        {active === "All" && (
          <>
            {/* <span variant="h6">Sales</span>
            <br />
            <br />
            <Sales />
            <br />
            <br /> */}{" "}
            {/* <br /> //! Location of Maint Contracts and Workorders
            <span variant="h6">Maintenance Contracts</span>
            <br />
            <br />
            <Contracts invoiceId={props?.invoiceId} /> */} {" "}
            <br />
            <br />
            <span variant="h6">Workorders</span>
            <br />
            <br />
            <Workorders invoiceId={props?.invoiceId} />
          </>
        )}
        {active === "Sales" && (
          <>
            <span variant="h6">Sales</span>
            <br />
            <br />
            <Sales />
          </>
        )}

        {active === "Maintanance Contracts" && (
          <>
            <br />
            <span variant="h6">Maintanance Contracts</span>
            <br />
            <br />
            <Contracts invoiceId={props?.invoiceId} />
          </>
        )}

        {active === "Workorders" && (
          <>
            <br />
            <span variant="h6">Workorders</span>
            <br />
            <br />
            <Workorders invoiceId={props?.invoiceId} />
          </>
        )}

        {active === "Overall" && (
          <>


            {userRole !== "vendor" && ( // Check if userRole is not "vendor"
              <>
                <Box>
                  <Box className={classNames(classes.header)}>
                    End Customer Invoices
                  </Box>
                  {props?.loading?.allInvoicesLoading ? (
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <Loader />
                      <span>Loading...</span>
                    </Box>
                  ) : (
                    <OverallTable
                      invoicesData={props?.invoicesData}
                      search={props?.search}
                      dateRange={props?.dateRange}
                      perPage={5}
                    />
                  )}
                  {console.log(props?.invoicesData, "wohoo")}
                </Box>


                <Box className={classNames(classes.header)}>Contracts</Box>
                {props?.loading?.allContractsLoading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Loader />
                    <span>Loading...</span>
                  </Box>
                ) : (
                  <VendorContracts
                    contractsData={props?.contractsData}
                    search={props?.search}
                    perPage={5}
                  />
                )}
              </>
            )}

            <Box className={classNames(classes.header)}>Vendor Invoices</Box>
            {props?.loading?.vendorWorkOrdersLoading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Loader />
                <span>Loading...</span>
              </Box>
            ) : (
              <VendorsInvoiceTable
                invoicesData={props?.vendorInvoiceData}
                search={props?.search}
                perPage={5}
              />
            )}

            <Box className={classNames(classes.header)}>Documents</Box>
            {props?.loading?.allDocumentsLoading ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Loader />
                <span>Loading...</span>
              </Box>
            ) : (
              <DocumentsTable
                documentsData={props?.documentsData}
                search={props?.search}
                perPage={5}
                refetchDocument={props.refetchDocument}
              />
            )}
          </>
        )}

        {active === "End Customer Invoices" && (
          <>
            {props?.loading?.allInvoicesLoading ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Loader />

                <span>Loading...</span>
              </Box>
            ) : (
              <OverallTable
                invoicesData={props?.invoicesData}
                search={props?.invoiceSearch}
                dateRange={props?.dateRange}
                perPage={25}
              />
            )}
          </>
        )}

        {/* {active === "Vendor Invoices For Customer" && (
          <>
            {console.log(
              props?.customersData?.allCustomers,
              "rops?.customersData?.allCustomers",
            )}
            <CustomersTable
              invoicesData={props?.customersData?.allCustomers}
              search={props?.search}
            />
          </>
        )} */}

        {active === "Vendor Invoices" && (
          <>
            {props?.loading?.vendorWorkOrdersLoading ? (
              <Box
                className={classNames(classes.loader)}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Loader />

                <span>Loading...</span>
              </Box>
            ) : (
              <VendorsInvoiceTable
                invoicesData={props?.vendorInvoiceData}
                perPage={25}
                filters={props.filters}
                setLocation={props?.setLocation}
                setStatus={props?.setStatus}
                setInvoiceType={props?.setInvoiceType}
                location={props?.location}
                status={props?.status}
                invoiceType={props?.invoiceType}
                setDate={props?.setDate}
              />
            )}
          </>
        )}

        {active === "Contracts" && (
          <>
            {props?.loading?.allContractsLoading ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Loader />

                <span>Loading...</span>
              </Box>
            ) : (
              <VendorContracts
                contractsData={props?.contractsData}
                search={props?.search}
                perPage={25}
              />
            )}
          </>
        )}

        {active === "Documents" && (
          <>
            {props?.loading?.allDocumentsLoading ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Loader />

                <span>Loading...</span>
              </Box>
            ) : (
              <DocumentsTable
                documentsData={props?.documentsData}
                search={props?.search}
                perPage={25}
                refetchDocument={props.refetchDocument}
              />
            )}
          </>
        )}
      </Box>
    </>
  );
}
