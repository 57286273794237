import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  heading: {
    textAlign: "left",
    background: "#deecef",
    fontSize: "17px",
    fontWeight: "800",
    padding: "5px",
  },
  headingContainer: {
    padding: "0px",
    marginLeft: "12px",
  },
  buttonContainer: {
    paddingTop: "15px",
    paddingBottom: "15px",
    textAlign: "center",
  },

  box1: {
    background: "#f4f4f4",
    paddingBottom: "12px",
    marginLeft: "0px",
  },
  filterRow: {
    marginTop: "0px !important",
    marginLeft: "0px !important",
  },

  box2: {
    width: "292px",
    marginTop: "10px",
  },

  div1: {
    verticalAlign: "Top",
    paddingBottom: "5px",
    cursor: "pointer",
  },

  div2: {
    fontWeight: "400",
    lineHeight: "13.79px",
    fontFamily: "Ubuntu",
    fontStyle: "Regular",
    fontSize: "12px",
    lineHeight: "14px",
    lineHeight: "100 %",
    align: "Left",
    verticalAlign: "Top",
    paddingLeft: "12px",
    paddingTop: "12px",
    marginBottom: "-2px",
  },

  div3: {
    fontWeight: "400",
    lineHeight: "13.79px",
    fontFamily: "Ubuntu",
    fontStyle: "Regular",
    fontSize: "12px",
    lineHeight: "14px",
    lineHeight: "100 %",
    align: "Left",
    verticalAlign: "Top",
    paddingLeft: "12px",
    // width: '1px'
  },

  button1: {
    width: "80px",
  },
  button2: {
    width: "140px",
  },
}));
